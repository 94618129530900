import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import SvgRendering from "./../SvgRendering/SvgRendering"

const ListingItems = (props) => {
    const parentPath = "/dashboard/"
    let tab = props.rawTab
    let classNames = "text-capitalize"
    let simulationActive = ""
    let linkClassNames = "list-group-item border-0 rounded-0 text-white"

    if (tab === "rex" || tab === "kpi" || tab === "odis") {
        classNames = "text-uppercase"
    }

    if (tab === "extrud3d" || tab === "rex") {
        simulationActive = <span className="text-capitalize">Simulation</span>
    }

    if (props.location.pathname === "/dashboard/" + tab || props.location.pathname === "/dashboard/" + tab + "-simulation") {
        linkClassNames += " active"
    }

    return (
        <>
            <li className={props.toggled}>
                <Link className={linkClassNames} to={parentPath + tab}>
                    <SvgRendering iconName={tab} modules={props.modules} />
                    <span className={classNames}>{tab}</span> {simulationActive}
                </Link>
            </li>
        </>
    )
}

export default withRouter(ListingItems)