import React from "react"
import {connect} from "react-redux"

import PieChart from "assets/gifs/Pie_Chart.gif"
import Graph from "assets/gifs/Graphs.gif"
import BarChart from "assets/gifs/Vertical_Bar_Graphs.gif"

const mapStateToProps = state => {
    return {
        modules: state.DashboardReducer.modules
    }
}

const KPI = (props) => {
    let ip_adress = null
    if (props.modules !== undefined) {
        props.modules.map(item => {
            if (item.name === "KPI") {
                if (item.ip_adress !== null) {
                    return ip_adress = item.ip_adress
                }
            }
        })
    }
    if (ip_adress === null) {
        return (
            <div className="kpi-wrapper">
                <h1 className="kpi-headline text-center text-white">Kennzahlen - Report (KPI) </h1>
                <img className="graph" src={Graph}></img>
                <img className="bar-chart" src={BarChart}></img>
                <img className="pie-chart" src={PieChart}></img>
            </div>
        )
    } else {
        return (
            <iframe src={ip_adress} width="100%" height="100%" />
        )
    }
}

export default connect(mapStateToProps)(KPI)