import React from "react";
import {
    Route,
    Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = state => (
    {
        accessToken: state.LoginReducer.accessToken
    }
)

const checkAuth = ({ component: Component, ...rest }) => {
    if (rest.accessToken !== undefined) {
        return (
            <Route
                {...rest}
                render={props =>
                    rest.accessToken !== "" ? 
                    (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    } else {
        return (
            <Redirect
                to={{
                    pathname: "/",
                }}
            />
        )
    }
}

export default connect(mapStateToProps)(checkAuth)