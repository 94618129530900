import React from 'react'
import { connect } from 'react-redux'

import SvgRendering from '../Menu/SvgRendering/SvgRendering';

const mapStateToProps = state => {
    return {modules: state.DashboardReducer.modules}
}

const Dashboard = (props) => <div className="mt-5 text-center"><SvgRendering iconName="grid" modules={props.modules} /></div>

export default connect(mapStateToProps)(Dashboard)