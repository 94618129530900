import { SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS, SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS_TEXT, GRAPHQL_REQUEST_ALL_ERROR_CLASSES } from "./actionTypes";

export function setActiveTroubleshooterErrorClass(errorClassName) {
    return {
        type: SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS,
        errorClassName
    }
}

export function setActiveTroubleshooterErrorClassText(errorClassNameText) {
    return {
        type: SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS_TEXT,
        errorClassNameText
    }
}

export function graphqlRequestAllErrorClasses(data) {
    return {
        type: GRAPHQL_REQUEST_ALL_ERROR_CLASSES,
        payload: {
            query: data
        }
    }
}