import React from "react"
import {connect} from "react-redux"

const mapStateToProps = state => {
    return {
        modules: state.DashboardReducer.modules
    }
}

const Odis = props => {
    let ip_adress = null
    if (props.modules !== undefined) {
        props.modules.map(item => {
            if (item.name === "Odis") {
                if (item.ip_adress !== null) {
                    return ip_adress = item.ip_adress
                }
            }
        })
    }
    return (
        <>
        <iframe src={ip_adress} width="100%" height="100%"></iframe>
        <style>
            {"iframe{border: none}"}
        </style>
        </>
    )
}

export default connect(mapStateToProps)(Odis)