import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownMenu from "./DropdownMenu/DropdownMenu";
import SvgRendering from "../Menu/SvgRendering/SvgRendering";

const UserDropdown = (props) => {
    const [ dropdown, setDropdown ] = useState(false)
    const [ userroleIcon, setUserroleIcon ] = useState(undefined)

    const toggleButton = () => {
        setDropdown(!dropdown)
    }

    var icon = "chevron-down"
    if (dropdown) {
        icon = "chevron-up"
    }

    if (userroleIcon === undefined) {
        switch(props.userrole) {
            case "USER":
            default:
            setUserroleIcon(<SvgRendering iconName="user-level-3" />)
            break;
            case "MANAGER":
            setUserroleIcon(<SvgRendering iconName="user-level-2" />)
            break;
            case "ADMIN":
            setUserroleIcon(<SvgRendering iconName="user-level-1" />)
            break;
        }
    }

    return(
        <>
            <div className="user-dropdown-button mt-4 mr-4" onClick={() => toggleButton()}>
                <p href="#" className="mb-0">{userroleIcon}User {props.username}
                    <button className="user-dropdown-arrow bg-transparent border-0 ml-4">
                        <FontAwesomeIcon icon={icon} />
                    </button>
                </p>
            </div>
            <DropdownMenu toggle={dropdown} companyUrl={props.companyUrl} />
        </>
    )
}

export default UserDropdown