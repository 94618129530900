import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import TroubleshooterCategories from "components/Troubleshooter/TroubleshooterCategories";
import { graphqlRequestAllErrorClasses } from "store/troubleshooter/actions";

const mapStateToProps = state => {
    return {
        allErrorClasses: state.TroubleshooterReducer.allErrorClasses,
        companyId: state.LoginReducer.companyId
    }
}

const mapDispatchToProps = {
    graphqlRequestAllErrorClasses
}

const TroubleshooterCategoryWrapper = (props) => {
    const {allErrorClasses, graphqlRequestAllErrorClasses,companyId} = props
    const query =
    `query ($id: ID = ${companyId}){
        company(id: $id) {
            errorClasses {
                name
                errorTypes {
                name
                description
                errorDiagnosisTypes {
                    name
                }
                }
            }
        }
    }`
    
    useEffect(() => {graphqlRequestAllErrorClasses(query)}, [])

    let errorClasses = []
    if (allErrorClasses.errorClasses !== null) {
        errorClasses = allErrorClasses
    }

    if (errorClasses !== undefined) {
        return (
        <div className="col-4 offset-1">
            <div className="gradient-top" />
            <div className="troubleshooter-category-wrapper">
            {errorClasses.map((item, i) => {
                const {name, errorTypes} = item
                return (
                <div className="troubleshooter-category mb-3" key={i}>
                <h4 className="text-white">{name}</h4>
                <TroubleshooterCategories errorTypes={errorTypes} />
            </div>
            )})}
            </div>
            <div className="gradient-bottom" />
        </div>
        )
    } else {
        return ""
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TroubleshooterCategoryWrapper)