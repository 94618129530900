import React, { useEffect } from "react";
import { connect } from 'react-redux'

import InputField from "components/Login/InputField/InputField";
import LoginLogo from "components/Login/LoginLogo/LoginLogo";

const mapStateToProps = state => {
    return {
        loginFailed: state.LoginReducer.loginFailed,
        companyId: state.LoginReducer.companyId
    }
}

const Index = (props) => {

    let loadCompany = window.location.pathname
    const { loginFailed, companyId } = props

    useEffect(() => {
        if (localStorage.getItem('Company') !== null
        && companyId === undefined
        && loginFailed >= 1
        ) {
            loadCompany = localStorage.getItem('Company')
            const loadUrl = window.location.host
            window.location.href = "http://" + loadUrl + "/" + loadCompany
        }
    }, [loginFailed])
        
    return (
        <div className="login-page">
            <InputField />
            <LoginLogo />
        </div>
    )
}

export default connect(mapStateToProps)(Index)