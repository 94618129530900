import React from 'react'
import SvgRendering from 'components/Menu/SvgRendering/SvgRendering';

const DropdownMenu = (props) => {
    if (props.toggle) {
        return (
            <div className="user-dropdown-menu mt-2 mr-4 p-2">
                <a href={"/" + props.companyUrl} className="p-2 mb-0 user-dropdown-menu-item"><SvgRendering iconName="logout"/><span className="ml-3">Logout</span></a>
            </div>
        )
    } else {
        return ""
    }
}

export default DropdownMenu