import { ADD_LOGIN_INPUT, REMOVE_LOGIN_INPUT, REMOVE_ALL_LOGIN_INPUTS, LOGIN, GRAPHQL_REQUEST_ACCESS_TOKEN, GRAPHQL_REQUEST_GET_COMPANY_ID } from "./actionTypes";

const initialState = {
    checkingNumber: [],
    totalNumberAmount: 4,
    accessDashboard: false,
    accessToken: undefined,
    loginFailed: 0
}

export function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_LOGIN_INPUT:
            if (state.checkingNumber.length < state.totalNumberAmount) {
                return {
                    ...state,
                    checkingNumber: [
                        ...state.checkingNumber,
                        action.checkingNumber
                    ],
                }
            } else {
                return state
            }
        case REMOVE_LOGIN_INPUT:
            return {
                ...state,
                checkingNumber: state.checkingNumber.slice(0, state.checkingNumber.length - 1)
            }
        case REMOVE_ALL_LOGIN_INPUTS:
            return {
                ...state,
                checkingNumber: []
            }
        case LOGIN:
            if (state.checkingNumber.length  === state.totalNumberAmount) {
                return {
                    ...state,
                    accessDashboard: true
                }
            } else {
                return {
                    ...state,
                    accessDashboard: false
                }
            }
        case GRAPHQL_REQUEST_ACCESS_TOKEN + "_RESOLVED":
            return {
                ...state,
                username: action.payload.login.name,
                userrole: action.payload.login.role,
                accessToken: action.payload.login.token.access_token
            }
        case GRAPHQL_REQUEST_GET_COMPANY_ID + "_RESOLVED":
            return {
                ...state,
                companyId: action.payload.check.id,
                companyUrl: action.payload.check.url
            }
        case "LOGIN_FAILED":
            return {
                ...state,
                loginFailed: state.loginFailed + 1
            }
        default:
            return state
    }
}