import React from "react"
import {connect} from "react-redux"

const mapStateToProps = state => {
    return {
        modules: state.DashboardReducer.modules
    }
}
const Extrud = (props) => {
    let ip_adress = process.env.PUBLIC_URL + '/assets/iframes/extrud3d.html'
    if (props.modules !== undefined) {
        props.modules.map(item => {
            if (item.name === "Extrud3d") {
                if (item.ip_adress !== null) {
                    return ip_adress = item.ip_adress
                }
            }
        })
    }
    return (
    <div className="fullsize-wrapper">
        <iframe title="Extrud3D Animation" src={ip_adress}></iframe>
    </div>
)}

export default connect(mapStateToProps)(Extrud)