import React from 'react'
import { Link } from "react-router-dom"
import { connect } from "react-redux"

const mapStateToProps = state => {
    return {
        currentErrorClassText: state.TroubleshooterReducer.currentErrorClassText,
        currentErrorClass: state.TroubleshooterReducer.currentErrorClass,
    }
}

const TroubleshooterCategoryDesc = (props) => {
    return (
    <div className="col-5 text-white offset-1">
        <h3 className="h1">{props.currentErrorClass}</h3>
        <h5>Beschreibung</h5>
        <div className="troubleshooter-desc">
        <div dangerouslySetInnerHTML={{__html: props.currentErrorClassText}} />
            <div className="gradient-bottom-small" />
        </div>
        <div className="text-center mt-3 troubleshooter-diagnosis-button">
            <Link to="/dashboard/troubleshooter-simulation"><button className="text-white p-2">Diagnose starten</button></Link>
        </div>
    </div>
)}

export default connect(mapStateToProps)(TroubleshooterCategoryDesc)