import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
	return {
		modules: state.DashboardReducer.modules
	}
}

const SvgRendering = (props) => {
	const standardColor = '#6F7379'
	const changedColor = '#FFFFFF'
	let 
	activeControl = standardColor,
	activeExpertise = standardColor,
	activeHvi = standardColor,
	activeAssistant = standardColor,
	activeKPI = standardColor,
	activeAnalytics = standardColor,
	activeLive = standardColor,
	activeTroubleshooter = standardColor,
	activeExtrud3d = standardColor,
	activeChillware = standardColor,
	activeREX = standardColor,
	activeOdis = standardColor,
	activeAutonomous = standardColor

	let linkControl = "/dashboard",
	linkExpertise = "/dashboard",
	linkHvi = "/dashboard",
	linkAssistant = "/dashboard",
	linkKPI = "/dashboard",
	linkAnalytics = "/dashboard",
	linkLive = "/dashboard",
	linkTroubleshooter = "/dashboard",
	linkExtrud3d = "/dashboard",
	linkChillware = "/dashboard",
	linkREX = "/dashboard",
	linkOdis = "/dashboard",
	linkAutonomous = "/dashboard"

	let newModules = []
	if (props.modules) {
		props.modules.map(item => {
			newModules.push(item.name.toLowerCase())
		})
		if (newModules.includes("control")) {
			activeControl = changedColor
			linkControl += "/control"
		}
		if (newModules.includes("expertise")) {
			activeExpertise = changedColor
			linkExpertise += "/expertise"
		}
		if (newModules.includes("hvi")) {
			activeHvi = changedColor
			linkHvi += "/hvi"
		}
		if (newModules.includes("assistant")) {
			activeAssistant = changedColor
			linkAssistant += "/assistant"
		}
		if (newModules.includes("kpi")) {
			activeKPI = changedColor
			linkKPI += "/kpi"
		}
		if (newModules.includes("analytics")) {
			activeAnalytics = changedColor
			linkAnalytics += "/analytics"
		}
		if (newModules.includes("live")) {
			activeLive = changedColor
			linkLive += "/live"
		}
		if (newModules.includes("troubleshooter")) {
			activeTroubleshooter = changedColor
			linkTroubleshooter += "/troubleshooter"
		}
		if (newModules.includes("extrud3d")) {
			activeExtrud3d = changedColor
			linkExtrud3d += "/extrud3d"
		}
		if (newModules.includes("chillware")) {
			activeChillware = changedColor
			linkChillware += "/chillware"
		}
		if (newModules.includes("rex")) {
			activeREX = changedColor
			linkREX += "/rex"
		}
		if (newModules.includes("odis")) {
			activeOdis = changedColor
			linkOdis += "/odis"
		}
		if (newModules.includes("autonomous")) {
			activeAutonomous = changedColor
			linkAutonomous += "/autonomous"
		}
	}

	switch(props.iconName) {
		case "vipra":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="20 20 60 60" space="preserve">
					<style type="text/css">
						{'.st0{fill:#FFFFFF;}'}
					</style>
					<g>
						<g>
							<g>
								<polygon className="st0" points="57.7,63.2 46.3,63.2 29.8,37 41.2,37 			"/>
							</g>
							<g>
								<circle className="st0" cx="65.3" cy="41.9" r="4.9"/>
							</g>
						</g>
					</g>
				</svg>

			)
		case "control":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stControl{fill:' + activeControl + "}"}
					</style>
					<g>
						<g>
							<g>
								<path className="stControl" d="M121.8,104.8H55.7c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h59.8c0.8,0,1.5,0.7,1.5,1.5v2
                                            c0,0.8-0.7,1.5-1.5,1.5H65.3c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h53.2c0.8,0,1.5-0.7,1.5-1.5v-5
                                            c0-0.8,0.7-1.5,1.5-1.5h3.4c0.8,0,1.5-0.7,1.5-1.5v-50c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v46.9
                                            C123.3,104.2,122.7,104.8,121.8,104.8z"/>
							</g>
							<g>
								<path className="stControl" d="M23.6,35.6v70.8c0,0.8,0.7,1.5,1.5,1.5h3.4c0.8,0,1.5,0.7,1.5,1.5v5c0,0.8,0.7,1.5,1.5,1.5h25.8
                                            c0.8,0,1.5-0.7,1.5-1.5l0,0c0-0.8-0.7-1.5-1.5-1.5H34.7c-0.8,0-1.5-0.7-1.5-1.5v-5c0-0.8-0.7-1.5-1.5-1.5h-3.4
                                            c-0.8,0-1.5-0.7-1.5-1.5V38.7c0-0.8,0.7-1.5,1.5-1.5h93.6c0.8,0,1.5,0.7,1.5,1.5v9.9c0,0.8,0.7,1.5,1.5,1.5l0,0
                                            c0.8,0,1.5-0.7,1.5-1.5v-13c0-0.8-0.7-1.5-1.5-1.5H25.1C24.4,34,23.6,34.7,23.6,35.6z"/>
							</g>
							<g>
								<path className="stControl" d="M118.5,96.7L118.5,96.7c0-0.8-0.7-1.5-1.5-1.5h-5c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h5
                                            C117.8,98.2,118.5,97.5,118.5,96.7z"/>
							</g>
							<g>
								<path className="stControl" d="M117,88.7h-16.3c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5H117c0.8,0,1.5-0.7,1.5-1.5l0,0
                                            C118.5,89.4,117.8,88.7,117,88.7z"/>
							</g>
							<g>
								<path className="stControl" d="M117,82.3h-16.3c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5H117c0.8,0,1.5-0.7,1.5-1.5l0,0
                                            C118.5,83,117.8,82.3,117,82.3z"/>
							</g>
							<g>
								<path className="stControl" d="M117,75.9h-16.3c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5H117c0.8,0,1.5-0.7,1.5-1.5l0,0
                                            C118.5,76.6,117.8,75.9,117,75.9z"/>
							</g>
							<g>
								<path className="stControl" d="M117,69.4h-16.3c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5H117c0.8,0,1.5-0.7,1.5-1.5l0,0
                                            C118.5,70.1,117.8,69.4,117,69.4z"/>
							</g>
							<g>
								<path className="stControl" d="M114.5,42.1h-79c-2.1,0-3.8,1.7-3.8,3.8V59c0,2.1,1.7,3.8,3.8,3.8h79c2.1,0,3.8-1.7,3.8-3.8V45.9
                                            C118.3,43.8,116.7,42.1,114.5,42.1z"/>
							</g>
							<g>
								<path className="stControl" d="M107.2,96.7L107.2,96.7c0-0.8-0.7-1.5-1.5-1.5h-0.2c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h0.2
                                            C106.5,98.2,107.2,97.5,107.2,96.7z"/>
							</g>
							<g>
								<path className="stControl" d="M89.4,96.8v-4.1c0-0.6,0.4-1.2,0.9-1.4c2.3-1,3.9-3.2,3.9-5.8s-1.6-4.9-3.9-5.8c-0.6-0.2-0.9-0.8-0.9-1.4
                                            v-8.9c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v8.9c0,0.6-0.4,1.2-0.9,1.4c-2.3,1-3.9,3.2-3.9,5.8s1.6,4.9,3.9,5.8
                                            c0.6,0.2,0.9,0.8,0.9,1.4v4.1c0,0.8,0.7,1.5,1.5,1.5l0,0C88.8,98.3,89.4,97.6,89.4,96.8z"/>
							</g>
							<g>
								<path className="stControl" d="M73.3,96.8V86.3c0-0.6,0.4-1.2,0.9-1.4c2.3-1,3.9-3.2,3.9-5.8s-1.6-4.9-3.9-5.8c-0.6-0.2-0.9-0.8-0.9-1.4
                                            v-2.5c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v2.5c0,0.6-0.4,1.2-0.9,1.4c-2.3,1-3.9,3.2-3.9,5.8s1.6,4.9,3.9,5.8
                                            c0.6,0.2,0.9,0.8,0.9,1.4v10.5c0,0.8,0.7,1.5,1.5,1.5l0,0C72.7,98.3,73.3,97.6,73.3,96.8z"/>
							</g>
							<g>
								<path className="stControl" d="M54.3,94.3v2.5c0,0.8,0.7,1.5,1.5,1.5l0,0c0.8,0,1.5-0.7,1.5-1.5v-2.5c0-0.6,0.4-1.2,0.9-1.4
                                            c2.3-1,3.9-3.2,3.9-5.8s-1.6-4.9-3.9-5.8c-0.6-0.2-0.9-0.8-0.9-1.4V69.4c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v10.5
                                            c0,0.6-0.4,1.2-0.9,1.4c-2.3,1-3.9,3.2-3.9,5.8s1.6,4.9,3.9,5.8C53.8,93.1,54.3,93.7,54.3,94.3z"/>
							</g>
							<g>
								<path className="stControl" d="M49.3,104.8h-0.2c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h0.2c0.8,0,1.5-0.7,1.5-1.5l0,0
                                            C50.9,105.5,50.3,104.8,49.3,104.8z"/>
							</g>
							<g>
								<path className="stControl" d="M41.2,71.8v-2.5c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v2.5c0,0.6-0.4,1.2-0.9,1.4
                                            c-2.3,1-3.9,3.2-3.9,5.8s1.6,4.9,3.9,5.8c0.6,0.2,0.9,0.8,0.9,1.4v10.5c0,0.8,0.7,1.5,1.5,1.5l0,0c0.8,0,1.5-0.7,1.5-1.5V86.2
                                            c0-0.6,0.4-1.2,0.9-1.4c2.3-1,3.9-3.2,3.9-5.8s-1.6-4.9-3.9-5.8C41.5,72.9,41.2,72.4,41.2,71.8z"/>
							</g>
							<g>
								<path className="stControl" d="M42.8,107.9h0.2c0.8,0,1.5-0.7,1.5-1.5l0,0c0-0.8-0.7-1.5-1.5-1.5h-0.2c-0.8,0-1.5,0.7-1.5,1.5l0,0
                                            C41.3,107.2,41.9,107.9,42.8,107.9z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "assistant":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stAssistant{fill:' + activeAssistant + "}"}
					</style>
					<g>
						<g>
							<g>
								<path className="stAssistant" d="M36.1,69.9l3.7,7.3C38.1,78.1,37,80,37.2,82c0.2,2,1.5,3.6,3.4,4.2l1.3,10.5c-2.4,0.9-3.7,3.5-3,6
				s3.2,4,5.7,3.5l5.8,5.8c0.3,1.7,1.4,3.2,3,3.8c1.6,0.7,3.4,0.4,4.8-0.6L67,119c0.5,2.3,2.6,3.9,5,3.8c2.3-0.1,4.3-1.8,4.7-4.1
				c2.6,4.5,7.9,6.7,13,5.4c5-1.4,8.5-5.9,8.5-11.1c0-0.1,0-0.2,0-0.2c0.4-0.1,0.8-0.2,1.1-0.3h0.1c0.3-0.1,0.7-0.2,1-0.3l0.3-0.1
				c0.3-0.1,0.6-0.2,0.9-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.5-0.3,0.7-0.4c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.4-0.3,0.6-0.4
				c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.2,0.5-0.4c0.3-0.2,0.6-0.5,0.8-0.7c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.5-0.6,0.8-0.9
				c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.2-0.3,0.2-0.4
				c0.1-0.2,0.2-0.5,0.3-0.7s0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.5,0.3-0.7s0.1-0.3,0.2-0.5c0.1-0.3,0.1-0.5,0.2-0.8
				c0-0.2,0.1-0.3,0.1-0.5c0.1-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.3,0.1-0.4c0-0.4,0.1-0.9,0.1-1.4c0-1.4-0.2-2.8-0.6-4.2
				c0.6-0.6,1.2-1.3,1.7-2c1.4-1.8,2.2-3.9,2.2-6.2l0,0c0-0.3,0-0.5,0-0.8c0-1.4-0.2-2.8-0.7-4.1c3.9-2.3,6.1-6.7,5.6-11.3
				c0-0.1,0-0.3-0.1-0.4c0-0.3-0.1-0.6-0.2-0.9c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.2-0.7-0.3-1c0-0.1-0.1-0.2-0.1-0.3
				c-0.2-0.4-0.4-0.8-0.6-1.2V66c1-2.1,1.5-4.4,1.5-6.7c0-0.6,0-1.2-0.1-1.8c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.1-0.8-0.2-1.1
				c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.2-0.6-0.3-0.9s-0.2-0.5-0.3-0.8s-0.3-0.6-0.4-0.9c-0.2-0.5-0.5-0.9-0.7-1.3
				c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.3-0.4-0.5-0.6s-0.4-0.4-0.6-0.6c-0.4-0.4-0.8-0.7-1.2-1.1
				l-0.1-0.1c-0.5-0.4-1-0.7-1.5-1.1c0,0-0.1,0-0.1-0.1c1.3-3.5,0.8-7.5-1.3-10.6c-2.2-3.1-5.7-5-9.5-5c-1.1,0-2.3,0.2-3.4,0.5
				c-2.1-3.4-5.8-5.4-9.7-5.5s-7.7,2-9.8,5.3l0.1-0.4L66.5,29c-0.7-2.2-2.8-3.6-5-3.5c-2.3,0.2-4.2,1.9-4.5,4.1L45.3,39.9
				c-0.9-0.8-2-1.2-3.2-1.2c-2.3,0-4.3,1.6-4.8,3.8c-0.5,2.3,0.6,4.6,2.7,5.5l-4.1,12.2c-2.2,0.6-3.7,2.6-3.7,4.9
				C32.3,67.4,33.9,69.4,36.1,69.9L36.1,69.9z M39.3,60.6l4.1-12.2c0.2,0,0.4-0.1,0.6-0.2l8.6,11.1c-0.9,0.6-1.6,1.6-2,2.6L41.7,63
				C41.2,62,40.4,61.1,39.3,60.6z M60.3,35.1V39c-1.8,0.6-3.1,2.3-3.3,4.3c-0.2,1.9,0.9,3.8,2.6,4.7l-4.5,9.1l-8.6-11
				c0.6-1,0.8-2.1,0.6-3.2L58,33.4C58.5,34.1,59.3,34.7,60.3,35.1z M65.9,46.6l3.2,3.2c-0.3,0.7-0.5,1.4-0.5,2.1
				c0,2.7,2.2,4.9,4.9,4.9c0.6,0,1.1-0.1,1.6-0.3V67c-1.5-0.6-3.2-0.3-4.5,0.6l-10.5-5.3c-0.3-1.4-1.2-2.6-2.5-3.2l5.3-10.6
				C64.2,48.2,65.2,47.5,65.9,46.6z M73.5,50.2c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
				C71.8,51,72.6,50.2,73.5,50.2z M69.9,94.7c-0.6-1.7-2-2.9-3.8-3.2l-4.3-5.7v-0.1l12.3-9.2c0.3,0,0.7-0.1,1-0.2v18.4H69.9z
				 M67.5,115.6l-7.3-3.1c0.1-0.4,0.2-0.8,0.2-1.2c0-0.3,0-0.7-0.1-1l4.8-7.1l4.6,10.4C68.7,113.9,67.9,114.7,67.5,115.6z
				 M51.4,108.3l-3.8-3.8c0.7-0.9,1.1-2,1.1-3.1c0-0.1,0-0.2,0-0.3l12-2.7c0.4,0.9,1.1,1.7,2,2.2l-4.4,6.6
				C56.1,105.6,53,106.1,51.4,108.3L51.4,108.3z M45.3,96.6L44,86.1c1.1-0.5,2-1.3,2.6-2.4l5.7,2.3c0.4,1.6,1.6,2.9,3.1,3.5
				c1.6,0.6,3.3,0.3,4.6-0.7l2.6,3.5c-1,0.7-1.7,1.6-2,2.8L47.5,98C46.8,97.3,46.1,96.9,45.3,96.6z M55.3,61.8
				c0.9,0,1.6,0.7,1.6,1.6S56.2,65,55.3,65s-1.6-0.7-1.6-1.6C53.7,62.5,54.4,61.8,55.3,61.8z M59.7,65.8l9.1,4.5
				c-0.6,1.8,0,3.8,1.5,5l-9.2,6.9c-0.6-0.9-1.6-1.7-2.6-2L57.1,68C58.2,67.6,59.1,66.8,59.7,65.8z M57,83.2c0.9,0,1.6,0.7,1.6,1.6
				c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C55.3,83.9,56.1,83.2,57,83.2z M65.2,98c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
				c0.9,0,1.6,0.7,1.6,1.6C66.9,97.3,66.1,98,65.2,98z M73.5,73.3c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
				c0.9,0,1.6,0.7,1.6,1.6S74.4,73.3,73.5,73.3z M61.9,42c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
				C60.3,42.7,61,42,61.9,42z M42,66.3l8.8-1.1c0.6,1.4,1.7,2.4,3.1,2.9l1.3,12.2c-1.1,0.4-2.1,1.3-2.6,2.4l-5.7-2.3
				c-0.4-1.8-1.9-3.2-3.7-3.6l-3.7-7.3C40.7,68.7,41.6,67.6,42,66.3z M42.2,79.9c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
				s-1.6-0.7-1.6-1.6C40.5,80.6,41.2,79.9,42.2,79.9z M42.2,101.3c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
				C42.9,103,42.2,102.2,42.2,101.3z M53.7,111.2c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
				C54.4,112.9,53.7,112.1,53.7,111.2z M71.8,119.5c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
				C73.5,118.7,72.7,119.5,71.8,119.5z M73,113l-5.5-12.3c1.1-0.6,1.9-1.5,2.4-2.7h5.2v14.8c0,0.5,0,0.9,0.1,1.4
				C74.6,113.6,73.8,113.2,73,113z M78.4,37c0-3.9,2.7-7.2,6.5-8s7.7,1.2,9.2,4.7c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.9,0.1,1.3,0
				c1.1-0.5,2.3-0.8,3.5-0.8c2.7,0,5.1,1.3,6.7,3.4c1.5,2.2,2,4.9,1.1,7.4c-4.8-1.8-10.2-1.1-14.3,1.9c-4.2,2.9-6.7,7.7-6.7,12.8
				h3.3c0-4.3,2.2-8.3,5.8-10.5c3.6-2.3,8.2-2.5,12-0.5c1,0.5,1.9,1.1,2.7,1.8c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,0.8,0.9
				c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.3,0.5,0.6,0.7,0.9c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.2,0.3,0.3,0.5
				c0.2,0.4,0.3,0.9,0.5,1.3c0,0.1,0.1,0.3,0.1,0.5c0.1,0.4,0.2,0.7,0.2,1.1c0,0.2,0.1,0.3,0.1,0.5c0.2,1.8,0.1,3.6-0.5,5.3
				c-2.1-1.9-4.8-2.9-7.6-2.9v3.3c2.7,0,5.3,1.4,6.8,3.6c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.7,0.6,1.1c0.1,0.1,0.1,0.3,0.2,0.4
				c0.1,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.3,0.1,0.4c0.7,3.8-1.3,7.7-4.9,9.1c-0.4,0.2-0.8,0.5-0.9,1c-0.2,0.4-0.1,0.9,0.1,1.3
				c1.5,2.9,1.3,6.3-0.4,9c-1.5,2-4.1,2.9-6.5,2.4c-2.5-0.5-4.4-2.4-5-4.9c5.5-0.5,9.7-5.2,9.7-10.7h-3.3c0,4.1-3.3,7.4-7.4,7.4
				c-4.1,0-7.4-3.3-7.4-7.4h-3.3c0,5,3.5,9.3,8.3,10.4c0.4,2.9,2,5.4,4.5,7c2.4,1.6,5.4,2,8.2,1.1c0.3,0.9,0.4,1.9,0.4,2.9
				c0,0.4,0,0.7-0.1,1.1c0,0.1,0,0.2-0.1,0.4c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4-0.2,0.6
				c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.1-0.2,0.3-0.2,0.4
				c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.4
				c-0.1,0.1-0.2,0.2-0.3,0.3s-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.1-0.3,0.2
				c-0.2,0.1-0.4,0.3-0.6,0.4l-0.2,0.1c-0.2,0.1-0.5,0.3-0.7,0.4l-0.1,0.1c-0.3,0.1-0.6,0.2-0.8,0.3h-0.1c-1,0.3-2,0.5-3,0.6l0,0
				c-0.2,0-0.4,0-0.6,0c-5.9,0-10.7-4.8-10.7-10.7H82c0,7.4,5.8,13.5,13.2,14l0,0c0,4.6-3.7,8.2-8.2,8.2c-4.6,0-8.2-3.7-8.2-8.2V37
				H78.4z M75.1,37v10.2c-1.2-0.5-2.6-0.4-3.8,0.2l-4.6-4.6c-0.3-1.8-1.5-3.3-3.2-3.9V35c1.3-0.5,2.4-1.5,2.9-2.8l9.1,1.3
				C75.3,34.7,75.1,35.9,75.1,37z M61.9,28.8c0.9,0,1.6,0.7,1.6,1.6S62.8,32,61.9,32s-1.6-0.7-1.6-1.6C60.3,29.5,61,28.8,61.9,28.8z
				 M42.2,42c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C40.5,42.7,41.2,42,42.2,42z M37.2,63.4
				c0.9,0,1.6,0.7,1.6,1.6s-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6C35.6,64.2,36.3,63.4,37.2,63.4z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "analytics":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stAnalytics{fill:' + activeAnalytics + "}"}
					</style>
					<g>
						<g>
							<g>
								<g>
									<path className="stAnalytics" d="M122.4,120.3H27.5c-1.1,0-2.1,0.9-2.1,2.1c0,1.1,0.9,2.1,2.1,2.1h94.9c1.1,0,2.1-0.9,2.1-2.1
					C124.4,121.3,123.6,120.3,122.4,120.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M44,95.6H31.6c-1.1,0-2.1,0.9-2.1,2.1v24.7c0,1.1,0.9,2.1,2.1,2.1H44c1.1,0,2.1-0.9,2.1-2.1V97.7
					C46,96.4,45.1,95.6,44,95.6z M42,120.3h-8.2V99.7h8.3L42,120.3L42,120.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M68.8,74.9H56.4c-1.1,0-2.1,0.9-2.1,2.1v45.4c0,1.1,0.9,2.1,2.1,2.1h12.4c1.1,0,2.1-0.9,2.1-2.1V76.9
					C70.9,75.8,69.9,74.9,68.8,74.9z M66.8,120.3h-8.3V79h8.3V120.3L66.8,120.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M93.6,83.1H81.1c-1.1,0-2.1,0.9-2.1,2.1v37.1c0,1.1,0.9,2.1,2.1,2.1h12.4c1.1,0,2.1-0.9,2.1-2.1V85.2
					C95.6,84.1,94.7,83.1,93.6,83.1z M91.4,120.3h-8.3v-33h8.3V120.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M118.3,58.4h-12.4c-1.1,0-2.1,0.9-2.1,2.1v61.9c0,1.1,0.9,2.1,2.1,2.1h12.4c1.1,0,2.1-0.9,2.1-2.1V60.5
					C120.4,59.3,119.4,58.4,118.3,58.4z M116.3,120.3h-8.3V62.6h8.3V120.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M37.9,62.5c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3C46,66.3,42.4,62.5,37.9,62.5z
					 M37.9,74.9c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.9,4.1,4.1C42,73.1,40.1,74.9,37.9,74.9z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M62.5,41.9c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3c4.5,0,8.3-3.7,8.3-8.3S67.1,41.9,62.5,41.9z
					 M62.5,54.3c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.9,4.1,4.1C66.8,52.4,64.9,54.3,62.5,54.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M87.4,50.1c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3S91.9,50.1,87.4,50.1z M87.4,62.5
					c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.9,4.1,4.1S89.7,62.5,87.4,62.5z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M112.1,25.4c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3S116.7,25.4,112.1,25.4z
					 M112.1,37.8c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.9,4.1,4.1S114.4,37.8,112.1,37.8z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M109.2,36.5c-0.8-0.8-2.1-0.8-2.9,0l-16,16c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.6,1.5,0.6
					c0.5,0,1.1-0.2,1.5-0.6l16-16C109.9,38.8,109.9,37.3,109.2,36.5z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M82.4,53.6l-13.2-3.8c-1.1-0.3-2.2,0.3-2.5,1.4s0.3,2.2,1.4,2.5l13.2,3.8c0.2,0.1,0.4,0.1,0.6,0.1
					c0.9,0,1.7-0.6,2-1.5C84.1,55.1,83.4,53.9,82.4,53.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M59.4,52.8c-0.7-0.9-2-1-2.9-0.3L40.9,64.8c-0.9,0.7-1,2-0.3,2.9c0.4,0.5,1,0.8,1.6,0.8
					c0.4,0,0.9-0.1,1.3-0.4L59,55.8C60,54.9,60,53.6,59.4,52.8z"/>
								</g>
							</g>
						</g>
					</g>
				</svg>

			)
		case "live":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stLive{fill:' + activeLive + "}"}
					</style>
					<g>
						<g>
							<g>
								<g>
									<g>
										<path className="stLive" d="M66.6,31.3c-19.5,0-35.3,15.8-35.3,35.3s15.8,35.3,35.3,35.3s35.3-15.8,35.3-35.3S86.1,31.3,66.6,31.3z
						 M66.6,34.6c10.4,0,20.1,5.1,26.1,13.6l-4.2,4.2c-1-0.6-2.2-1-3.4-1c-3.7,0-6.7,3-6.7,6.7c0,1.2,0.3,2.4,1,3.4l-11,11
						c-2.1-1.3-4.7-1.3-6.8,0L54,64.9c0.6-1,0.9-2.2,1-3.4c0-3.7-3-6.7-6.7-6.7c-3.1,0-5.7,2.1-6.5,5h-6.2
						C38.6,45.2,51.6,34.7,66.6,34.6z M88.5,58.2c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4S88.5,56.3,88.5,58.2z
						 M68.3,78.4c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4S63,75,64.9,75S68.3,76.5,68.3,78.4z M51.5,61.5c0,1.9-1.5,3.4-3.4,3.4
						s-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4S51.5,59.7,51.5,61.5z M82.1,94.5c-4.7,2.6-10.1,4-15.5,4c-17.6,0-31.9-14.3-32-31.9
						c0-1.1,0.1-2.3,0.2-3.4h6.8c0.8,3,3.4,5,6.5,5c1.2,0,2.4-0.3,3.4-1l7.6,7.6c-0.6,1-0.9,2.2-1,3.4c0,3.7,3,6.7,6.7,6.7
						s6.7-3,6.7-6.7c0-1.2-0.3-2.4-1-3.4l11-11c1,0.6,2.2,0.9,3.4,1c3.7,0,6.7-3,6.7-6.7c0-1.2-0.3-2.4-1-3.4l3.7-3.7
						C103.1,66.5,97.5,86,82.1,94.5z"/>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<path className="stLive" d="M108.7,41.4c3.1,0,5.7-2.1,6.5-5h10.3V33h-10.3c-0.8-3-3.4-5-6.5-5c-3.7,0-6.7,3-6.7,6.7
						c0,1.2,0.3,2.4,1,3.4l-3,3c-14.2-18.5-40.6-22-59-7.9s-21.9,40.5-7.8,58.9c13.3,17.4,37.8,21.6,56.2,9.7l5.1,5.1l2.4,2.4
						l13.5,13.5c3.4,3.4,9,3.4,12.5,0s3.4-9,0-12.5l-13.5-13.5l-2.4-2.4l-5.1-5.1c9-13.9,9-31.8,0-45.6l3.4-3.4
						C106.3,41,107.5,41.3,108.7,41.4z M108.7,31.3c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4S106.8,31.3,108.7,31.3
						z M120.5,112.8c2.2,2.1,2.2,5.6,0.1,7.7c-2.1,2.2-5.6,2.2-7.7,0.1l-0.1-0.1L99.3,107l7.7-7.7L120.5,112.8z M104.6,96.8
						l-7.7,7.7l-4.7-4.7c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.3,0.7-0.6,1.1-0.9c0.2-0.2,0.4-0.3,0.5-0.5
						c0.7-0.6,1.4-1.3,2-2c0.2-0.2,0.3-0.4,0.5-0.5c0.3-0.4,0.6-0.7,0.9-1.1c0.2-0.2,0.4-0.5,0.6-0.8c0.2-0.2,0.4-0.5,0.6-0.7
						L104.6,96.8z M98.1,89c-0.5,0.7-1.1,1.5-1.7,2.2c-0.2,0.2-0.3,0.4-0.5,0.6c-0.5,0.6-1,1.2-1.6,1.7c-0.2,0.2-0.5,0.5-0.7,0.7
						c-0.6,0.5-1.1,1.1-1.7,1.6c-0.2,0.2-0.4,0.4-0.6,0.5c-0.7,0.6-1.4,1.1-2.2,1.7c-17.4,12.4-41.6,8.4-54-9s-8.4-41.6,9-54
						s41.6-8.4,54,9C107.7,57.5,107.7,75.6,98.1,89z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>

			)

		case "kpi":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stKPI{fill:' + activeKPI + "}"}
					</style>
					<g>
						<g>
							<g>
								<g>
									<path className="stKPI" d="M117,24.6c-0.4,0-1.4,0-1.6,0H56.1c-1.1,0-2,0.9-2,2s0.9,2,2,2H110c-0.8,1.3-1.2,2.7-1.2,4.3v25.7
					c0,1.1,0.9,2,2,2s2-0.9,2-2v-6.1h10.6c1.1,0,2-0.9,2-2V32.8C125.3,28.3,121.6,24.6,117,24.6z M121.4,48.5h-8.6V32.8
					c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3V48.5L121.4,48.5z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M123.2,104.6l-12.8-12.8c1.5-3,2.3-6.4,2.3-10c0-12.6-10.2-22.8-22.8-22.8S67.1,69.2,67.1,81.8
					c0,2.9,0.5,5.6,1.5,8.2l-2.3,2.3l-9.5-9.5c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6L51.8,85V67.1c0-1.1-0.9-2-2-2s-2,0.9-2,2V89
					L30,106.8c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l15-15v14.2h-8.6c-1.1,0-2,0.9-2,2v6.3
					c0,4.6,3.7,8.3,8.3,8.3h58.9c4.6,0,8.3-3.7,8.3-8.3v-1.7c1.4,1.3,3.2,2.1,5.1,2.1l0,0c2,0,3.8-0.8,5.2-2.2
					C126,112.2,126,107.5,123.2,104.6z M89.8,62.9c10.4,0,18.9,8.5,18.9,18.9s-8.5,18.9-18.9,18.9c-7.1,0-13.4-4-16.6-9.9l11.4-11.4
					v4.5c0,1.1,0.9,2,2,2s2-0.9,2-2v-9.4c0-1.1-0.9-2-2-2h-9.4c-1.1,0-2,0.9-2,2s0.9,2,2,2h4.9L71.7,86.9c-0.4-1.6-0.7-3.3-0.7-5.1
					C71,71.4,79.4,62.9,89.8,62.9z M45.5,121.2c-2.4,0-4.3-1.9-4.3-4.3v-4.3h55v2.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v1.6
					c0,1.6,0.4,3.1,1.2,4.3L45.5,121.2L45.5,121.2z M108.8,116.9c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3v-1.6
					c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-4.3c0-1.1-0.9-2-2-2H51.8V90.5l3.6-3.6l9.5,9.5c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6
					l2.7-2.7c4,6.5,11.2,10.9,19.4,10.9c3.6,0,6.9-0.8,10-2.3l9,9V116.9z M120.3,112.3c-0.7,0.7-1.5,1-2.5,1l0,0
					c-0.9,0-1.8-0.4-2.4-1l-12.2-12.2c1.9-1.4,3.6-3.1,5-4.9l12.2,12.2C121.7,108.8,121.7,111,120.3,112.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M102.3,79.8c-1.1,0-2,0.9-2,2c0,5.8-4.7,10.5-10.5,10.5c-1.1,0-2,0.9-2,2s0.9,2,2,2
					c7.9,0,14.4-6.5,14.4-14.4C104.2,80.7,103.4,79.8,102.3,79.8z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M75,58.4h-1.8V45.2c0-1.1-0.9-2-2-2h-9c-1.1,0-2,0.9-2,2v13.2H57V37.1c0-1.1-0.9-2-2-2h-8.6
					c-1.1,0-2,0.9-2,2v21.3h-3.1v-8.6c0-1.1-0.9-2-2-2h-8.5c-1.1,0-2,0.9-2,2v8.6h-2.2c-1.1,0-2,0.9-2,2s0.9,2,2,2H75
					c1.1,0,2-0.9,2-2C77,59.2,76.1,58.4,75,58.4z M37.5,58.4h-4.7v-6.7h4.7V58.4z M53.2,58.4h-4.7V39.1h4.7V58.4z M69.3,58.4h-5.1
					V47.2h5.1V58.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M91,35.2H75.8c-1.1,0-2,0.9-2,2s0.9,2,2,2H91c1.1,0,2-0.9,2-2S92.1,35.2,91,35.2z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M102.1,35.8c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6s-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
					c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6s0.6-0.9,0.6-1.4C102.7,36.6,102.5,36.1,102.1,35.8z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M83.4,43.8h-3.2c-1.1,0-2,0.9-2,2s0.9,2,2,2h3.2c1.1,0,2-0.9,2-2S84.5,43.8,83.4,43.8z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M100.7,43.8H91c-1.1,0-2,0.9-2,2s0.9,2,2,2h9.7c1.1,0,2-0.9,2-2S101.8,43.8,100.7,43.8z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M28.1,111.5c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
					c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4C28.6,112.4,28.4,111.9,28.1,111.5z"/>
								</g>
							</g>
						</g>
					</g>
				</svg>

			)

		case "expertise":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stExpertise{fill:' + activeExpertise + "}"}
					</style>
					<g>
						<g>
							<g>
								<path className="stExpertise" d="M81.7,72.4c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C81.6,70.7,81.3,71.6,81.7,72.4z"/>
							</g>
							<g>
								<path className="stExpertise" d="M83.2,53.8c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2c-0.3-0.9-1.2-1.3-2-1.1
				c-5,1.6-14.5,6.4-14.9,6.6c-0.8,0.4-1.1,1.4-0.7,2.2C82,53.4,82.6,53.8,83.2,53.8z"/>
							</g>
							<g>
								<path className="stExpertise" d="M122.3,50.5c-0.9,0-1.6,0.7-1.6,1.6v63.6c0,2.7-2.2,4.9-4.9,4.9H76.6v-3.8c2.4-0.8,8.6-2.8,14.7-2.8
				c14.2,0,23.9,3.1,24,3.2c0.5,0.2,1,0.1,1.5-0.2c0.4-0.3,0.7-0.8,0.7-1.3V43.9c0-0.8-0.5-1.4-1.3-1.6c0,0-1.3-0.3-3.5-0.7
				c-0.9-0.2-1.7,0.4-1.9,1.3s0.4,1.7,1.3,1.9c0.8,0.2,1.5,0.3,2.1,0.4v68.3c-3.7-1-11.9-2.7-22.8-2.7c-7.1,0-14.1,2.3-16.3,3.1
				c-1.9-0.8-7.7-3.1-14.7-3.1c-11.2,0-20.5,1.9-24.5,2.8V45.2c3.3-0.8,12.9-2.9,24.5-2.9c5.9,0,11,1.9,13.1,2.7v64.2
				c0,0.6,0.3,1.1,0.8,1.4c0.5,0.3,1.1,0.3,1.6,0c0.2-0.1,16.3-9.8,30.7-14.5c0.7-0.2,1.1-0.8,1.1-1.5V27.7c0-0.5-0.3-1-0.7-1.3
				s-1-0.4-1.5-0.2c-13.1,4.9-26.3,13.1-26.4,13.2c-0.8,0.5-1,1.5-0.5,2.2c0.5,0.8,1.5,1,2.2,0.5c0.1-0.1,11.6-7.2,23.6-12v63.3
				c-11.1,3.9-22.8,10.2-27.7,13V44c0-0.6-0.3-1.2-0.9-1.5c-0.3-0.1-7-3.4-15.4-3.4c-14.7,0-26.1,3.2-26.6,3.3
				c-0.7,0.2-1.2,0.8-1.2,1.6v71.8c0,0.5,0.2,1,0.6,1.3c0.3,0.2,0.6,0.3,1,0.3c0.2,0,0.3,0,0.4-0.1c0.1,0,11.4-3.2,25.7-3.2
				c6,0,11.1,1.9,13.1,2.7v3.8H34.2c-2.7,0-4.9-2.2-4.9-4.9V52.1c0-0.9-0.7-1.6-1.6-1.6s-1.6,0.7-1.6,1.6v63.6
				c0,4.5,3.7,8.2,8.2,8.2h81.5c4.5,0,8.2-3.7,8.2-8.2V52.1C123.9,51.2,123.2,50.5,122.3,50.5z"/>
							</g>
							<g>
								<path className="stExpertise" d="M81.7,62.7c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C81.6,60.9,81.3,61.9,81.7,62.7z"/>
							</g>
							<g>
								<path className="stExpertise" d="M81.7,82.2c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C81.6,80.5,81.3,81.4,81.7,82.2z"/>
							</g>
							<g>
								<path className="stExpertise" d="M65.6,56.3c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2c0.1,0,0.3,0,0.4-0.1
				c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C67,57.4,66.5,56.5,65.6,56.3z"/>
							</g>
							<g>
								<path className="stExpertise" d="M65.6,66.1c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2c0.1,0,0.3,0,0.4-0.1
				c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C67,67.2,66.5,66.3,65.6,66.1z"/>
							</g>
							<g>
								<path className="stExpertise" d="M81.7,92c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C81.6,90.2,81.3,91.2,81.7,92z"/>
							</g>
							<g>
								<path className="stExpertise" d="M65.6,75.9c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2c0.1,0,0.3,0,0.4-0.1
				c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C67,77,66.5,76.1,65.6,75.9z"/>
							</g>
							<g>
								<path className="stExpertise" d="M65.6,95.5c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2c0.1,0,0.3,0,0.4-0.1
				c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2S66.5,95.7,65.6,95.5z"/>
							</g>
							<g>
								<path className="stExpertise" d="M65.6,85.7c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2c0.1,0,0.3,0,0.4-0.1
				c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C67,86.8,66.5,85.9,65.6,85.7z"/>
							</g>
						</g>
					</g>
				</svg>

			)

		case "extrud3d":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stExtrud3d{fill:' + activeExtrud3d + "}"}
					</style>
					<g>
						<g>
							<g>
								<g>
									<path className="stExtrud3d" d="M111.9,25.4H38.1c-1.1,0-1.9,0.9-1.9,1.9v95.5c0,1.1,0.9,1.9,1.9,1.9h73.8c1.1,0,1.9-0.9,1.9-1.9V27.3
					C113.8,26.3,113,25.4,111.9,25.4z M49.2,120.9h-9.1V117h9.1V120.9z M49.2,113.1h-9.1v-5.9h9.1V113.1z M59.5,120.9h-6.4V117h6.4
					V120.9z M59.5,113.1h-6.4v-5.9h6.4V113.1z M71.1,120.9h-7.8V117h7.8V120.9z M71.1,113.1h-7.8v-5.9h7.8V113.1z M110,120.9H75
					v-13.6h35V120.9z M110,103.4H40V37h69.9v66.5H110z M110,33.2H40v-3.9h69.9v3.9H110z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M104.1,111.1H88.6c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9h15.5c1.1,0,1.9-0.9,1.9-1.9
					S105.2,111.1,104.1,111.1z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M80.8,111.1c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9S81.9,111.1,80.8,111.1z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M97.3,47.5c0-1-0.9-1.9-1.9-1.9H70.3c-0.5,0-1,0.2-1.4,0.6L53.2,62.5c-0.3,0.4-0.5,0.9-0.5,1.3v27.2
					c0,0.3,0.1,0.5,0.2,0.7c0.3,0.7,1,1.2,1.8,1.2h27.2c0.5,0,1.1-0.3,1.5-0.7L97,76.3c0.3-0.4,0.5-0.8,0.5-1.3
					C97.3,75.3,97.3,47.5,97.3,47.5z M79.9,89.1H59.1c0.3-0.7,0.2-1.6-0.4-2.2c-0.6-0.6-1.4-0.7-2.2-0.4V65.8h13.7l0,0h9.6v23.3
					H79.9z M80.9,61.9H70.3l0,0h-11l9.2-9.5V56c0,1.1,0.9,1.9,1.9,2l0,0l0,0l0,0c1.1,0,1.9-0.9,1.9-1.9v-6.6h19L80.9,61.9z
					 M93.4,73.4h-3.9c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9h1.6l-7.4,8.6V64.4l9.7-11.7C93.4,52.8,93.4,73.4,93.4,73.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M74,73.4h-1.9v-1.7c0-1.1-0.9-1.9-1.9-2l0,0c-1.1,0-1.9,0.9-1.9,1.9v2.9L67,75.8c-0.8,0.8-0.7,2,0,2.7
					c0.4,0.4,0.9,0.5,1.4,0.5s1-0.2,1.4-0.6l1.3-1.3h3.1c1.1,0,1.9-0.9,1.9-1.9S75.1,73.4,74,73.4z"/>
								</g>
							</g>
							<g>
								<g>
									<circle className="stExtrud3d" cx="62.8" cy="82.6" r="1.9"/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)

		case "rex":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stREX{fill:' + activeREX + "}"}
					</style>
					<g>
						<g>
							<path className="stREX" d="M105.8,106.8h1.5v5.9l0,0l4.9-5.9h1.7l-3.7,4.5l4.3,7.3h-1.7l-3.5-6.1l-2,2.4v3.7h-1.5V106.8z"/>
							<path className="stREX" d="M121.9,108h-3.6v-1.3h8.6v1.3h-3.6v10.5h-1.5V108H121.9z"/>
							<path className="stREX" d="M132.7,106.8h3.4c2.6,0,4.4,0.9,4.4,3.5c0,2.5-1.8,3.6-4.4,3.6h-1.9v4.7h-1.5V106.8z M136,112.6
			c2.1,0,3-0.7,3-2.4s-1-2.3-3.1-2.3h-1.7v4.7H136z"/>
						</g>
						<g>
							<g>
								<path className="stREX" d="M142,103.3v-0.1L155.6,88c0.3-0.3,0.3-0.8-0.1-1.1c-0.3-0.3-0.8-0.3-1.1,0.1l-13,14.5l-21.5-53.2H147
				c0.4,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8h-27.8l-6-14.9l0,0c0-0.1-0.1-0.2-0.1-0.2l0,0l0,0c-0.1-0.1-0.1-0.1-0.2-0.1l0,0
				c-0.1,0-0.2-0.1-0.3-0.1l0,0H105l0,0l0,0l0,0c-0.1,0-0.1,0-0.2,0h-0.1l0,0l0,0l-0.1,0.1c0,0-0.1,0-0.1,0.1l0,0l0,0l0,0L90.9,46.8
				H74.4l-6-14.9l0,0c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2l0,0c-0.1,0-0.2-0.1-0.3-0.1l0,0h-7.9l0,0l0,0l0,0
				c-0.1,0-0.1,0-0.2,0h-0.1l0,0l0,0l-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1l0,0L45.6,46.9H29.2l-6-14.9l0,0
				c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1L23,31.6l-0.1-0.1c0,0,0,0-0.1,0s-0.2,0-0.2,0l0,0h-7.8l0,0l0,0l0,0c-0.1,0-0.1,0-0.2,0
				h-0.1l0,0l0,0l-0.1,0.1c0,0-0.1,0-0.1,0.1l0,0l0,0l0,0L0.7,46.9h-5.6c-0.4,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8h15.6
				c1.7,0,5.1-1,6.3-4.9c0.1-0.4-0.1-0.9-0.5-1s-0.9,0.1-1,0.5c-1.1,3.7-4.6,3.8-4.8,3.8H2.8l11.8-13.1L36,86.6H8.3
				c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h28.4l6.1,15.1l0,0l0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1
				c0.1,0,0.1,0.1,0.2,0.1h0.1c0.1,0,0.2,0.1,0.3,0.1l0,0h7.6l0,0l0,0c0.1,0,0.2,0,0.3-0.1l0,0l0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1
				v-0.1l13.4-14.9h16.3l6.1,15.1l0,0l0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1h0.1c0.1,0,0.2,0.1,0.3,0.1
				l0,0h7.6l0,0l0,0c0.1,0,0.2,0,0.3-0.1l0,0c0,0,0.1,0,0.1-0.1l0.1-0.1l0.1-0.1l0.1-0.1v-0.1l13.4-14.9h16.2c0.1,0,0.1,0,0.2,0
				l6.1,15.1l0,0l0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1h0.1c0.1,0,0.2,0.1,0.3,0.1l0,0h7.6l0,0
				c0.1,0,0.2,0,0.3-0.1l0,0c0.1,0,0.2-0.1,0.2-0.1l0,0c0,0,0-0.1,0.1-0.1C141.9,103.5,141.9,103.4,142,103.3L142,103.3z M57.2,86.6
				c-1.7,0-5.1,1-6.3,4.9c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6c1.1-3.7,4.6-3.8,4.8-3.8h6l-11.8,13.2
				L29.9,48.1h25.2c1.7,0,5.1-1,6.3-4.9c0.1-0.4-0.1-0.9-0.5-1s-0.9,0.1-1,0.5c-1.1,3.7-4.6,3.8-4.8,3.8h-7.3l11.7-13.1L81,86.6
				L57.2,86.6L57.2,86.6z M102,86.6c-1.7,0-5.1,1-6.3,4.9c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6
				c1.1-3.7,4.6-3.8,4.8-3.8h6.2l-11.8,13.2L74.9,48.1h25.2c1.7,0,5.1-1,6.3-4.9c0.1-0.4-0.1-0.9-0.5-1s-0.9,0.1-1,0.5
				c-1.1,3.7-4.6,3.8-4.8,3.8h-7.2l11.7-13.1l21.5,53.2L102,86.6L102,86.6z"/>
							</g>
						</g>
					</g>
				</svg>

			)

		case "chillware":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 224.4 224.4" space="preserve">
					<style type="text/css">
						{'.stChillware{fill:' + activeChillware + "}"}
					</style>
					<g>
						<g>
							<g>
								<path className="stChillware" d="M4.2,113.2c0-8.8,4.8-14.4,11.9-14.4c3.3,0,5.8,1.6,7.2,3.3l-1.1,1.3c-1.5-1.7-3.5-2.9-6.1-2.9
				c-6.1,0-9.9,4.9-9.9,12.6c0,7.7,3.7,12.7,9.7,12.7c2.9,0,5-1.2,7-3.4l1.1,1.2c-2.1,2.5-4.7,4-8.3,4C9,127.7,4.2,122,4.2,113.2z"
								/>
								<path className="stChillware" d="M31.7,96.7h1.9v8.8l0,4.5c2.1-2.1,4.2-3.7,7-3.7c4.2,0,6.1,2.5,6.1,7.8v13h-1.9v-12.8c0-4.3-1.4-6.3-4.6-6.3
				c-2.4,0-4.2,1.3-6.6,3.8v15.3h-1.9V96.7z"/>
								<path className="stChillware" d="M55.4,100.3c0-1,0.8-1.7,1.7-1.7c0.9,0,1.7,0.6,1.7,1.7c0,1-0.8,1.7-1.7,1.7C56.2,102,55.4,101.3,55.4,100.3
				z M56.2,106.9H58v20.3h-1.9V106.9z"/>
								<path className="stChillware" d="M67.9,124.4V96.7h1.9v27.9c0,0.9,0.4,1.3,0.9,1.3c0.2,0,0.3,0,0.8-0.1l0.3,1.5c-0.4,0.1-0.7,0.2-1.3,0.2
				C68.8,127.7,67.9,126.7,67.9,124.4z"/>
								<path className="stChillware" d="M80.1,124.4V96.7h1.9v27.9c0,0.9,0.4,1.3,0.9,1.3c0.2,0,0.3,0,0.8-0.1l0.3,1.5c-0.4,0.1-0.7,0.2-1.3,0.2
				C80.9,127.7,80.1,126.7,80.1,124.4z"/>
								<path className="stChillware" d="M88.9,99.6h6.4l1.9,12.9c0.4,2.9,0.8,5.8,1.1,8.7h0.2c0.5-2.9,1.1-5.8,1.6-8.7l3-12.9h5.3l3,12.9
				c0.6,2.8,1.1,5.8,1.7,8.7h0.2c0.4-2.9,0.7-5.9,1.1-8.7l1.9-12.9h5.9l-4.9,27.5h-7.8l-2.7-12.5c-0.4-2.1-0.8-4.3-1.1-6.4h-0.2
				c-0.3,2.1-0.7,4.3-1.1,6.4l-2.6,12.5H94L88.9,99.6z"/>
								<path className="stChillware" d="M133.2,99.6h7.5l8.7,27.5h-6.6l-3.6-14c-0.8-2.8-1.5-6-2.2-8.9h-0.2c-0.7,2.9-1.4,6.1-2.2,8.9l-3.6,14h-6.4
				L133.2,99.6z M130.2,115.7h13.3v4.8h-13.3V115.7z"/>
								<path className="stChillware" d="M154,99.6h9.9c5.8,0,10.6,2,10.6,8.6c0,6.3-4.8,9.1-10.6,9.1h-3.7v9.9H154V99.6z M163.4,112.3
				c3.3,0,5-1.4,5-4.1c0-2.7-1.8-3.6-5-3.6h-3.2v7.7H163.4z M162.8,115.6l4.3-4l8.8,15.5h-7L162.8,115.6z"/>
								<path className="stChillware" d="M182,99.6h17.3v5.2h-11v5.5h9.4v5.2h-9.4v6.3h11.5v5.2H182V99.6z"/>
								<path className="stChillware" d="M204.8,105.3c0-4.9,3.5-8.2,7.7-8.2s7.7,3.3,7.7,8.2c0,4.9-3.5,8.2-7.7,8.2S204.8,110.2,204.8,105.3z
				 M218.9,105.3c0-4-2.8-7-6.4-7c-3.6,0-6.4,3-6.4,7c0,4,2.8,6.9,6.4,6.9C216.1,112.2,218.9,109.3,218.9,105.3z M209.6,100.8h2.9
				c1.6,0,3.2,0.6,3.2,2.7c0,1.1-0.7,2.1-1.7,2.4l2.1,3.7h-1.6l-1.6-3.2h-2v3.2h-1.3V100.8z M212.2,105.1c1.3,0,2.2-0.4,2.2-1.6
				c0-1-0.5-1.6-2-1.6H211v3.2H212.2z"/>
							</g>
						</g>
					</g>
				</svg>
			)

		case "autonomous":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stAutonomous{fill:' + activeAutonomous + "}"}
					</style>
					<g>
						<g>
							<g>
								<path className="stAutonomous" d="M124.1,66.6c-0.1-0.8-0.8-1.4-1.6-1.4h-8.9c-0.9-3.6-2.4-7.1-4.3-10.3l6.3-6.3c0.6-0.6,0.7-1.5,0.2-2.1
				c-3.3-4.8-7.4-8.9-12.2-12.2c-0.7-0.5-1.6-0.4-2.1,0.2l-6.3,6.3c-3.2-1.9-6.7-3.3-10.3-4.3v-8.9c0-0.8-0.6-1.5-1.4-1.6
				c-5.7-1-11.5-1-17.2,0c-0.1,0-0.2,0.1-0.4,0.1c-4.7-1.7-9.9-1-14.1,1.8c-4.1,2.8-6.7,7.4-6.9,12.4c-0.4,0-0.8,0.1-1.2,0.2
				c-0.1,0-0.1,0-0.2,0c-0.5,0.1-0.9,0.2-1.4,0.4c-0.1,0-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0.1-0.3,0.2-0.5,0.2
				c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.3,0.2-0.5,0.3s-0.5,0.3-0.7,0.4S38,42.9,37.8,43c-0.2,0.2-0.4,0.3-0.6,0.5
				c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.4,0.4-0.5,0.6s-0.3,0.3-0.4,0.5
				c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.2,0.4-0.3,0.5
				c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.1,0.4-0.2,0.6
				c-0.1,0.3-0.1,0.6-0.2,0.8c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.1,0.6-0.1,1c0,0.2-0.1,0.3-0.1,0.5c0,0.5-0.1,1-0.1,1.5
				c0,2,0.4,4,1.2,5.8c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.6,0.4-1.2,0.9-1.7,1.4c-0.1,0.1-0.1,0.1-0.2,0.2
				c-0.3,0.3-0.6,0.6-0.9,1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.7,1.1-1.3,2.3-1.7,3.5
				c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.2,0.8c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.2-0.1,0.5-0.1,0.7s0,0.4-0.1,0.6V74
				c0,0.4,0,0.8,0,1.3c0,4.8,2.1,9.3,5.7,12.5c-0.7,1.2-1.3,2.5-1.7,3.9c0,0.1,0,0.2-0.1,0.2c-0.2,0.6-0.3,1.3-0.4,1.9
				c0,0.1,0,0.2,0,0.3c-0.1,0.7-0.1,1.3-0.1,2c0,7.4,5.1,13.8,12.2,15.4c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5
				c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.1,0.1,0.3,0.1,0.4c0.7,1.6,1.6,3,2.6,4.3c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.3,0.6,0.7,0.9,1
				c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,0.9,0.8c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.3,0.6,0.5,1,0.7c0.1,0.1,0.2,0.2,0.4,0.3
				c0.5,0.3,0.9,0.6,1.4,0.8c0.1,0,0.2,0.1,0.3,0.1c0.4,0.2,0.8,0.4,1.2,0.6c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.6,0.2,0.9,0.3
				c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.6,0.2,1,0.2c0.2,0.1,0.5,0.1,0.7,0.1c0.4,0.1,0.7,0.1,1.1,0.2c0.2,0,0.4,0.1,0.6,0.1
				c0.6,0.1,1.1,0.1,1.7,0.1c2.4,0,4.8-0.5,7-1.4c0.2,0.3,0.6,0.6,1,0.6c5.7,1,11.5,1,17.2,0c0.8-0.1,1.4-0.8,1.4-1.6v-8.9
				c3.6-0.9,7.1-2.4,10.3-4.3l6.3,6.3c0.6,0.6,1.5,0.7,2.1,0.2c4.8-3.3,8.9-7.4,12.2-12.2c0.5-0.7,0.4-1.6-0.2-2.1l-6.3-6.3
				c1.9-3.2,3.3-6.7,4.3-10.3h8.9c0.8,0,1.5-0.6,1.6-1.4C125.1,78.2,125.1,72.4,124.1,66.6z M58.3,121.9c-0.5,0-1,0-1.4-0.1
				c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2-0.1-0.4-0.1-0.6-0.2
				c-0.2-0.1-0.5-0.2-0.7-0.3s-0.4-0.2-0.6-0.2c-0.2-0.1-0.5-0.2-0.7-0.3s-0.3-0.2-0.5-0.3c-0.3-0.2-0.5-0.3-0.8-0.5
				c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8-0.5-1.5-1.1-2.2-1.8c0,0,0,0-0.1-0.1c-0.3-0.3-0.6-0.6-0.9-1c0-0.1-0.1-0.1-0.1-0.2
				c-0.2-0.3-0.5-0.6-0.7-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.2-0.6-0.4-1c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.3-0.2-0.7-0.3-1
				c0-0.1-0.1-0.2-0.1-0.3c-0.8-4.2,0.1-8.5,2.7-12c0.5-0.7,0.4-1.8-0.3-2.3c-0.7-0.6-1.8-0.4-2.3,0.3c-2.4,3.2-3.7,7-3.7,11
				c0,0.3,0,0.7,0,1c-5-1.7-8.4-6.5-8.4-11.8c0-0.7,0.1-1.4,0.2-2c0-0.1,0-0.1,0.1-0.2c0.3-1.9,1.2-3.8,2.4-5.3l0,0
				c1.6-2,3.7-3.5,6.2-4.3c0.9-0.3,1.4-1.2,1.1-2.1c-0.3-0.9-1.2-1.4-2.1-1.1c-2.6,0.8-4.9,2.2-6.8,4.2c-2.8-2.5-4.4-6.1-4.4-9.9
				c0-0.6,0-1.1,0.1-1.7c0-0.2,0.1-0.4,0.1-0.5c0.1-0.4,0.1-0.8,0.2-1.1c0-0.2,0.1-0.4,0.1-0.6c0.1-0.5,0.3-0.9,0.5-1.3
				c0.1-0.3,0.2-0.5,0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.3-0.5,0.5-0.8c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.4,0.7-0.9,1.1-1.3
				c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.4-0.4,0.6-0.6s0.5-0.4,0.7-0.6c0.2-0.1,0.4-0.3,0.6-0.4c0.4-0.3,0.8-0.5,1.2-0.7
				c1.9-1,4-1.5,6.1-1.5c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-2,0-3.9,0.4-5.8,1.1c-0.6-1.4-0.9-2.9-0.9-4.4
				c0-5.3,3.5-9.9,8.6-11.3s10.5,0.9,13.1,5.4c0.3,0.5,0.8,0.8,1.4,0.8s1.1-0.3,1.4-0.8s0.3-1.2,0-1.7c-2.4-4.1-6.6-6.8-11.3-7.4
				c0.4-6.7,6.1-12,12.8-11.8s12.1,5.7,12.1,12.5v10.3l-7.2,10.1l-6.1-2.7c0-0.1,0-0.2,0-0.2c0-2.4-1.7-4.5-4.1-5s-4.8,0.9-5.7,3.1
				c-0.9,2.3,0,4.9,2.1,6.1c2.1,1.3,4.8,0.9,6.4-0.9l6,2.7c0.4,0.2,0.9,0.3,1.3,0.3c1.1,0,2.1-0.5,2.7-1.4l4.5-6.3v13.1H59.6
				c-0.8-2.3-3.2-3.7-5.6-3.3s-4.2,2.5-4.2,4.9c0,2.4,1.8,4.5,4.2,4.9c2.4,0.4,4.7-1,5.6-3.3h13.6v6.7h-3.6C68.8,78,66.4,76.6,64,77
				c-2.4,0.4-4.2,2.5-4.2,4.9s1.8,4.5,4.2,4.9c2.4,0.4,4.7-1,5.6-3.3h3.6v6.7H59.6c-1.6,0-3,1.1-3.3,2.7l-1.2,5.9
				c-2.3,0.7-3.8,3-3.5,5.4s2.3,4.2,4.7,4.4c2.4,0.1,4.6-1.5,5.1-3.9s-0.7-4.7-2.9-5.7l1.1-5.4h13.6v13.3
				C73.2,115.2,66.5,121.9,58.3,121.9z M56.6,58.6c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7
				C55.8,56.9,56.6,57.7,56.6,58.6z M56.6,71.9c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
				C55.8,70.4,56.6,71,56.6,71.9z M66.6,81.9c0,0.9-0.7,1.7-1.7,1.7c-1,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
				C65.8,80.4,66.6,81,66.6,81.9z M58.3,103.7c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
				C57.5,101.9,58.3,102.7,58.3,103.7z M121.1,81.9h-8.8c-0.8,0-1.5,0.5-1.6,1.3c-0.9,4.1-2.5,8.1-4.8,11.7
				c-0.4,0.7-0.3,1.5,0.2,2.1l6.2,6.2c-2.7,3.6-5.8,6.8-9.4,9.4l-6.2-6.2c-0.6-0.6-1.4-0.6-2.1-0.2c-3.6,2.3-7.5,3.9-11.7,4.8
				c-0.8,0.2-1.3,0.8-1.3,1.6v8.8c-4.1,0.6-8.2,0.6-12.3,0.1c4.6-3.4,7.3-8.9,7.3-14.6v-8.4c12.2-0.9,21.7-11,21.7-23.2
				s-9.5-22.4-21.7-23.2V41.2c0-4.8-2.2-9.3-5.9-12.3c3.6-0.3,7.3-0.2,10.9,0.3V38c0,0.8,0.5,1.5,1.3,1.6c4.1,0.9,8.1,2.5,11.7,4.8
				c0.7,0.4,1.5,0.3,2.1-0.2l6.2-6.2c3.6,2.7,6.8,5.8,9.4,9.4l-6.2,6.2c-0.6,0.6-0.6,1.4-0.2,2.1c2.3,3.6,3.9,7.5,4.8,11.7
				c0.2,0.8,0.8,1.3,1.6,1.3h8.8C121.7,73,121.7,77.5,121.1,81.9z M86.6,75.4c0-5.8-4.3-10.7-10-11.5v-8.4
				C87,56.4,94.9,65,94.9,75.4s-8,19-18.3,19.9v-8.4C82.3,85.9,86.6,81,86.6,75.4z M76.6,83.4V67.1c3.9,0.8,6.7,4.2,6.7,8.2
				C83.2,79.2,80.5,82.7,76.6,83.4z"/>
							</g>
						</g>
					</g>
				</svg>

			)

		case "logout":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 50 50" space="preserve">
					<style type="text/css">
						{'.st10{fill:#64686E;}'}
					</style>
					<g>
						<g>
							<g>
								<g>
									<path className="st10" d="M25.6,12.2c-2.7,0-5.3,0.8-7.5,2.4c-0.3,0.2-0.3,0.5-0.2,0.8l2.1,3.3c0.1,0.1,0.2,0.2,0.4,0.3
					c0.2,0,0.3,0,0.5-0.1c1.3-0.9,2.8-1.4,4.4-1.4c4.1,0,7.5,3.3,7.5,7.5s-3.3,7.5-7.5,7.5c-1.6,0-3.1-0.5-4.4-1.4
					c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0.1-0.4,0.3l-2.1,3.3c-0.2,0.3-0.1,0.6,0.2,0.8c2.2,1.6,4.8,2.4,7.5,2.4
					c7,0,12.8-5.7,12.8-12.8S32.6,12.2,25.6,12.2z"/>
									<path className="st10" d="M19.8,29.8c0.1-0.2,0.1-0.4,0-0.6l-1.4-2.1h8.4c1.2,0,2.1-0.9,2.1-2.1s-0.9-2.1-2.1-2.1h-8.4l1.4-2.1
					c0.1-0.2,0.1-0.4,0-0.6c-0.1-0.2-0.3-0.3-0.5-0.3h-4c-0.2,0-0.4,0.1-0.5,0.3l-3,4.5c-0.1,0.2-0.1,0.5,0,0.7l3,4.5
					c0.1,0.2,0.3,0.3,0.5,0.3h4C19.5,30.1,19.7,30,19.8,29.8z"/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)

		case "grid":
			return (
				<svg viewBox="0 0 111 74" className="grid-honeycomb">
					<defs>
						<g id="pod">
							<polygon points="5,-9 -5,-9 -10,0 -5,9 5,9 10,0" />
						</g>
						<g id="dashboard-grid-control">
							<SvgRendering iconName="control-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-expertise">
							<SvgRendering iconName="expertise-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-assistant">
							<SvgRendering iconName="assistant-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-troubleshooter">
							<SvgRendering iconName="troubleshooter-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-kpi">
							<SvgRendering iconName="kpi-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-analytics">
							<SvgRendering iconName="analytics-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-live">
							<SvgRendering iconName="live-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-extrud3d">
							<SvgRendering iconName="extrud3d-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-simulation">
							<SvgRendering iconName="simulation-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-rex">
							<SvgRendering iconName="rex-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-odis">
							<SvgRendering iconName="odis-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-autonomous">
							<SvgRendering iconName="autonomous-dashboard" modules={props.modules}/>
						</g>
						<g id="dashboard-grid-hvi">
							<SvgRendering iconName="hvi-dashboard" modules={props.modules}/>
						</g>
						<linearGradient id="gradient" x2="0.55" y2="0">
							<stop offset="0%" stopColor="var(--color-stop)" />

							<stop offset="100%" stopColor="var(--color-bot)" />
						</linearGradient>
					</defs>
					<g className="pod-wrap">
						<use href="#pod" className="grid-secondary" transform="translate(10, 10)"/>
						<use href="#pod" className="grid-secondary" transform="translate(10, 29)"/>
						<use href="#pod" className="grid-secondary" transform="translate(10, 48)"/>

						<Link to={linkControl} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(26, 19.5)"/>
							<use href="#dashboard-grid-control" transform="translate(-0.75 ,11)" />
						</Link>
						<Link to={linkExpertise} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(26, 38.5)"/>
							<use href="#dashboard-grid-expertise" transform="translate(-0.75 ,30)" />
						</Link>
						<Link to={linkHvi} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(26, 57.5)"/>
							<use href="#dashboard-grid-hvi" transform="translate(-0.75 ,50)"></use>
						</Link>

						<use href="#pod" className="grid-secondary" transform="translate(42, 10)"/>
						<Link to={linkAssistant} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(42, 29)"/>
							<use href="#dashboard-grid-assistant" transform="translate(15.25 ,21)" />
						</Link>
						<Link to={linkKPI} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(42, 48)"/>
							<use href="#dashboard-grid-kpi" transform="translate(15 ,39.5)" />
						</Link>

						<Link to={linkAnalytics} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(58, 19.5)"/>
							<use href="#dashboard-grid-analytics" transform="translate(31.25 ,11)" />
						</Link>
						<Link to={linkLive} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(58, 38.5)"/>
							<use href="#dashboard-grid-live" transform="translate(31.25 ,30)" />
						</Link>
						<Link to={linkTroubleshooter} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(58, 57.5)"/>
							<use href="#dashboard-grid-troubleshooter" transform="translate(31.25 ,50.5)" />
						</Link>

						<use href="#pod" className="grid-secondary" transform="translate(74, 10)"/>
						<Link to={linkExtrud3d} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(74, 29)"/>
							<use href="#dashboard-grid-extrud3d" transform="translate(47.25 ,21)" />
						</Link>
						<Link to={linkChillware} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(74, 48)"/>
							<use href="#dashboard-grid-simulation" transform="translate(47.25 ,39.5)" />
						</Link>
						<Link to={linkREX} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(90, 19.5)"/>
							<use href="#dashboard-grid-rex" transform="translate(63.25 ,11)" />
						</Link>
						<Link to={linkOdis} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(90, 38.5)"/>
							<use href="#dashboard-grid-odis" transform="translate(63.25 ,30)" />
						</Link>
						<Link to={linkAutonomous} className="hover-grid">
							<use href="#pod" className="grid-primary" transform="translate(90, 57.5)"/>
							<use href="#dashboard-grid-autonomous" transform="translate(63.25 ,49.5)" />
						</Link>
					</g>
				</svg>
			)
		case "odis":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stOdis{fill:' + activeOdis + "}"}
					</style>
					<g>
						<g>
							<g>
								<g>
									<g>
										<path className="stOdis" d="M42.3,72.6h-6.4l-6-6.7h-4.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h6l6,6.7h4.7c1.1,0,2,0.9,2,2S43.4,72.6,42.3,72.6
						z"/>
									</g>
									<g>
										<path className="stOdis" d="M114.1,72.6h-6.4c-1.1,0-2-0.9-2-2s0.9-2,2-2h4.7l6-6.7h5.3c1.1,0,2,0.9,2,2s-0.9,2-2,2h-3.6L114.1,72.6z"
										/>
									</g>
									<g>
										<path className="stOdis" d="M93.9,72.6h-6.1l-7.1-7.1H69.3l-6.8,6.8h-6.4c-1.1,0-2-0.9-2-2s0.9-2,2-2h4.7l6.8-6.8h14.8l7.1,7.1h4.4
						c1.1,0,2,0.9,2,2S95.1,72.6,93.9,72.6z"/>
									</g>
								</g>
								<g>
									<g>
										<path className="stOdis" d="M31.6,92.2h-5c-1.1,0-2-0.9-2-2s0.9-2,2-2h3.2l6-6.7h6.4c1.1,0,2,0.9,2,2s-0.9,2-2,2h-4.7L31.6,92.2z"/>
									</g>
									<g>
										<path className="stOdis" d="M123,92.2h-4.7l-6-6.7h-4.7c-1.1,0-2-0.9-2-2s0.9-2,2-2h6.4l6,6.7h2.9c1.1,0,2,0.9,2,2
						S124.1,92.2,123,92.2z"/>
									</g>
									<g>
										<path className="stOdis" d="M82.4,92.6H67.6l-6.8-6.8h-4.7c-1.1,0-2-0.9-2-2s0.9-2,2-2h6.4l6.8,6.8h11.5l7.1-7.1h6.1c1.1,0,2,0.9,2,2
						s-0.9,2-2,2h-4.4L82.4,92.6z"/>
									</g>
								</g>
							</g>
							<g>
								<g>
									<path className="stOdis" d="M75,105.1c-15.5,0-28.1-12.6-28.1-28.1S59.5,49,75,49c15.5,0,28.1,12.6,28.1,28.1S90.5,105.1,75,105.1z
					 M75,53c-13.3,0-24.1,10.8-24.1,24.1s10.8,24.1,24.1,24.1s24.1-10.8,24.1-24.1S88.3,53,75,53z"/>
								</g>
								<g>
									<path className="stOdis" d="M75,85c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9c4.4,0,7.9,3.5,7.9,7.9S79.4,85,75,85z M75,73.2
					c-2.1,0-3.9,1.7-3.9,3.9S72.9,81,75,81s3.9-1.7,3.9-3.9S77.1,73.2,75,73.2z"/>
								</g>
							</g>
							<g>
								<path className="stOdis" d="M74.2,111.3c-19.8,0-39.7-9.9-66.2-33l-0.7-0.6v-2L8,75.2c26.5-23.1,46.3-33,66.2-33c0.2,0,0.4,0,0.5,0
				c19.5,0.2,39.6,10,67.3,33l0.7,0.6v2l-0.7,0.6c-27.6,22.9-47.8,32.8-67.3,33C74.5,111.3,74.4,111.3,74.2,111.3z M12.3,76.7
				c25.1,21.6,43.8,30.8,62.4,30.6c18.1-0.2,37-9.3,63-30.6c-26-21.3-44.9-30.4-63-30.6c-0.2,0-0.3,0-0.5,0
				C55.8,46.1,37.2,55.3,12.3,76.7z"/>
							</g>
							<g>
								<path className="stOdis" d="M29.9,138.3h-17v-17c0-1.1,0.9-2,2-2s2,0.9,2,2v13h13c1.1,0,2,0.9,2,2S31,138.3,29.9,138.3z"/>
							</g>
							<g>
								<path className="stOdis" d="M136.8,138.3h-17c-1.1,0-2-0.9-2-2s0.9-2,2-2h13v-13c0-1.1,0.9-2,2-2s2,0.9,2,2V138.3z"/>
							</g>
							<g>
								<path className="stOdis" d="M134.8,33.4c-1.1,0-2-0.9-2-2v-13h-13c-1.1,0-2-0.9-2-2s0.9-2,2-2h17v17C136.8,32.5,135.9,33.4,134.8,33.4z"
								/>
							</g>
							<g>
								<path className="stOdis" d="M14.9,33.4c-1.1,0-2-0.9-2-2v-17h17c1.1,0,2,0.9,2,2s-0.9,2-2,2h-13v13C16.9,32.5,16,33.4,14.9,33.4z"/>
							</g>
							<g>
								<path className="stOdis" d="M38.2,127.2H24V113c0-1.1,0.9-2,2-2s2,0.9,2,2v10.2h10.2c1.1,0,2,0.9,2,2S39.3,127.2,38.2,127.2z"/>
							</g>
							<g>
								<path className="stOdis" d="M125.7,127.2h-14.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h10.2V113c0-1.1,0.9-2,2-2s2,0.9,2,2V127.2z"/>
							</g>
							<g>
								<path className="stOdis" d="M123.7,41.7c-1.1,0-2-0.9-2-2V29.5h-10.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h14.2v14.2
				C125.7,40.8,124.8,41.7,123.7,41.7z"/>
							</g>
							<g>
								<path className="stOdis" d="M26,41.7c-1.1,0-2-0.9-2-2V25.5h14.2c1.1,0,2,0.9,2,2s-0.9,2-2,2H28v10.2C28,40.8,27.1,41.7,26,41.7z"/>
							</g>
						</g>
						<g>
							<path className="stOdis" d="M48.3,138.9c-0.4,0-0.7,0-1-0.1c-0.3-0.1-0.5-0.2-0.7-0.4l0.3-0.9c0.2,0.2,0.3,0.3,0.5,0.4
			c0.2,0.1,0.5,0.1,0.8,0.1c0.3,0,0.6-0.1,0.8-0.3s0.3-0.4,0.3-0.8c0-0.4-0.1-0.6-0.2-0.9c-0.2-0.2-0.5-0.5-1-0.9
			c-0.5-0.4-0.9-0.7-1.1-1.1c-0.2-0.4-0.3-0.7-0.3-1.1c0-0.6,0.2-1.1,0.5-1.4c0.4-0.4,0.8-0.6,1.4-0.6c0.7,0,1.2,0.2,1.6,0.6
			l-0.3,0.8c-0.1-0.1-0.3-0.2-0.5-0.3s-0.4-0.1-0.7-0.1c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7c0,0.3,0.1,0.6,0.3,0.8
			s0.5,0.5,1,0.8s0.8,0.7,1.1,1s0.4,0.8,0.4,1.3c0,0.6-0.2,1.1-0.6,1.5S48.9,138.9,48.3,138.9z"/>
							<path className="stOdis" d="M54.3,138.9c-0.9,0-1.5-0.4-1.9-1.1c-0.4-0.7-0.6-1.7-0.6-2.9c0-1.2,0.2-2.2,0.6-2.9c0.4-0.7,1.1-1.1,1.9-1.1
			c0.9,0,1.5,0.4,1.9,1.1c0.4,0.7,0.6,1.7,0.6,2.9c0,1.2-0.2,2.2-0.6,2.9C55.8,138.6,55.2,138.9,54.3,138.9z M54.3,131.9
			c-0.5,0-0.9,0.3-1.1,0.9c-0.2,0.6-0.3,1.3-0.3,2.3c0,0.9,0.1,1.7,0.3,2.2c0.2,0.5,0.6,0.8,1.1,0.8s0.9-0.3,1.1-0.8
			c0.2-0.6,0.3-1.3,0.3-2.2c0-0.9-0.1-1.7-0.3-2.2C55.2,132.1,54.8,131.9,54.3,131.9z"/>
							<path className="stOdis" d="M60.7,139c-1.6,0-2.4-0.8-2.4-2.5v-5.3h1.2v5.4c0,1,0.4,1.4,1.2,1.4c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.3-0.1,0.5-0.2v-6.6h1.2v7.2c-0.2,0.2-0.6,0.3-1,0.5S61.2,139,60.7,139z"/>
							<path className="stOdis" d="M67.5,132.1c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.6,0.1-0.8,0.2v6.7h-1.2v-7.2c0.3-0.2,0.7-0.3,1.1-0.4
			s1-0.1,1.8-0.1L67.5,132.1z"/>
							<path className="stOdis" d="M71.1,138.9c-0.8,0-1.4-0.3-1.8-1c-0.5-0.7-0.7-1.7-0.7-2.9c0-1.2,0.2-2.2,0.7-2.9c0.5-0.7,1.1-1,1.8-1
			c0.4,0,0.7,0,0.9,0.1c0.2,0.1,0.4,0.2,0.6,0.4l-0.3,0.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.6-0.1
			c-0.5,0-0.9,0.3-1.2,0.8c-0.3,0.5-0.4,1.3-0.4,2.2c0,0.9,0.1,1.6,0.4,2.2c0.3,0.5,0.6,0.8,1.1,0.8c0.2,0,0.4,0,0.6-0.1
			c0.2-0.1,0.3-0.2,0.4-0.3l0.3,0.8c-0.1,0.2-0.3,0.3-0.6,0.4C71.8,138.9,71.5,138.9,71.1,138.9z"/>
							<path className="stOdis" d="M74.8,135.4c0,0.8,0.2,1.4,0.5,1.9c0.3,0.4,0.8,0.7,1.4,0.7c0.4,0,0.7,0,0.9-0.1s0.5-0.2,0.7-0.3l0.3,0.9
			c-0.2,0.2-0.5,0.3-0.8,0.4c-0.4,0.1-0.7,0.2-1.2,0.2c-0.9,0-1.6-0.3-2.1-1s-0.8-1.7-0.8-3c0-1.2,0.2-2.2,0.7-2.9
			c0.5-0.7,1.1-1,1.9-1c1.7,0,2.5,1.3,2.5,3.9L74.8,135.4z M76.3,131.9c-0.4,0-0.8,0.2-1.1,0.7c-0.3,0.5-0.4,1.1-0.4,2l2.8-0.4
			C77.6,132.7,77.2,131.9,76.3,131.9z"/>
							<path className="stOdis" d="M85.8,138.8h-1.5l-0.7-3.4c0-0.2-0.1-0.4-0.1-0.5s0-0.3-0.1-0.3l-0.2,0.8l-0.7,3.4H81l-1.4-7.8h1.8l0.6,4.1
			c0,0.2,0.1,0.4,0.1,0.5c0,0.1,0,0.3,0,0.4c0-0.1,0-0.2,0-0.4c0-0.1,0-0.3,0.1-0.6l0.8-4.1l1.2,0l0.8,4.1c0,0.2,0.1,0.4,0.1,0.6
			s0,0.3,0,0.4c0-0.2,0-0.3,0-0.4c0-0.2,0.1-0.4,0.1-0.5l0.6-4.1h1.6L85.8,138.8z"/>
							<path className="stOdis" d="M88,138.8V131h3.5v1.4h-1.6v1.7h1.4v1.4h-1.4v1.9h1.6v1.4H88z"/>
							<path className="stOdis" d="M95.7,138.8l-1.4-3.6l0,3.6h-1.8v-7.5c0.6-0.3,1.3-0.5,2.1-0.5c0.9,0,1.6,0.2,2.1,0.7s0.6,1.1,0.6,1.9
			c0,0.4-0.1,0.8-0.4,1.2c-0.2,0.3-0.5,0.6-0.9,0.7l1.6,3.5H95.7z M94.6,132.1c-0.1,0-0.2,0-0.3,0v2.5l0.2,0c0.3,0,0.6-0.1,0.8-0.4
			c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.2-1S95,132.1,94.6,132.1z"/>
							<path className="stOdis" d="M101.7,138.8l-1.4-4l0,4h-1.8V131h1.8v3.6l1.5-3.6h1.8l-1.6,3.6l1.8,4.2H101.7z"/>
						</g>
					</g>
				</svg>

			)
		case "troubleshooter":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{'.stTroubleshooter{fill:' + activeTroubleshooter + "}"}
					</style>
					<g>
						<path className="stTroubleshooter" d="M131.4,100.1L109,77.2l3.2-3.2c3.1-3.2,4.9-7.5,4.9-12c0-3.3-0.9-6.5-2.7-9.2l8-8.1h0c0.5-0.5,0.8-1.2,0.8-2
		c0-0.7-0.3-1.4-0.8-2l-18.5-18.9c-1.1-1.1-2.8-1.1-3.9,0l-8,8.1c-2.6-1.8-5.7-2.7-9-2.8c-4.4,0-8.5,1.6-11.5,4.7l-3.5,3.6
		c-2.7-2.4-6.1-3.6-9.7-3.6c-3.6,0.1-7,1.4-9.7,3.9l-8.8-9c-0.8-0.8-2.1-0.8-2.9,0l-19,19.4c-0.4,0.4-0.6,0.9-0.6,1.5
		c0,0.6,0.2,1.1,0.6,1.5l8.8,9c-2.4,2.8-3.7,6.3-3.7,10.1c0,4.1,1.6,8,4.4,11l19.3,19.7c1.6,1.7,3.8,2.6,6.1,2.6c2,0,3.9-0.7,5.4-2
		l8.4,8.6l-3.9,4c-0.9,0.9-0.9,2.3,0,3.2L75,127.8c0.8,0.8,1.9,1.3,3,1.3c1.1,0,2.2-0.5,3-1.3l5.7-5.8c0.5-0.5,0.8-1,1-1.6l2.2,2.3
		c0.8,0.8,1.9,1.3,3,1.3c1.1,0,2.2-0.5,3-1.3l5.7-5.8c0.5-0.5,0.8-1,1-1.6l2.2,2.3c0.8,0.8,1.9,1.3,3,1.3c1.1,0,2.2-0.5,3-1.3
		l5.7-5.8c0.5-0.5,0.8-1,1-1.6l1.9,2c0.8,0.8,1.9,1.3,3,1.3c1.1,0,2.2-0.5,3-1.3l5.7-5.8C133.1,104.6,133.1,101.8,131.4,100.1z
		 M101.8,23.6c0.1-0.1,0.2-0.1,0.3,0l18.5,18.9c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.2l-7.7,7.9L94.1,31.5L101.8,23.6z
		 M73.3,33.7c2.5-2.6,5.9-4,9.6-4c0,0,0.1,0,0.1,0c3.8,0,7.3,1.5,9.9,4.2l17.4,17.8c2.7,2.7,4.1,6.3,4.1,10.2
		c0,3.8-1.5,7.5-4.1,10.2l-3.2,3.2l-2.3-2.4c-0.5-0.5-1.2-0.8-1.9-0.8c-0.7,0-1.4,0.3-1.9,0.8l-3.6,3.6l-8.4-8.6c2-2.6,2.5-6,1.3-9
		c1,0.4,1.9,0.6,2.7,0.8c2.2,0.5,4.1,0.7,5.6,0.7c1.9,0,3.1-0.2,3.2-0.3c0.7-0.1,1.1-0.8,1-1.5c-0.1-0.7-0.8-1.1-1.5-1
		c0,0-3.4,0.7-7.8-0.4c-2.4-0.6-4.7-1.6-6.8-2.9l-8.1-8.3c0,0-0.1,0-0.1-0.1C78.5,46,78.4,46,78.3,46c-0.1,0-0.1-0.1-0.2-0.1
		c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c0,0-0.1,0-0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0L67,56.2c-3.6,3.7-9.5,0.6-9.5-4c0-1.5,0.6-2.9,1.6-4L69,38.1L73.3,33.7z M85.6,88
		l-7.8,8l-8-8.1l7.8-8L85.6,88z M87.5,86.1l-8-8.1l7.9-8l8,8.1L87.5,86.1z M38.4,28.9l8.4,8.6L28.4,56.3L20,47.7L38.4,28.9z
		 M48.5,97.1L29.3,77.4c-2.4-2.4-3.7-5.7-3.7-9.2c0-3.5,1.3-6.7,3.7-9.2l13.6-13.9l6.7-6.8c4.7-4.8,11.9-5.2,16.8-1.1l-8.9,9.1
		C55.9,47.9,55,50,55,52.2c0,2.2,0.8,4.3,2.4,5.8c0.2,0.2,0.3,0.3,0.5,0.4l-2.9,3l-5-5.1l-3.4,3.5l5,5.1l-5,5.1l3.4,3.5l5-5.1l5,5.1
		l3.4-3.5l-5-5.1l4.4-4.5c0.1,0,0.2,0,0.3,0c2.2,0,4.2-0.9,5.7-2.4l8.8-9c2.4,3.3,5,5.7,7.6,7.4l1.6,1.6c2.4,2.5,2.4,6.5,0,8.9
		l-9,9.2L68.1,86l0,0L57.3,97.1c-1.2,1.2-2.7,1.9-4.4,1.9C51.3,99,49.7,98.3,48.5,97.1z M68,89.7l8,8.1l-7.9,8l-8-8.1L68,89.7z
		 M85,120.1l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5l-12-12.3l8.2-8.4l12,12.3C85.7,118.3,85.7,119.4,85,120.1z
		 M100,115l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5l-5-5.1c0,0,0,0,0,0l-12-12.3l8.2-8.4l17,17.4
		C100.7,113.1,100.7,114.3,100,115z M115,109.9l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5L92.4,101l-7.6-7.8
		l8.2-8.4l22,22.5C115.7,108,115.7,109.2,115,109.9z M129.6,104.5l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5
		l-4.7-4.8L94.8,83l8.1-8.2c0.1-0.1,0.2-0.1,0.3,0l3.2,3.3c0,0,0,0,0,0s0,0,0,0l23.3,23.8C130.3,102.6,130.3,103.8,129.6,104.5z"/>
					</g>
				</svg>
			)
		case "control-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stControl{fill:' + activeControl + '}'}
					</style>
					<g>
						<g>
							<path className="stControl" d="M61,57.6c0-6.6,3.6-10.7,8.8-10.7c2.4,0,4.3,1.2,5.4,2.5l-0.8,1c-1.1-1.3-2.6-2.1-4.5-2.1
			c-4.5,0-7.3,3.6-7.3,9.3c0,5.7,2.8,9.5,7.2,9.5c2.1,0,3.7-0.9,5.2-2.5l0.8,0.9c-1.6,1.8-3.5,3-6.1,3C64.6,68.3,61,64.2,61,57.6z"
							/>
							<path className="stControl" d="M80.2,60.5c0-5,3.1-7.9,6.8-7.9c3.6,0,6.8,2.9,6.8,7.9c0,5-3.1,7.9-6.8,7.9C83.4,68.3,80.2,65.4,80.2,60.5z
			 M92.3,60.5c0-4-2.3-6.7-5.3-6.7c-3,0-5.3,2.7-5.3,6.7c0,4,2.3,6.7,5.3,6.7C90,67.1,92.3,64.4,92.3,60.5z"/>
							<path className="stControl" d="M99.8,52.9h1.2l0.2,2.3h0.1c1.5-1.5,3.1-2.7,5.2-2.7c3.1,0,4.6,1.9,4.6,5.8V68h-1.4v-9.5c0-3.2-1-4.7-3.4-4.7
			c-1.8,0-3.1,0.9-4.9,2.8V68h-1.4V52.9z"/>
							<path className="stControl" d="M118.4,63.8v-9.7h-2.4V53l2.4-0.1l0.2-4.4h1.2v4.4h4.4v1.2h-4.4v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C119.3,68.3,118.4,66.5,118.4,63.8z"/>
							<path className="stControl" d="M129.4,52.9h1.2l0.2,2.8h0.1c1-1.9,2.5-3.2,4.3-3.2c0.5,0,1,0.1,1.5,0.3l-0.3,1.3c-0.5-0.2-0.8-0.3-1.4-0.3
			c-1.4,0-3,1-4.1,3.9V68h-1.4V52.9z"/>
							<path className="stControl" d="M139.4,60.5c0-5,3.1-7.9,6.8-7.9s6.8,2.9,6.8,7.9c0,5-3.1,7.9-6.8,7.9S139.4,65.4,139.4,60.5z M151.5,60.5
			c0-4-2.3-6.7-5.3-6.7s-5.3,2.7-5.3,6.7c0,4,2.3,6.7,5.3,6.7S151.5,64.4,151.5,60.5z"/>
							<path className="stControl" d="M159,65.9V45.4h1.4v20.7c0,0.7,0.3,1,0.7,1c0.1,0,0.3,0,0.6-0.1l0.2,1.1c-0.3,0.1-0.5,0.2-1,0.2
			C159.6,68.3,159,67.6,159,65.9z"/>
						</g>
						<g>
							<g>
								<path className="stControl" d="M159,167.9H92.8c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h59.8c0.8,0,1.5,0.7,1.5,1.5v2
				c0,0.8-0.7,1.5-1.5,1.5h-50.2c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h53.2c0.8,0,1.5-0.7,1.5-1.5v-5
				c0-0.8,0.7-1.5,1.5-1.5h3.4c0.8,0,1.5-0.7,1.5-1.5v-50c0-0.8-0.7-1.5-1.5-1.5h0c-0.8,0-1.5,0.7-1.5,1.5v46.9
				C160.5,167.3,159.9,167.9,159,167.9z"/>
							</g>
							<g>
								<path className="stControl" d="M60.8,98.7v70.8c0,0.8,0.7,1.5,1.5,1.5h3.4c0.8,0,1.5,0.7,1.5,1.5v5c0,0.8,0.7,1.5,1.5,1.5h25.8
				c0.8,0,1.5-0.7,1.5-1.5l0,0c0-0.8-0.7-1.5-1.5-1.5H71.8c-0.8,0-1.5-0.7-1.5-1.5v-5c0-0.8-0.7-1.5-1.5-1.5h-3.4
				c-0.8,0-1.5-0.7-1.5-1.5v-64.7c0-0.8,0.7-1.5,1.5-1.5H159c0.8,0,1.5,0.7,1.5,1.5v9.9c0,0.8,0.7,1.5,1.5,1.5h0
				c0.8,0,1.5-0.7,1.5-1.5v-13c0-0.8-0.7-1.5-1.5-1.5H62.3C61.5,97.1,60.8,97.8,60.8,98.7z"/>
							</g>
							<g>
								<path className="stControl" d="M155.6,159.8L155.6,159.8c0-0.8-0.7-1.5-1.5-1.5h-5c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h5
				C155,161.3,155.6,160.6,155.6,159.8z"/>
							</g>
							<g>
								<path className="stControl" d="M154.1,151.8h-16.3c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h16.3c0.8,0,1.5-0.7,1.5-1.5v0
				C155.6,152.5,155,151.8,154.1,151.8z"/>
							</g>
							<g>
								<path className="stControl" d="M154.1,145.4h-16.3c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h16.3c0.8,0,1.5-0.7,1.5-1.5v0
				C155.6,146.1,155,145.4,154.1,145.4z"/>
							</g>
							<g>
								<path className="stControl" d="M154.1,139h-16.3c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h16.3c0.8,0,1.5-0.7,1.5-1.5v0
				C155.6,139.7,155,139,154.1,139z"/>
							</g>
							<g>
								<path className="stControl" d="M154.1,132.5h-16.3c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h16.3c0.8,0,1.5-0.7,1.5-1.5l0,0
				C155.6,133.2,155,132.5,154.1,132.5z"/>
							</g>
							<g>
								<path className="stControl" d="M151.7,105.2h-79c-2.1,0-3.8,1.7-3.8,3.8v13.1c0,2.1,1.7,3.8,3.8,3.8h79c2.1,0,3.8-1.7,3.8-3.8V109
				C155.5,106.9,153.8,105.2,151.7,105.2z"/>
							</g>
							<g>
								<path className="stControl" d="M144.4,159.8L144.4,159.8c0-0.8-0.7-1.5-1.5-1.5h-0.2c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h0.2
				C143.7,161.3,144.4,160.6,144.4,159.8z"/>
							</g>
							<g>
								<path className="stControl" d="M126.6,159.9v-4.1c0-0.6,0.4-1.2,0.9-1.4c2.3-1,3.9-3.2,3.9-5.8c0-2.6-1.6-4.9-3.9-5.8
				c-0.6-0.2-0.9-0.8-0.9-1.4v-8.9c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v8.9c0,0.6-0.4,1.2-0.9,1.4
				c-2.3,1-3.9,3.2-3.9,5.8s1.6,4.9,3.9,5.8c0.6,0.2,0.9,0.8,0.9,1.4v4.1c0,0.8,0.7,1.5,1.5,1.5l0,0
				C125.9,161.4,126.6,160.7,126.6,159.9z"/>
							</g>
							<g>
								<path className="stControl" d="M110.5,159.9v-10.5c0-0.6,0.4-1.2,0.9-1.4c2.3-1,3.9-3.2,3.9-5.8c0-2.6-1.6-4.9-3.9-5.8
				c-0.6-0.2-0.9-0.8-0.9-1.4v-2.5c0-0.8-0.7-1.5-1.5-1.5h0c-0.8,0-1.5,0.7-1.5,1.5v2.5c0,0.6-0.4,1.2-0.9,1.4
				c-2.3,1-3.9,3.2-3.9,5.8c0,2.6,1.6,4.9,3.9,5.8c0.6,0.2,0.9,0.8,0.9,1.4v10.5c0,0.8,0.7,1.5,1.5,1.5h0
				C109.8,161.4,110.5,160.7,110.5,159.9z"/>
							</g>
							<g>
								<path className="stControl" d="M91.4,157.4v2.5c0,0.8,0.7,1.5,1.5,1.5h0c0.8,0,1.5-0.7,1.5-1.5v-2.5c0-0.6,0.4-1.2,0.9-1.4
				c2.3-1,3.9-3.2,3.9-5.8c0-2.6-1.6-4.9-3.9-5.8c-0.6-0.2-0.9-0.8-0.9-1.4v-10.5c0-0.8-0.7-1.5-1.5-1.5h0c-0.8,0-1.5,0.7-1.5,1.5
				v10.5c0,0.6-0.4,1.2-0.9,1.4c-2.3,1-3.9,3.2-3.9,5.8c0,2.6,1.6,4.9,3.9,5.8C91,156.2,91.4,156.8,91.4,157.4z"/>
							</g>
							<g>
								<path className="stControl" d="M86.5,167.9h-0.2c-0.8,0-1.5,0.7-1.5,1.5l0,0c0,0.8,0.7,1.5,1.5,1.5h0.2c0.8,0,1.5-0.7,1.5-1.5l0,0
				C88.1,168.6,87.4,167.9,86.5,167.9z"/>
							</g>
							<g>
								<path className="stControl" d="M78.3,134.9v-2.5c0-0.8-0.7-1.5-1.5-1.5l0,0c-0.8,0-1.5,0.7-1.5,1.5v2.5c0,0.6-0.4,1.2-0.9,1.4
				c-2.3,1-3.9,3.2-3.9,5.8c0,2.6,1.6,4.9,3.9,5.8c0.6,0.2,0.9,0.8,0.9,1.4v10.5c0,0.8,0.7,1.5,1.5,1.5l0,0c0.8,0,1.5-0.7,1.5-1.5
				v-10.5c0-0.6,0.4-1.2,0.9-1.4c2.3-1,3.9-3.2,3.9-5.8c0-2.6-1.6-4.9-3.9-5.8C78.7,136,78.3,135.5,78.3,134.9z"/>
							</g>
							<g>
								<path className="stControl" d="M79.9,171h0.2c0.8,0,1.5-0.7,1.5-1.5l0,0c0-0.8-0.7-1.5-1.5-1.5h-0.2c-0.8,0-1.5,0.7-1.5,1.5l0,0
				C78.4,170.3,79.1,171,79.9,171z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "assistant-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stAssistant{fill:' + activeAssistant + "}"}
					</style>
					<g>
						<g>
							<path className="stAssistant" d="M57,42.7h1.4l7.3,20.7h-1.5l-4.1-12.2c-0.8-2.5-1.6-4.6-2.3-7.1h-0.1c-0.7,2.5-1.4,4.7-2.3,7.1l-4.2,12.2
			h-1.5L57,42.7z M53,55h9.3v1.2H53V55z"/>
							<path className="stAssistant" d="M69.4,61.6l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3c-2.3-0.8-4.8-1.7-4.8-4.3
			c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.4,0-3.4,1.4-3.4,2.7c0,1.7,1.8,2.4,3.6,3
			c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C72.7,63.7,70.8,62.8,69.4,61.6z"/>
							<path className="stAssistant" d="M83.7,61.6l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3c-2.3-0.8-4.8-1.7-4.8-4.3
			c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.3,0-3.4,1.4-3.4,2.7c0,1.7,1.8,2.4,3.6,3
			c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C87,63.7,85.1,62.8,83.7,61.6z"/>
							<path className="stAssistant" d="M99.3,43.4c0-0.8,0.6-1.2,1.3-1.2c0.7,0,1.3,0.5,1.3,1.2c0,0.7-0.6,1.2-1.3,1.2
			C99.9,44.6,99.3,44.1,99.3,43.4z M99.9,48.3h1.4v15.1h-1.4V48.3z"/>
							<path className="stAssistant" d="M106.7,61.6l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3
			c-2.3-0.8-4.8-1.7-4.8-4.3c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.3,0-3.4,1.4-3.4,2.7
			c0,1.7,1.8,2.4,3.6,3c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C110,63.7,108.1,62.8,106.7,61.6z"/>
							<path className="stAssistant" d="M122.6,59.2v-9.7h-2.4v-1.1l2.4-0.1l0.2-4.4h1.2v4.4h4.4v1.2H124v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C123.5,63.7,122.6,61.9,122.6,59.2z"/>
							<path className="stAssistant" d="M131.8,59.6c0-3.3,3-4.9,9.7-5.7c0-2.3-0.6-4.8-3.6-4.8c-2,0-3.8,1-4.8,1.8l-0.6-1c1.1-0.8,3.2-2,5.6-2
			c3.6,0,4.8,2.6,4.8,5.8v9.6h-1.2l-0.2-2h-0.1c-1.5,1.2-3.4,2.3-5.3,2.3C133.8,63.7,131.8,62.4,131.8,59.6z M141.6,60.1V55
			c-6.2,0.7-8.3,2.2-8.3,4.6c0,2.2,1.5,3,3.2,3C138.2,62.5,139.7,61.7,141.6,60.1z"/>
							<path className="stAssistant" d="M149.9,48.3h1.2l0.2,2.3h0.1c1.5-1.5,3.1-2.7,5.2-2.7c3.1,0,4.6,1.9,4.6,5.8v9.7h-1.4v-9.5
			c0-3.2-1-4.7-3.4-4.7c-1.8,0-3.1,0.9-4.9,2.8v11.4h-1.4V48.3z"/>
							<path className="stAssistant" d="M168.5,59.2v-9.7h-2.4v-1.1l2.4-0.1l0.2-4.4h1.2v4.4h4.4v1.2h-4.4v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C169.3,63.7,168.5,61.9,168.5,59.2z"/>
						</g>
						<g>
							<g>
								<path className="stAssistant" d="M74,127.7l3.7,7.3c-1.7,0.9-2.8,2.8-2.6,4.8c0.2,2,1.5,3.6,3.4,4.2l1.3,10.5c-2.4,0.9-3.7,3.5-3,6
				c0.7,2.5,3.2,4,5.7,3.5l5.8,5.8c0.3,1.7,1.4,3.2,3,3.8c1.6,0.7,3.4,0.4,4.8-0.6l8.8,3.8c0.5,2.3,2.6,3.9,5,3.8
				c2.3-0.1,4.3-1.8,4.7-4.1c2.6,4.5,7.9,6.7,13,5.4c5-1.4,8.5-5.9,8.5-11.1c0-0.1,0-0.2,0-0.2c0.4-0.1,0.8-0.2,1.1-0.3l0.1,0
				c0.3-0.1,0.7-0.2,1-0.3l0.3-0.1c0.3-0.1,0.6-0.2,0.9-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.3-0.1,0.5-0.3,0.7-0.4
				c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.1,0.3-0.2,0.5-0.4c0.3-0.2,0.6-0.5,0.8-0.7
				c0.1-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.5-0.6,0.8-0.9c0.1-0.1,0.2-0.2,0.2-0.3c0.2-0.2,0.4-0.5,0.5-0.7c0.1-0.1,0.2-0.3,0.2-0.4
				c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.2-0.5,0.3-0.7
				c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.1-0.5,0.2-0.8c0-0.2,0.1-0.3,0.1-0.5c0.1-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.3,0.1-0.4
				c0-0.4,0.1-0.9,0.1-1.4c0-1.4-0.2-2.8-0.6-4.2c0.6-0.6,1.2-1.3,1.7-2c1.4-1.8,2.2-3.9,2.2-6.2h0c0-0.3,0-0.5,0-0.8
				c0-1.4-0.2-2.8-0.7-4.1c3.9-2.3,6.1-6.7,5.6-11.3c0-0.1,0-0.3-0.1-0.4c0-0.3-0.1-0.6-0.2-0.9c0-0.1-0.1-0.3-0.1-0.4
				c-0.1-0.3-0.2-0.7-0.3-1c0-0.1-0.1-0.2-0.1-0.3c-0.2-0.4-0.4-0.8-0.6-1.2c0,0,0-0.1,0-0.1c1-2.1,1.5-4.4,1.5-6.7
				c0-0.6,0-1.2-0.1-1.8c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.1-0.8-0.2-1.1c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.2-0.6-0.3-0.9
				c-0.1-0.3-0.2-0.5-0.3-0.8c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.5-0.5-0.9-0.7-1.3c-0.1-0.2-0.3-0.4-0.4-0.6
				c-0.2-0.3-0.4-0.5-0.6-0.8c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.4-0.8-0.7-1.2-1.1l-0.1-0.1
				c-0.5-0.4-1-0.7-1.5-1.1c0,0-0.1,0-0.1-0.1c1.3-3.5,0.8-7.5-1.3-10.6c-2.2-3.1-5.7-5-9.5-5c-1.1,0-2.3,0.2-3.4,0.5
				c-2.1-3.4-5.8-5.4-9.7-5.5s-7.7,2-9.8,5.3l0.1-0.4l-10.4-1.5c-0.7-2.2-2.8-3.6-5-3.5c-2.3,0.2-4.2,1.9-4.5,4.1L83.2,97.7
				c-0.9-0.8-2-1.2-3.2-1.2c-2.3,0-4.3,1.6-4.8,3.8c-0.5,2.3,0.6,4.6,2.7,5.5l-4.1,12.2c-2.2,0.6-3.7,2.6-3.7,4.9
				C70.2,125.2,71.8,127.2,74,127.7L74,127.7z M77.2,118.4l4.1-12.2c0.2,0,0.4-0.1,0.6-0.2l8.6,11.1c-0.9,0.6-1.6,1.6-2,2.6
				l-8.9,1.1C79.1,119.8,78.3,118.9,77.2,118.4z M98.2,92.9v3.9c-1.8,0.6-3.1,2.3-3.3,4.3c-0.2,1.9,0.9,3.8,2.6,4.7l-4.5,9.1
				l-8.6-11c0.6-1,0.8-2.1,0.6-3.2l10.9-9.5C96.4,91.9,97.2,92.5,98.2,92.9z M103.8,104.4l3.2,3.2c-0.3,0.7-0.5,1.4-0.5,2.1
				c0,2.7,2.2,4.9,4.9,4.9c0.6,0,1.1-0.1,1.6-0.3v10.5c-1.5-0.6-3.2-0.3-4.5,0.6l-10.5-5.3c-0.3-1.4-1.2-2.6-2.5-3.2l5.3-10.6
				C102.1,106,103.1,105.3,103.8,104.4z M111.4,108c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
				C109.7,108.8,110.5,108,111.4,108z M107.8,152.5c-0.6-1.7-2-2.9-3.8-3.2l-4.3-5.7c0,0,0-0.1,0-0.1l12.3-9.2c0.3,0,0.7-0.1,1-0.2
				v18.4H107.8z M105.4,173.4l-7.3-3.1c0.1-0.4,0.2-0.8,0.2-1.2c0-0.3,0-0.7-0.1-1l4.8-7.1l4.6,10.4
				C106.6,171.7,105.8,172.5,105.4,173.4z M89.3,166.1l-3.8-3.8c0.7-0.9,1.1-2,1.1-3.1c0-0.1,0-0.2,0-0.3l12-2.7
				c0.4,0.9,1.1,1.7,2,2.2l-4.4,6.6C94,163.4,90.9,163.9,89.3,166.1L89.3,166.1z M83.2,154.4l-1.3-10.5c1.1-0.5,2-1.3,2.6-2.4
				l5.7,2.3c0.4,1.6,1.6,2.9,3.1,3.5c1.6,0.6,3.3,0.3,4.6-0.7l2.6,3.5c-1,0.7-1.7,1.6-2,2.8l-13.1,2.9
				C84.7,155.1,84,154.7,83.2,154.4z M93.2,119.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
				C91.6,120.3,92.3,119.6,93.2,119.6z M97.6,123.6l9.1,4.5c-0.6,1.8,0,3.8,1.5,5L99,140c-0.6-0.9-1.6-1.7-2.6-2L95,125.8
				C96.1,125.4,97,124.6,97.6,123.6z M94.9,141c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
				C93.2,141.7,94,141,94.9,141z M103.1,155.8c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
				C104.8,155.1,104,155.8,103.1,155.8z M111.4,131.1c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6
				C113,130.4,112.3,131.1,111.4,131.1z M99.8,99.8c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
				C98.2,100.5,98.9,99.8,99.8,99.8z M79.9,124.1l8.8-1.1c0.6,1.4,1.7,2.4,3.1,2.9l1.3,12.2c-1.1,0.4-2.1,1.3-2.6,2.4l-5.7-2.3
				c-0.4-1.8-1.9-3.2-3.7-3.6l-3.7-7.3C78.6,126.5,79.5,125.4,79.9,124.1z M80.1,137.7c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
				s-1.6-0.7-1.6-1.6C78.4,138.4,79.1,137.7,80.1,137.7z M80.1,159.1c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
				c0,0.9-0.7,1.6-1.6,1.6C80.8,160.8,80.1,160,80.1,159.1z M91.6,169c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
				c0,0.9-0.7,1.6-1.6,1.6C92.3,170.7,91.6,169.9,91.6,169z M109.7,177.3c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
				c0.9,0,1.6,0.7,1.6,1.6C111.4,176.5,110.6,177.3,109.7,177.3z M110.9,170.8l-5.5-12.3c1.1-0.6,1.9-1.5,2.4-2.7h5.2v14.8
				c0,0.5,0,0.9,0.1,1.4C112.5,171.4,111.7,171,110.9,170.8z M116.3,94.8c0-3.9,2.7-7.2,6.5-8c3.8-0.8,7.7,1.2,9.2,4.7
				c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.9,0.1,1.3,0c1.1-0.5,2.3-0.8,3.5-0.8c2.7,0,5.1,1.3,6.7,3.4c1.5,2.2,2,4.9,1.1,7.4
				c-4.8-1.8-10.2-1.1-14.3,1.9c-4.2,2.9-6.7,7.7-6.7,12.8h3.3c0-4.3,2.2-8.3,5.8-10.5c3.6-2.3,8.2-2.5,12-0.5
				c1,0.5,1.9,1.1,2.7,1.8c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,0.8,0.9c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.3,0.5,0.6,0.7,0.9
				c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.3,0.3,0.5,0.4,0.8c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.4,0.3,0.9,0.5,1.3c0,0.1,0.1,0.3,0.1,0.5
				c0.1,0.4,0.2,0.7,0.2,1.1c0,0.2,0.1,0.3,0.1,0.5c0.2,1.8,0.1,3.6-0.5,5.3c-2.1-1.9-4.8-2.9-7.6-2.9v3.3c2.7,0,5.3,1.4,6.8,3.6
				c0,0.1,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.7,0.6,1.1c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.3,0.2,0.7,0.3,1c0,0.1,0.1,0.3,0.1,0.4
				c0.7,3.8-1.3,7.7-4.9,9.1c-0.4,0.2-0.8,0.5-0.9,1c-0.2,0.4-0.1,0.9,0.1,1.3c1.5,2.9,1.3,6.3-0.4,9c-1.5,2-4.1,2.9-6.5,2.4
				c-2.5-0.5-4.4-2.4-5-4.9c5.5-0.5,9.7-5.2,9.7-10.7h-3.3c0,4.1-3.3,7.4-7.4,7.4c-4.1,0-7.4-3.3-7.4-7.4h-3.3c0,5,3.5,9.3,8.3,10.4
				c0.4,2.9,2,5.4,4.5,7c2.4,1.6,5.4,2,8.2,1.1c0.3,0.9,0.4,1.9,0.4,2.9c0,0.4,0,0.7-0.1,1.1c0,0.1,0,0.2-0.1,0.4
				c0,0.2-0.1,0.5-0.1,0.7c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.6
				c-0.1,0.1-0.1,0.3-0.2,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.5
				c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.3
				c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.1-0.3,0.2
				c-0.2,0.1-0.4,0.3-0.6,0.4l-0.2,0.1c-0.2,0.1-0.5,0.3-0.7,0.4l-0.1,0.1c-0.3,0.1-0.6,0.2-0.8,0.3l-0.1,0c-1,0.3-2,0.5-3,0.6h0
				c-0.2,0-0.4,0-0.6,0c-5.9,0-10.7-4.8-10.7-10.7h-3.3c0,7.4,5.8,13.5,13.2,14v0c0,4.6-3.7,8.2-8.2,8.2c-4.6,0-8.2-3.7-8.2-8.2
				V94.8z M113,94.8V105c-1.2-0.5-2.6-0.4-3.8,0.2l-4.6-4.6c-0.3-1.8-1.5-3.3-3.2-3.9v-3.9c1.3-0.5,2.4-1.5,2.9-2.8l9.1,1.3
				C113.2,92.5,113,93.7,113,94.8z M99.8,86.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
				C98.2,87.3,98.9,86.6,99.8,86.6z M80.1,99.8c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
				C78.4,100.5,79.1,99.8,80.1,99.8z M75.1,121.2c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6s-1.6-0.7-1.6-1.6
				C73.5,122,74.2,121.2,75.1,121.2z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "troubleshooter-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stTroubleshooter{fill:' + activeTroubleshooter + "}"}
					</style>
					<g>
						<g>
							<path className="stTroubleshooter" d="M11.7,42.5H5.2v-1.2h14.6v1.2h-6.6v19.4h-1.4V42.5z"/>
							<path className="stTroubleshooter" d="M23.6,46.8h1.2l0.2,2.8H25c1-1.9,2.5-3.2,4.3-3.2c0.5,0,1,0.1,1.5,0.3L30.5,48c-0.5-0.2-0.8-0.3-1.4-0.3
			c-1.4,0-3,1-4.1,3.9v10.2h-1.4V46.8z"/>
							<path className="stTroubleshooter" d="M33.6,54.4c0-5,3.1-7.9,6.8-7.9c3.6,0,6.8,2.9,6.8,7.9c0,5-3.1,7.9-6.8,7.9C36.7,62.3,33.6,59.4,33.6,54.4z
			 M45.6,54.4c0-4-2.3-6.7-5.3-6.7c-3,0-5.3,2.7-5.3,6.7c0,4,2.3,6.7,5.3,6.7C43.4,61,45.6,58.4,45.6,54.4z"/>
							<path className="stTroubleshooter" d="M52.9,56.5v-9.7h1.4v9.5c0,3.2,1,4.7,3.4,4.7c1.8,0,3.1-0.9,4.9-3.1V46.8H64v15.1h-1.2l-0.2-2.5h-0.1
			c-1.5,1.7-3,2.9-5.1,2.9C54.4,62.3,52.9,60.4,52.9,56.5z"/>
							<path className="stTroubleshooter" d="M72.7,60.4L72.7,60.4l-0.2,1.5h-1.2V39.3h1.4v6.6l-0.1,2.9c1.5-1.3,3.4-2.4,5.2-2.4c4,0,6,3,6,7.7
			c0,5.1-3.1,8.1-6.6,8.1C75.8,62.3,74.1,61.5,72.7,60.4z M82.4,54.1c0-3.7-1.3-6.5-4.8-6.5c-1.5,0-3.1,0.9-4.9,2.5v8.9
			c1.6,1.4,3.3,1.9,4.4,1.9C80.2,61,82.4,58.2,82.4,54.1z"/>
							<path className="stTroubleshooter" d="M89.9,59.9V39.3h1.4v20.7c0,0.7,0.3,1,0.7,1c0.1,0,0.3,0,0.6-0.1l0.2,1.1c-0.3,0.1-0.5,0.2-1,0.2
			C90.5,62.3,89.9,61.5,89.9,59.9z"/>
							<path className="stTroubleshooter" d="M97.6,54.4c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1H99.1c0.1,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.7,1.5C100.7,62.3,97.6,59.3,97.6,54.4z M108.7,53.4c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H108.7z"/>
							<path className="stTroubleshooter" d="M113.7,60.2l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3
			c-2.3-0.8-4.8-1.7-4.8-4.3c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.3,0-3.4,1.4-3.4,2.7
			c0,1.7,1.8,2.4,3.6,3c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C117,62.3,115.1,61.3,113.7,60.2z"/>
							<path className="stTroubleshooter" d="M129.9,39.3h1.4v6.6l0,3.3c1.6-1.6,3.1-2.7,5.2-2.7c3.1,0,4.6,1.9,4.6,5.8v9.7h-1.4v-9.5c0-3.2-1-4.7-3.4-4.7
			c-1.8,0-3.1,0.9-4.9,2.8v11.4h-1.4V39.3z"/>
							<path className="stTroubleshooter" d="M146.8,54.4c0-5,3.1-7.9,6.8-7.9c3.6,0,6.8,2.9,6.8,7.9c0,5-3.1,7.9-6.8,7.9
			C149.9,62.3,146.8,59.4,146.8,54.4z M158.8,54.4c0-4-2.3-6.7-5.3-6.7c-3,0-5.3,2.7-5.3,6.7c0,4,2.3,6.7,5.3,6.7
			C156.6,61,158.8,58.4,158.8,54.4z"/>
							<path className="stTroubleshooter" d="M165.1,54.4c0-5,3.1-7.9,6.8-7.9c3.6,0,6.8,2.9,6.8,7.9c0,5-3.1,7.9-6.8,7.9
			C168.3,62.3,165.1,59.4,165.1,54.4z M177.2,54.4c0-4-2.3-6.7-5.3-6.7c-3,0-5.3,2.7-5.3,6.7c0,4,2.3,6.7,5.3,6.7
			C174.9,61,177.2,58.4,177.2,54.4z"/>
							<path className="stTroubleshooter" d="M184.7,57.7V48h-2.4V47l2.4-0.1l0.2-4.4h1.2v4.4h4.4V48h-4.4v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C185.5,62.3,184.7,60.4,184.7,57.7z"/>
							<path className="stTroubleshooter" d="M194.1,54.4c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1h-10.8c0,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.8,1.5C197.2,62.3,194.1,59.3,194.1,54.4z M205.1,53.4c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H205.1z"/>
							<path className="stTroubleshooter" d="M212.1,46.8h1.2l0.2,2.8h0.1c1-1.9,2.5-3.2,4.3-3.2c0.5,0,1,0.1,1.5,0.3l-0.3,1.3c-0.5-0.2-0.8-0.3-1.4-0.3
			c-1.4,0-3,1-4.1,3.9v10.2h-1.4V46.8z"/>
						</g>
						<g>
							<path className="stTroubleshooter" d="M168.8,156.1l-22.4-22.9l3.2-3.2c3.1-3.2,4.9-7.5,4.9-12c0-3.3-0.9-6.5-2.7-9.2l8-8.1h0
			c0.5-0.5,0.8-1.2,0.8-2c0-0.7-0.3-1.4-0.8-2l-18.5-18.9c-1.1-1.1-2.8-1.1-3.9,0l-8,8.1c-2.6-1.8-5.7-2.7-9-2.8
			c-4.4,0-8.5,1.6-11.5,4.7l-3.5,3.6c-2.7-2.4-6.1-3.6-9.7-3.6c-3.6,0.1-7,1.4-9.7,3.9l-8.8-9c-0.8-0.8-2.1-0.8-2.9,0l-19,19.4
			c-0.4,0.4-0.6,0.9-0.6,1.5c0,0.6,0.2,1.1,0.6,1.5l8.8,9c-2.4,2.8-3.7,6.3-3.7,10.1c0,4.1,1.6,8,4.4,11l19.3,19.7
			c1.6,1.7,3.8,2.6,6.1,2.6c2,0,3.9-0.7,5.4-2l8.4,8.6l-3.9,4c-0.9,0.9-0.9,2.3,0,3.2l12.2,12.5c0.8,0.8,1.9,1.3,3,1.3
			c1.1,0,2.2-0.5,3-1.3l5.7-5.8c0.5-0.5,0.8-1,1-1.6l2.2,2.3c0.8,0.8,1.9,1.3,3,1.3s2.2-0.5,3-1.3l5.7-5.8c0.5-0.5,0.8-1,1-1.6
			l2.2,2.3c0.8,0.8,1.9,1.3,3,1.3c1.1,0,2.2-0.5,3-1.3l5.7-5.8c0.5-0.5,0.8-1,1-1.6l1.9,2c0.8,0.8,1.9,1.3,3,1.3
			c1.1,0,2.2-0.5,3-1.3l5.7-5.8C170.5,160.6,170.5,157.8,168.8,156.1z M139.2,79.6c0.1-0.1,0.2-0.1,0.3,0L158,98.5
			c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.2l-7.7,7.9l-18.8-19.2L139.2,79.6z M110.7,89.7c2.5-2.6,5.9-4,9.6-4c0,0,0.1,0,0.1,0
			c3.8,0,7.3,1.5,9.9,4.2l17.4,17.8c2.7,2.7,4.1,6.3,4.1,10.2c0,3.8-1.5,7.5-4.1,10.2l-3.2,3.2l-2.3-2.4c-0.5-0.5-1.2-0.8-1.9-0.8
			c-0.7,0-1.4,0.3-1.9,0.8l-3.6,3.6l-8.4-8.6c2-2.6,2.5-6,1.3-9c1,0.4,1.9,0.6,2.7,0.8c2.2,0.5,4.1,0.7,5.6,0.7
			c1.9,0,3.1-0.2,3.2-0.3c0.7-0.1,1.1-0.8,1-1.5c-0.1-0.7-0.8-1.1-1.5-1c0,0-3.4,0.7-7.8-0.4c-2.4-0.6-4.7-1.6-6.8-2.9l-8.1-8.3
			c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0
			c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1l0,0c0,0,0,0,0,0c0,0,0,0,0,0l-9.8,10.1
			c-3.6,3.7-9.5,0.6-9.5-4c0-1.5,0.6-2.9,1.6-4l9.8-10.1L110.7,89.7z M123,144l-7.8,8l-8-8.1l7.8-8L123,144z M124.9,142.1l-8-8.1
			l7.9-8l8,8.1L124.9,142.1z M75.8,84.9l8.4,8.6l-18.4,18.8l-8.4-8.6L75.8,84.9z M85.9,153.1l-19.3-19.7c-2.4-2.4-3.7-5.7-3.7-9.2
			c0-3.5,1.3-6.7,3.7-9.2l13.6-13.9l6.7-6.8c4.7-4.8,11.9-5.2,16.8-1.1l-8.9,9.1c-1.5,1.6-2.4,3.6-2.4,5.8s0.8,4.3,2.4,5.8
			c0.2,0.2,0.3,0.3,0.5,0.4l-2.9,3l-5-5.1l-3.4,3.5l5,5.1l-5,5.1l3.4,3.5l5-5.1l5,5.1l3.4-3.5l-5-5.1l4.4-4.5c0.1,0,0.2,0,0.3,0
			c2.2,0,4.2-0.9,5.7-2.4l8.8-9c2.4,3.3,5,5.7,7.6,7.4l1.6,1.6c2.4,2.5,2.4,6.5,0,8.9l-9,9.2l-9.6,9.9l0,0l-10.8,11.1
			c-1.2,1.2-2.7,1.9-4.4,1.9C88.7,155,87.1,154.3,85.9,153.1z M105.4,145.8l8,8.1l-7.9,8l-8-8.1L105.4,145.8z M122.4,176.2l-5.7,5.8
			c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5l-12-12.3l8.2-8.4l12,12.3C123.1,174.3,123.1,175.5,122.4,176.2z M137.4,171.1
			l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5s-0.9-0.2-1.3-0.5l-5-5.1c0,0,0,0,0,0l-12-12.3l8.2-8.4l17,17.4
			C138.1,169.2,138.1,170.3,137.4,171.1z M152.4,166l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5l-14.4-14.7l-7.6-7.8
			l8.2-8.4l22,22.5C153.1,164.1,153.1,165.2,152.4,166z M167,160.5l-5.7,5.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5
			l-4.7-4.8l-22-22.5l8.1-8.2c0.1-0.1,0.2-0.1,0.3,0l3.2,3.3c0,0,0,0,0,0s0,0,0,0l23.3,23.8C167.7,158.6,167.7,159.8,167,160.5z"/>
						</g>
					</g>
				</svg>
			)
		case "analytics-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stAnalytics{fill:' + activeAnalytics + "}"}
					</style>
					<g>
						<g>
							<path className="stAnalytics" d="M57.2,43.8h1.4l7.3,20.7h-1.5l-4.1-12.2c-0.8-2.5-1.6-4.6-2.3-7.1h-0.1c-0.7,2.5-1.4,4.7-2.3,7.1l-4.2,12.2
			h-1.5L57.2,43.8z M53.2,56.2h9.3v1.2h-9.3V56.2z"/>
							<path className="stAnalytics" d="M70.6,49.4h1.2l0.2,2.3H72c1.5-1.5,3.1-2.7,5.2-2.7c3.1,0,4.6,1.9,4.6,5.8v9.7h-1.4V55c0-3.2-1-4.7-3.4-4.7
			c-1.8,0-3.1,0.9-4.9,2.8v11.4h-1.4V49.4z"/>
							<path className="stAnalytics" d="M87.8,60.7c0-3.3,3-4.9,9.7-5.7c0-2.3-0.6-4.8-3.6-4.8c-2,0-3.8,1-4.8,1.8l-0.6-1c1.1-0.8,3.2-2,5.6-2
			c3.6,0,4.8,2.6,4.8,5.8v9.6h-1.2l-0.2-2h-0.1c-1.5,1.2-3.4,2.3-5.3,2.3C89.8,64.9,87.8,63.6,87.8,60.7z M97.5,61.2v-5.1
			c-6.2,0.7-8.3,2.2-8.3,4.6c0,2.2,1.5,3,3.2,3C94.1,63.7,95.6,62.8,97.5,61.2z"/>
							<path className="stAnalytics" d="M105.8,62.5V41.9h1.4v20.7c0,0.7,0.3,1,0.7,1c0.1,0,0.3,0,0.6-0.1l0.2,1.1c-0.3,0.1-0.5,0.2-1,0.2
			C106.5,64.9,105.8,64.1,105.8,62.5z"/>
							<path className="stAnalytics" d="M112.7,71l0.3-1.2c0.3,0.1,0.8,0.2,1.2,0.2c1.8,0,3.1-1.7,3.9-4l0.4-1.3l-6.2-15.3h1.5l3.6,9.5
			c0.5,1.3,1.1,2.9,1.6,4.2h0.1c0.5-1.3,1-2.9,1.4-4.2l3.2-9.5h1.4l-5.9,17c-0.8,2.5-2.4,5-5.1,5C113.6,71.3,113.1,71.2,112.7,71z"
							/>
							<path className="stAnalytics" d="M130.4,60.3v-9.7h-2.4v-1.1l2.4-0.1l0.2-4.4h1.2v4.4h4.4v1.2h-4.4v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C131.3,64.9,130.4,63,130.4,60.3z"/>
							<path className="stAnalytics" d="M140.9,44.6c0-0.8,0.6-1.2,1.3-1.2c0.7,0,1.3,0.5,1.3,1.2c0,0.7-0.6,1.2-1.3,1.2
			C141.5,45.8,140.9,45.3,140.9,44.6z M141.4,49.4h1.4v15.1h-1.4V49.4z"/>
							<path className="stAnalytics" d="M148.9,57c0-5,3.2-7.9,6.9-7.9c2.1,0,3.4,0.9,4.4,1.9l-0.8,1c-1-0.9-2.1-1.6-3.6-1.6c-3.1,0-5.5,2.7-5.5,6.7
			c0,4,2.2,6.7,5.4,6.7c1.6,0,3-0.8,4-1.7l0.7,1c-1.3,1.2-2.9,2-4.8,2C151.8,64.9,148.9,62,148.9,57z"/>
							<path className="stAnalytics" d="M163.9,62.8l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3
			c-2.3-0.8-4.8-1.7-4.8-4.3c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.3,0-3.4,1.4-3.4,2.7
			c0,1.7,1.8,2.4,3.6,3c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C167.2,64.9,165.3,63.9,163.9,62.8z"/>
						</g>
						<g>
							<g>
								<g>
									<path className="stAnalytics" d="M160.8,178.4H66c-1.1,0-2.1,0.9-2.1,2.1c0,1.1,0.9,2.1,2.1,2.1h94.9c1.1,0,2.1-0.9,2.1-2.1
					C162.9,179.3,162,178.4,160.8,178.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M82.5,153.6H70.1c-1.1,0-2.1,0.9-2.1,2.1v24.7c0,1.1,0.9,2.1,2.1,2.1h12.4c1.1,0,2.1-0.9,2.1-2.1v-24.7
					C84.5,154.5,83.6,153.6,82.5,153.6z M80.4,178.4h-8.2v-20.6h8.3L80.4,178.4L80.4,178.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M107.2,133H94.8c-1.1,0-2.1,0.9-2.1,2.1v45.4c0,1.1,0.9,2.1,2.1,2.1h12.4c1.1,0,2.1-0.9,2.1-2.1V135
					C109.3,133.9,108.4,133,107.2,133z M105.2,178.4h-8.3v-41.3h8.3L105.2,178.4L105.2,178.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M132,141.2h-12.4c-1.1,0-2.1,0.9-2.1,2.1v37.1c0,1.1,0.9,2.1,2.1,2.1H132c1.1,0,2.1-0.9,2.1-2.1v-37.1
					C134,142.2,133.1,141.2,132,141.2z M129.9,178.4h-8.3v-33h8.3V178.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M156.7,116.5h-12.4c-1.1,0-2.1,0.9-2.1,2.1v61.9c0,1.1,0.9,2.1,2.1,2.1h12.4c1.1,0,2.1-0.9,2.1-2.1v-61.9
					C158.8,117.4,157.9,116.5,156.7,116.5z M154.7,178.4h-8.3v-57.7h8.3V178.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M76.3,120.6c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3
					C84.5,124.3,80.8,120.6,76.3,120.6z M76.3,133c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.9,4.1,4.1
					C80.4,131.1,78.6,133,76.3,133z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M101,100c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3c4.5,0,8.3-3.7,8.3-8.3
					C109.3,103.7,105.6,100,101,100z M101,112.4c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.9,4.1,4.1
					C105.2,110.5,103.3,112.4,101,112.4z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M125.8,108.2c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3S130.3,108.2,125.8,108.2z
					 M125.8,120.6c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.9,4.1,4.1S128.1,120.6,125.8,120.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M150.5,83.5c-4.5,0-8.3,3.7-8.3,8.3c0,4.5,3.7,8.3,8.3,8.3s8.3-3.7,8.3-8.3
					C158.8,87.2,155.1,83.5,150.5,83.5z M150.5,95.9c-2.3,0-4.1-1.9-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1s4.1,1.9,4.1,4.1
					S152.8,95.9,150.5,95.9z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M147.6,94.6c-0.8-0.8-2.1-0.8-2.9,0l-16,16c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.6,1.5,0.6
					c0.5,0,1.1-0.2,1.5-0.6l16-16C148.4,96.8,148.4,95.4,147.6,94.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M120.8,111.7l-13.2-3.8c-1.1-0.3-2.2,0.3-2.5,1.4c-0.3,1.1,0.3,2.2,1.4,2.5l13.2,3.8
					c0.2,0.1,0.4,0.1,0.6,0.1c0.9,0,1.7-0.6,2-1.5C122.5,113.2,121.9,112,120.8,111.7z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stAnalytics" d="M97.8,110.8c-0.7-0.9-2-1-2.9-0.3l-15.6,12.4c-0.9,0.7-1,2-0.3,2.9c0.4,0.5,1,0.8,1.6,0.8
					c0.4,0,0.9-0.1,1.3-0.4l15.6-12.4C98.4,113,98.5,111.7,97.8,110.8z"/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		case "live-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stLive{fill:' + activeLive + "}"}
					</style>
					<g>
						<g>
							<path className="stLive" d="M86.8,42.7h1.4v19.4h9.5v1.2h-11V42.7z"/>
							<path className="stLive" d="M102.4,43.4c0-0.8,0.6-1.2,1.3-1.2c0.7,0,1.3,0.5,1.3,1.2c0,0.7-0.6,1.2-1.3,1.2
			C102.9,44.6,102.4,44.1,102.4,43.4z M102.9,48.3h1.4v15.1h-1.4V48.3z"/>
							<path className="stLive" d="M109.1,48.3h1.5l3.4,9.4c0.5,1.5,1,3,1.5,4.3h0.1c0.5-1.4,1-2.9,1.5-4.3l3.4-9.4h1.4l-5.6,15.1h-1.6
			L109.1,48.3z"/>
							<path className="stLive" d="M125.5,55.8c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1H127c0.1,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.7,1.5C128.6,63.7,125.5,60.7,125.5,55.8z M136.6,54.8c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H136.6z"/>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path className="stLive" d="M103.8,88.1c-19.5,0-35.3,15.8-35.3,35.3s15.8,35.3,35.3,35.3s35.3-15.8,35.3-35.3
						C139.1,103.9,123.3,88.1,103.8,88.1z M103.8,91.4c10.4,0,20.1,5.1,26.1,13.6l-4.2,4.2c-1-0.6-2.2-1-3.4-1c-3.7,0-6.7,3-6.7,6.7
						c0,1.2,0.3,2.4,1,3.4l-11,11c-2.1-1.3-4.7-1.3-6.8,0l-7.6-7.6c0.6-1,0.9-2.2,1-3.4c0-3.7-3-6.7-6.7-6.7c-3.1,0-5.7,2.1-6.5,5
						h-6.2C75.8,102,88.8,91.5,103.8,91.4z M125.7,115c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4
						C124.2,111.6,125.7,113.1,125.7,115z M105.5,135.2c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4s1.5-3.4,3.4-3.4
						C104,131.8,105.5,133.3,105.5,135.2z M88.7,118.3c0,1.9-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4
						S88.7,116.5,88.7,118.3z M119.3,151.3c-4.7,2.6-10.1,4-15.5,4c-17.6,0-31.9-14.3-32-31.9c0-1.1,0.1-2.3,0.2-3.4h6.8
						c0.8,3,3.4,5,6.5,5c1.2,0,2.4-0.3,3.4-1l7.6,7.6c-0.6,1-0.9,2.2-1,3.4c0,3.7,3,6.7,6.7,6.7s6.7-3,6.7-6.7c0-1.2-0.3-2.4-1-3.4
						l11-11c1,0.6,2.2,0.9,3.4,1c3.7,0,6.7-3,6.7-6.7c0-1.2-0.3-2.4-1-3.4l3.7-3.7C140.3,123.3,134.7,142.8,119.3,151.3z"/>
									</g>
								</g>
							</g>
							<g>
								<g>
									<g>
										<path className="stLive" d="M145.9,98.2c3.1,0,5.7-2.1,6.5-5h10.3v-3.4h-10.3c-0.8-3-3.4-5-6.5-5c-3.7,0-6.7,3-6.7,6.7
						c0,1.2,0.3,2.4,1,3.4l-3,3C123,79.4,96.6,75.9,78.2,90s-21.9,40.5-7.8,58.9c13.3,17.4,37.8,21.6,56.2,9.7l5.1,5.1l2.4,2.4
						l13.5,13.5c3.4,3.4,9,3.4,12.5,0s3.4-9,0-12.5l-13.5-13.5l-2.4-2.4l-5.1-5.1c9-13.9,9-31.8,0-45.6l3.4-3.4
						C143.5,97.8,144.7,98.1,145.9,98.2z M145.9,88.1c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4s-3.4-1.5-3.4-3.4S144,88.1,145.9,88.1
						z M157.7,169.6c2.2,2.1,2.2,5.6,0.1,7.7c-2.1,2.2-5.6,2.2-7.7,0.1c0,0-0.1-0.1-0.1-0.1l-13.5-13.5l7.7-7.7L157.7,169.6z
						 M141.8,153.6l-7.7,7.7l-4.7-4.7c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.3,0.7-0.6,1.1-0.9
						c0.2-0.2,0.4-0.3,0.5-0.5c0.7-0.6,1.4-1.3,2-2c0.2-0.2,0.3-0.4,0.5-0.5c0.3-0.4,0.6-0.7,0.9-1.1c0.2-0.2,0.4-0.5,0.6-0.8
						c0.2-0.2,0.4-0.5,0.6-0.7L141.8,153.6z M135.3,145.8c-0.5,0.7-1.1,1.5-1.7,2.2c-0.2,0.2-0.3,0.4-0.5,0.6
						c-0.5,0.6-1,1.2-1.6,1.7c-0.2,0.2-0.5,0.5-0.7,0.7c-0.6,0.5-1.1,1.1-1.7,1.6c-0.2,0.2-0.4,0.4-0.6,0.5
						c-0.7,0.6-1.4,1.1-2.2,1.7c-17.4,12.4-41.6,8.4-54-9s-8.4-41.6,9-54s41.6-8.4,54,9C144.9,114.3,144.9,132.4,135.3,145.8z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		case "odis-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stOdis{fill:' + activeOdis + "}"}
					</style>
					<g>
						<g>
							<path className="stOdis" d="M89.1,169.8c0-3.8,2.1-6.1,5-6.1c3,0,5,2.3,5,6.1s-2.1,6.2-5,6.2C91.1,176,89.1,173.6,89.1,169.8z
			 M97.6,169.8c0-2.9-1.4-4.8-3.5-4.8s-3.5,1.8-3.5,4.8s1.4,4.8,3.5,4.8S97.6,172.8,97.6,169.8z"/>
							<path className="stOdis" d="M105.3,164h3c3.6,0,5.6,2.1,5.6,5.9c0,3.8-1.9,5.9-5.5,5.9h-3L105.3,164L105.3,164z M108.1,174.5
			c2.8,0,4.1-1.7,4.1-4.7s-1.4-4.6-4.1-4.6h-1.3v9.3H108.1z"/>
							<path className="stOdis" d="M120,164h1.5v11.8H120V164z"/>
							<path className="stOdis" d="M127.4,174.2l0.9-1c0.9,0.9,2,1.5,3.3,1.5c1.5,0,2.5-0.8,2.5-1.9c0-1.2-0.9-1.6-2-2.1l-1.7-0.7
			c-1.1-0.5-2.4-1.3-2.4-3c0-1.8,1.6-3.1,3.7-3.1c1.4,0,2.6,0.6,3.5,1.5l-0.8,1c-0.7-0.7-1.6-1.1-2.7-1.1c-1.3,0-2.2,0.7-2.2,1.7
			c0,1.1,1,1.6,2,2l1.7,0.7c1.4,0.6,2.4,1.4,2.4,3.2c0,1.9-1.5,3.4-4,3.4C129.9,176,128.5,175.3,127.4,174.2z"/>
						</g>
						<g>
							<g>
								<g>
									<g>
										<path className="stOdis" d="M79.5,109.8h-6.4l-6-6.7h-4.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h6l6,6.7h4.7c1.1,0,2,0.9,2,2
						S80.6,109.8,79.5,109.8z"/>
									</g>
									<g>
										<path className="stOdis" d="M151.3,109.8h-6.4c-1.1,0-2-0.9-2-2s0.9-2,2-2h4.7l6-6.7h5.3c1.1,0,2,0.9,2,2s-0.9,2-2,2h-3.6L151.3,109.8
						z"/>
									</g>
									<g>
										<path className="stOdis" d="M131.1,109.8H125l-7.1-7.1h-11.5l-6.8,6.8h-6.4c-1.1,0-2-0.9-2-2s0.9-2,2-2H98l6.8-6.8h14.8l7.1,7.1h4.4
						c1.1,0,2,0.9,2,2S132.3,109.8,131.1,109.8z"/>
									</g>
								</g>
								<g>
									<g>
										<path className="stOdis" d="M68.8,129.4h-5c-1.1,0-2-0.9-2-2s0.9-2,2-2H67l6-6.7h6.4c1.1,0,2,0.9,2,2s-0.9,2-2,2h-4.7L68.8,129.4z"/>
									</g>
									<g>
										<path className="stOdis" d="M160.2,129.4h-4.7l-6-6.7h-4.7c-1.1,0-2-0.9-2-2s0.9-2,2-2h6.4l6,6.7h2.9c1.1,0,2,0.9,2,2
						C162.1,128.5,161.3,129.4,160.2,129.4z"/>
									</g>
									<g>
										<path className="stOdis" d="M119.6,129.8h-14.8L98,123h-4.7c-1.1,0-2-0.9-2-2s0.9-2,2-2h6.4l6.8,6.8H118l7.1-7.1h6.1c1.1,0,2,0.9,2,2
						s-0.9,2-2,2h-4.4L119.6,129.8z"/>
									</g>
								</g>
							</g>
							<g>
								<g>
									<path className="stOdis" d="M112.2,142.3c-15.5,0-28.1-12.6-28.1-28.1s12.6-28.1,28.1-28.1s28.1,12.6,28.1,28.1
					S127.7,142.3,112.2,142.3z M112.2,90.2c-13.3,0-24.1,10.8-24.1,24.1s10.8,24.1,24.1,24.1s24.1-10.8,24.1-24.1
					S125.5,90.2,112.2,90.2z"/>
								</g>
								<g>
									<path className="stOdis" d="M112.2,122.2c-4.4,0-7.9-3.5-7.9-7.9s3.5-7.9,7.9-7.9s7.9,3.5,7.9,7.9S116.6,122.2,112.2,122.2z
					 M112.2,110.4c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S114.3,110.4,112.2,110.4z"/>
								</g>
							</g>
							<g>
								<path className="stOdis" d="M111.4,148.5c-19.8,0-39.7-9.9-66.2-33l-0.7-0.6v-2l0.7-0.6c26.5-23.1,46.3-33,66.2-33c0.2,0,0.4,0,0.5,0
				c19.5,0.2,39.6,10,67.3,33l0.7,0.6v2l-0.7,0.6c-27.6,22.9-47.8,32.8-67.3,33C111.7,148.5,111.6,148.5,111.4,148.5z M49.5,113.9
				c25.1,21.6,43.8,30.8,62.4,30.6c18.1-0.2,37-9.3,63-30.6c-26-21.3-44.9-30.4-63-30.6c-0.2,0-0.3,0-0.5,0
				C93,83.3,74.4,92.5,49.5,113.9z"/>
							</g>
							<g>
								<path className="stOdis" d="M67.1,175.5h-17v-17c0-1.1,0.9-2,2-2s2,0.9,2,2v13h13c1.1,0,2,0.9,2,2S68.2,175.5,67.1,175.5z"/>
							</g>
							<g>
								<path className="stOdis" d="M174,175.5h-17c-1.1,0-2-0.9-2-2s0.9-2,2-2h13v-13c0-1.1,0.9-2,2-2s2,0.9,2,2V175.5z"/>
							</g>
							<g>
								<path className="stOdis" d="M172,70.6c-1.1,0-2-0.9-2-2v-13h-13c-1.1,0-2-0.9-2-2s0.9-2,2-2h17v17C174,69.7,173.1,70.6,172,70.6z"/>
							</g>
							<g>
								<path className="stOdis" d="M52.1,70.6c-1.1,0-2-0.9-2-2v-17h17c1.1,0,2,0.9,2,2s-0.9,2-2,2h-13v13C54.1,69.7,53.2,70.6,52.1,70.6z"/>
							</g>
							<g>
								<path className="stOdis" d="M75.4,164.4H61.2v-14.2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2v10.2h10.2c1.1,0,2,0.9,2,2S76.5,164.4,75.4,164.4z"
								/>
							</g>
							<g>
								<path className="stOdis" d="M162.9,164.4h-14.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h10.2v-10.2c0-1.1,0.9-2,2-2s2,0.9,2,2V164.4z"/>
							</g>
							<g>
								<path className="stOdis" d="M160.9,78.9c-1.1,0-2-0.9-2-2V66.7h-10.2c-1.1,0-2-0.9-2-2s0.9-2,2-2h14.2v14.2
				C162.9,78,162,78.9,160.9,78.9z"/>
							</g>
							<g>
								<path className="stOdis" d="M63.2,78.9c-1.1,0-2-0.9-2-2V62.7h14.2c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H65.2v10.2
				C65.2,78,64.3,78.9,63.2,78.9z"/>
							</g>
						</g>
						<g>
							<path className="stOdis" d="M80.1,61.9c-0.5,0-0.8,0-1.2-0.1c-0.4-0.1-0.6-0.2-0.8-0.5l0.4-1.1c0.2,0.2,0.4,0.4,0.6,0.5
			c0.2,0.1,0.6,0.1,1,0.1c0.4,0,0.7-0.1,1-0.4c0.2-0.2,0.4-0.5,0.4-1c0-0.5-0.1-0.7-0.2-1.1c-0.2-0.2-0.6-0.6-1.2-1.1
			c-0.6-0.5-1.1-0.8-1.3-1.3c-0.2-0.5-0.4-0.8-0.4-1.3c0-0.7,0.2-1.3,0.6-1.7c0.5-0.5,1-0.7,1.7-0.7c0.8,0,1.4,0.2,1.9,0.7l-0.4,1
			c-0.1-0.1-0.4-0.2-0.6-0.4c-0.2-0.1-0.5-0.1-0.8-0.1c-0.4,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.4,0.1,0.7,0.4,1
			c0.2,0.2,0.6,0.6,1.2,1c0.6,0.4,1,0.8,1.3,1.2s0.5,1,0.5,1.6c0,0.7-0.2,1.3-0.7,1.8C81.6,61.7,80.9,61.9,80.1,61.9z"/>
							<path className="stOdis" d="M87.3,61.9c-1.1,0-1.8-0.5-2.3-1.3c-0.5-0.8-0.7-2-0.7-3.5c0-1.4,0.2-2.6,0.7-3.5s1.3-1.3,2.3-1.3
			c1.1,0,1.8,0.5,2.3,1.3c0.5,0.8,0.7,2,0.7,3.5c0,1.4-0.2,2.6-0.7,3.5C89.1,61.6,88.4,61.9,87.3,61.9z M87.3,53.5
			c-0.6,0-1.1,0.4-1.3,1.1c-0.2,0.7-0.4,1.6-0.4,2.8c0,1.1,0.1,2,0.4,2.6c0.2,0.6,0.7,1,1.3,1s1.1-0.4,1.3-1
			c0.2-0.7,0.4-1.6,0.4-2.6c0-1.1-0.1-2-0.4-2.6C88.4,53.8,87.9,53.5,87.3,53.5z"/>
							<path className="stOdis" d="M95,62c-1.9,0-2.9-1-2.9-3v-6.4h1.4v6.5c0,1.2,0.5,1.7,1.4,1.7c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.1,0.6-0.2
			v-7.9h1.4v8.6c-0.2,0.2-0.7,0.4-1.2,0.6S95.6,62,95,62z"/>
							<path className="stOdis" d="M103.2,53.8c-0.2-0.1-0.5-0.1-0.8-0.1c-0.4,0-0.7,0.1-1,0.2v8h-1.4v-8.6c0.4-0.2,0.8-0.4,1.3-0.5
			c0.5-0.1,1.2-0.1,2.2-0.1L103.2,53.8z"/>
							<path className="stOdis" d="M107.5,61.9c-1,0-1.7-0.4-2.2-1.2c-0.6-0.8-0.8-2-0.8-3.5c0-1.4,0.2-2.6,0.8-3.5c0.6-0.8,1.3-1.2,2.2-1.2
			c0.5,0,0.8,0,1.1,0.1s0.5,0.2,0.7,0.5l-0.4,1c-0.1-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.6,0-1.1,0.4-1.4,1
			c-0.4,0.6-0.5,1.6-0.5,2.6c0,1.1,0.1,1.9,0.5,2.6c0.4,0.6,0.7,1,1.3,1c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.4l0.4,1
			c-0.1,0.2-0.4,0.4-0.7,0.5C108.3,61.9,108,61.9,107.5,61.9z"/>
							<path className="stOdis" d="M111.9,57.7c0,1,0.2,1.7,0.6,2.3c0.4,0.5,1,0.8,1.7,0.8c0.5,0,0.8,0,1.1-0.1c0.2-0.1,0.6-0.2,0.8-0.4l0.4,1.1
			c-0.2,0.2-0.6,0.4-1,0.5c-0.5,0.1-0.8,0.2-1.4,0.2c-1.1,0-1.9-0.4-2.5-1.2c-0.6-0.8-1-2-1-3.6c0-1.4,0.2-2.6,0.8-3.5
			c0.6-0.8,1.3-1.2,2.3-1.2c2,0,3,1.6,3,4.7L111.9,57.7z M113.7,53.5c-0.5,0-1,0.2-1.3,0.8c-0.4,0.6-0.5,1.3-0.5,2.4l3.4-0.5
			C115.3,54.5,114.8,53.5,113.7,53.5z"/>
							<path className="stOdis" d="M125.1,61.8h-1.8l-0.8-4.1c0-0.2-0.1-0.5-0.1-0.6c0-0.1,0-0.4-0.1-0.4l-0.2,1l-0.8,4.1h-1.8l-1.7-9.4h2.2
			l0.7,4.9c0,0.2,0.1,0.5,0.1,0.6s0,0.4,0,0.5c0-0.1,0-0.2,0-0.5c0-0.1,0-0.4,0.1-0.7l1-4.9h1.4l1,4.9c0,0.2,0.1,0.5,0.1,0.7
			s0,0.4,0,0.5c0-0.2,0-0.4,0-0.5c0-0.2,0.1-0.5,0.1-0.6l0.7-4.9h1.9L125.1,61.8z"/>
							<path className="stOdis" d="M127.8,61.8v-9.4h4.2v1.7h-2v2h1.7v1.7h-1.7v2.3h1.9v1.7L127.8,61.8L127.8,61.8z"/>
							<path className="stOdis" d="M137,61.8l-1.7-4.3v4.3h-2.2v-9c0.7-0.4,1.6-0.6,2.5-0.6c1.1,0,1.9,0.2,2.5,0.8c0.6,0.6,0.7,1.3,0.7,2.3
			c0,0.5-0.1,1-0.5,1.4c-0.2,0.4-0.6,0.7-1.1,0.8l1.9,4.2L137,61.8L137,61.8z M135.7,53.8c-0.1,0-0.2,0-0.4,0v3h0.2
			c0.4,0,0.7-0.1,1-0.5c0.2-0.2,0.4-0.6,0.4-1.1s-0.1-0.8-0.2-1.2S136.1,53.8,135.7,53.8z"/>
							<path className="stOdis" d="M144.2,61.8l-1.7-4.8v4.8h-2.2v-9.4h2.2v4.3l1.8-4.3h2.2l-1.9,4.3l2.2,5H144.2z"/>
						</g>
					</g>
				</svg>
			)
		case "kpi-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stKPI{fill:' + activeKPI + "}"}
					</style>
					<g>
						<g>
							<path className="stKPI" d="M94.8,42.2h1.4v11.5h0.1l10-11.5h1.7l-6.6,7.7l7.5,13h-1.7L100.4,51l-4.2,4.8v7.1h-1.4V42.2z"/>
							<path className="stKPI" d="M113.8,42.2h5.2c4.7,0,7.5,1.5,7.5,5.8c0,4.1-2.8,6-7.4,6h-3.9v8.9h-1.4C113.8,62.9,113.8,42.2,113.8,42.2z
			 M118.8,52.8c4.2,0,6.2-1.4,6.2-4.8c0-3.5-2.1-4.5-6.4-4.5h-3.4v9.3H118.8z"/>
							<path className="stKPI" d="M132.6,42.2h1.4v20.7h-1.4V42.2z"/>
						</g>
						<g>
							<g>
								<g>
									<path className="stKPI" d="M154.2,81.4c-0.4,0-1.4,0-1.6,0H93.3c-1.1,0-2,0.9-2,2s0.9,2,2,2h53.9c-0.8,1.3-1.2,2.7-1.2,4.3v25.7
					c0,1.1,0.9,2,2,2s2-0.9,2-2v-6.1h10.6c1.1,0,2-0.9,2-2V89.6C162.5,85.1,158.8,81.4,154.2,81.4z M158.6,105.3H150V89.6
					c0-2.4,1.9-4.3,4.3-4.3c2.4,0,4.3,1.9,4.3,4.3V105.3L158.6,105.3z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M160.4,161.4l-12.8-12.8c1.5-3,2.3-6.4,2.3-10c0-12.6-10.2-22.8-22.8-22.8s-22.8,10.2-22.8,22.8
					c0,2.9,0.5,5.6,1.5,8.2l-2.3,2.3l-9.5-9.5c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6l-2.2,2.2v-17.9c0-1.1-0.9-2-2-2s-2,0.9-2,2
					v21.9l-17.8,17.8c-0.8,0.8-0.8,2,0,2.8c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6l15-15v14.2h-8.6c-1.1,0-2,0.9-2,2v6.3
					c0,4.6,3.7,8.3,8.3,8.3h58.9c4.6,0,8.3-3.7,8.3-8.3v-1.7c1.4,1.3,3.2,2.1,5.1,2.1l0,0c2,0,3.8-0.8,5.2-2.2
					C163.2,169,163.2,164.3,160.4,161.4z M127,119.7c10.4,0,18.9,8.5,18.9,18.9s-8.5,18.9-18.9,18.9c-7.1,0-13.4-4-16.6-9.9
					l11.4-11.4v4.5c0,1.1,0.9,2,2,2s2-0.9,2-2v-9.4c0-1.1-0.9-2-2-2h-9.4c-1.1,0-2,0.9-2,2s0.9,2,2,2h4.9l-10.4,10.4
					c-0.4-1.6-0.7-3.3-0.7-5.1C108.2,128.2,116.6,119.7,127,119.7z M82.7,178c-2.4,0-4.3-1.9-4.3-4.3v-4.3h55v2.4c0,0.1,0,0.1,0,0.2
					c0,0.1,0,0.1,0,0.2v1.6c0,1.6,0.4,3.1,1.2,4.3L82.7,178L82.7,178z M146,173.7c0,2.4-1.9,4.3-4.3,4.3c-2.4,0-4.3-1.9-4.3-4.3
					v-1.6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2v-4.3c0-1.1-0.9-2-2-2H89v-18.1l3.6-3.6l9.5,9.5c0.4,0.4,0.9,0.6,1.4,0.6
					s1-0.2,1.4-0.6l2.7-2.7c4,6.5,11.2,10.9,19.4,10.9c3.6,0,6.9-0.8,10-2.3l9,9V173.7z M157.5,169.1c-0.7,0.7-1.5,1-2.5,1l0,0
					c-0.9,0-1.8-0.4-2.4-1l-12.2-12.2c1.9-1.4,3.6-3.1,5-4.9l12.2,12.2C158.9,165.6,158.9,167.8,157.5,169.1z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M139.5,136.6c-1.1,0-2,0.9-2,2c0,5.8-4.7,10.5-10.5,10.5c-1.1,0-2,0.9-2,2s0.9,2,2,2
					c7.9,0,14.4-6.5,14.4-14.4C141.4,137.5,140.6,136.6,139.5,136.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M112.2,115.2h-1.8V102c0-1.1-0.9-2-2-2h-9c-1.1,0-2,0.9-2,2v13.2h-3.2V93.9c0-1.1-0.9-2-2-2h-8.6
					c-1.1,0-2,0.9-2,2v21.3h-3.1v-8.6c0-1.1-0.9-2-2-2H68c-1.1,0-2,0.9-2,2v8.6h-2.2c-1.1,0-2,0.9-2,2s0.9,2,2,2h48.4
					c1.1,0,2-0.9,2-2C114.2,116,113.3,115.2,112.2,115.2z M74.7,115.2H70v-6.7h4.7V115.2z M90.4,115.2h-4.7V95.9h4.7V115.2z
					 M106.5,115.2h-5.1V104h5.1V115.2z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M128.2,92H113c-1.1,0-2,0.9-2,2s0.9,2,2,2h15.2c1.1,0,2-0.9,2-2C130.2,92.9,129.3,92,128.2,92z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M139.3,92.6c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6s-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
					c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6s0.6-0.9,0.6-1.4C139.9,93.4,139.7,92.9,139.3,92.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M120.6,100.6h-3.2c-1.1,0-2,0.9-2,2s0.9,2,2,2h3.2c1.1,0,2-0.9,2-2S121.7,100.6,120.6,100.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M137.9,100.6h-9.7c-1.1,0-2,0.9-2,2s0.9,2,2,2h9.7c1.1,0,2-0.9,2-2S139,100.6,137.9,100.6z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stKPI" d="M65.3,168.3c-0.4-0.4-0.9-0.6-1.4-0.6s-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4s0.2,1,0.6,1.4
					c0.4,0.4,0.9,0.6,1.4,0.6s1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4C65.8,169.2,65.6,168.7,65.3,168.3z"/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		case "expertise-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stExpertise{fill:' + activeExpertise + "}"}
					</style>
					<g>
						<g>
							<path className="stExpertise" d="M50.4,42.6h11.3v1.2h-9.9v7.8h8.3v1.2h-8.3v9.1H62v1.2H50.4V42.6z"/>
							<path className="stExpertise" d="M69.9,55.4l-4.7-7.2h1.5l2.4,3.8c0.5,0.8,1,1.7,1.6,2.5h0.1c0.5-0.8,1-1.7,1.6-2.5l2.3-3.8h1.5l-4.6,7.3
			l5.1,7.8h-1.5l-2.7-4.1c-0.6-0.9-1.2-1.9-1.8-2.8h-0.1c-0.6,0.9-1.1,1.8-1.7,2.8l-2.6,4.1h-1.5L69.9,55.4z"/>
							<path className="stExpertise" d="M81.6,48.2h1.2l0.2,1.9h0.1c1.5-1.2,3.3-2.3,5.1-2.3c4,0,6,3,6,7.7c0,5.1-3.1,8.1-6.6,8.1
			c-1.4,0-3-0.7-4.5-1.9v2.8v5.5h-1.4L81.6,48.2L81.6,48.2z M92.7,55.5c0-3.7-1.3-6.5-4.8-6.5c-1.5,0-3.1,0.9-4.9,2.5v8.9
			c1.7,1.4,3.3,1.9,4.4,1.9C90.5,62.4,92.7,59.6,92.7,55.5z"/>
							<path className="stExpertise" d="M98.9,55.7c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1h-10.8c0.1,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.7,1.5C102.1,63.6,98.9,60.6,98.9,55.7z M110,54.7c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H110z"/>
							<path className="stExpertise" d="M116.9,48.2h1.2l0.2,2.8h0.1c1-1.9,2.5-3.2,4.3-3.2c0.5,0,1,0.1,1.5,0.3l-0.3,1.3c-0.5-0.2-0.8-0.3-1.4-0.3
			c-1.4,0-3,1-4.1,3.9v10.2H117L116.9,48.2L116.9,48.2z"/>
							<path className="stExpertise" d="M128.8,59.1v-9.7h-2.4v-1.1l2.4-0.1l0.2-4.4h1.2v4.4h4.4v1.2h-4.4v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C129.6,63.6,128.8,61.8,128.8,59.1z"/>
							<path className="stExpertise" d="M139.3,43.3c0-0.8,0.6-1.2,1.3-1.2s1.3,0.5,1.3,1.2s-0.6,1.2-1.3,1.2C139.8,44.5,139.3,44,139.3,43.3z
			 M139.8,48.2h1.4v15.1h-1.4V48.2z"/>
							<path className="stExpertise" d="M146.7,61.5l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3
			c-2.3-0.8-4.8-1.7-4.8-4.3c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.4,0-3.4,1.4-3.4,2.7
			c0,1.7,1.8,2.4,3.6,3c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C150,63.6,148,62.7,146.7,61.5z"/>
							<path className="stExpertise" d="M161.6,55.7c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1h-10.8c0.1,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.7,1.5C164.7,63.6,161.6,60.6,161.6,55.7z M172.6,54.7c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H172.6z"/>
						</g>
						<g>
							<g>
								<path className="stExpertise" d="M118.7,130.7c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C118.6,129,118.3,129.9,118.7,130.7z"/>
							</g>
							<g>
								<path className="stExpertise" d="M120.2,112.1c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2c-0.3-0.9-1.2-1.3-2-1.1
				c-5,1.6-14.5,6.4-14.9,6.6c-0.8,0.4-1.1,1.4-0.7,2.2C119,111.7,119.6,112.1,120.2,112.1z"/>
							</g>
							<g>
								<path className="stExpertise" d="M159.3,108.8c-0.9,0-1.6,0.7-1.6,1.6V174c0,2.7-2.2,4.9-4.9,4.9h-39.2v-3.8c2.4-0.8,8.6-2.8,14.7-2.8
				c14.2,0,23.9,3.1,24,3.2c0.5,0.2,1,0.1,1.5-0.2c0.4-0.3,0.7-0.8,0.7-1.3v-71.8c0-0.8-0.5-1.4-1.3-1.6c0,0-1.3-0.3-3.5-0.7
				c-0.9-0.2-1.7,0.4-1.9,1.3c-0.2,0.9,0.4,1.7,1.3,1.9c0.8,0.2,1.5,0.3,2.1,0.4v68.3c-3.7-1-11.9-2.7-22.8-2.7
				c-7.1,0-14.1,2.3-16.3,3.1c-1.9-0.8-7.7-3.1-14.7-3.1c-11.2,0-20.5,1.9-24.5,2.8v-68.4c3.3-0.8,12.9-2.9,24.5-2.9
				c5.9,0,11,1.9,13.1,2.7v64.2c0,0.6,0.3,1.1,0.8,1.4c0.5,0.3,1.1,0.3,1.6,0c0.2-0.1,16.3-9.8,30.7-14.5c0.7-0.2,1.1-0.8,1.1-1.5
				V86c0-0.5-0.3-1-0.7-1.3s-1-0.4-1.5-0.2c-13.1,4.9-26.3,13.1-26.4,13.2c-0.8,0.5-1,1.5-0.5,2.2c0.5,0.8,1.5,1,2.2,0.5
				c0.1-0.1,11.6-7.2,23.6-12v63.3c-11.1,3.9-22.8,10.2-27.7,13v-62.4c0-0.6-0.3-1.2-0.9-1.5c-0.3-0.1-7-3.4-15.4-3.4
				c-14.7,0-26.1,3.2-26.6,3.3c-0.7,0.2-1.2,0.8-1.2,1.6v71.8c0,0.5,0.2,1,0.6,1.3c0.3,0.2,0.6,0.3,1,0.3c0.2,0,0.3,0,0.4-0.1
				c0.1,0,11.4-3.2,25.7-3.2c6,0,11.1,1.9,13.1,2.7v3.8H71.2c-2.7,0-4.9-2.2-4.9-4.9v-63.6c0-0.9-0.7-1.6-1.6-1.6
				c-0.9,0-1.6,0.7-1.6,1.6v63.6c0,4.5,3.7,8.2,8.2,8.2h81.5c4.5,0,8.2-3.7,8.2-8.2v-63.6C160.9,109.5,160.2,108.8,159.3,108.8z"/>
							</g>
							<g>
								<path className="stExpertise" d="M118.7,121c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C118.6,119.2,118.3,120.2,118.7,121z"/>
							</g>
							<g>
								<path className="stExpertise" d="M118.7,140.5c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C118.6,138.8,118.3,139.7,118.7,140.5z"/>
							</g>
							<g>
								<path className="stExpertise" d="M102.6,114.6c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2
				c0.1,0,0.3,0,0.4-0.1c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C104,115.7,103.5,114.8,102.6,114.6z"/>
							</g>
							<g>
								<path className="stExpertise" d="M102.6,124.4c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2
				c0.1,0,0.3,0,0.4-0.1c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C104,125.5,103.5,124.6,102.6,124.4z"/>
							</g>
							<g>
								<path className="stExpertise" d="M118.7,150.3c0.3,0.6,0.9,0.9,1.5,0.9c0.2,0,0.5-0.1,0.7-0.2c0.1,0,9.7-4.9,14.4-6.4c0.9-0.3,1.3-1.2,1.1-2
				c-0.3-0.9-1.2-1.3-2-1.1c-5,1.6-14.5,6.4-14.9,6.6C118.6,148.5,118.3,149.5,118.7,150.3z"/>
							</g>
							<g>
								<path className="stExpertise" d="M102.6,134.2c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2
				c0.1,0,0.3,0,0.4-0.1c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C104,135.3,103.5,134.4,102.6,134.2z"/>
							</g>
							<g>
								<path className="stExpertise" d="M102.6,153.8c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2
				c0.1,0,0.3,0,0.4-0.1c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C104,154.8,103.5,154,102.6,153.8z"/>
							</g>
							<g>
								<path className="stExpertise" d="M102.6,144c-10.1-2.4-21.5,0.6-22,0.8c-0.9,0.2-1.4,1.1-1.2,2c0.2,0.7,0.9,1.2,1.6,1.2c0.1,0,0.3,0,0.4-0.1
				c0.1,0,11.1-2.9,20.4-0.8c0.9,0.2,1.8-0.3,2-1.2C104,145.1,103.5,144.2,102.6,144z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "extrud3d-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stExtrud3d{fill:' + activeExtrud3d + "}"}
					</style>
					<g>
						<g>
							<g>
								<g>
									<path className="stExtrud3d" d="M146.5,83.1H72.7c-1.1,0-1.9,0.9-1.9,1.9v95.5c0,1.1,0.9,1.9,1.9,1.9h73.8c1.1,0,1.9-0.9,1.9-1.9V85
					C148.4,84,147.6,83.1,146.5,83.1z M83.8,178.6h-9.1v-3.9h9.1V178.6z M83.8,170.9h-9.1V165h9.1V170.9z M94.1,178.6h-6.4v-3.9h6.4
					V178.6z M94.1,170.9h-6.4V165h6.4V170.9z M105.7,178.6h-7.8v-3.9h7.8V178.6z M105.7,170.9h-7.8V165h7.8V170.9z M144.6,178.6h-35
					V165h35V178.6z M144.6,161.2H74.6V94.7h69.9V161.2z M144.6,90.9H74.6V87h69.9V90.9z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M138.7,168.9h-15.5c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9h15.5c1.1,0,1.9-0.9,1.9-1.9
					S139.8,168.9,138.7,168.9z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M115.4,168.9c-1.1,0-1.9,0.9-1.9,1.9s0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9S116.5,168.9,115.4,168.9z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M131.9,105.2c0-1-0.9-1.9-1.9-1.9c0,0-25.1,0-25.1,0c-0.5,0-1,0.2-1.4,0.6c0,0-15.7,16.3-15.7,16.3
					c-0.3,0.4-0.5,0.9-0.5,1.3c0,0,0,27.2,0,27.2c0,0.3,0.1,0.5,0.2,0.7c0.3,0.7,1,1.2,1.8,1.2c0,0,27.2,0,27.2,0
					c0.5,0,1.1-0.3,1.5-0.7l13.6-15.8c0.3-0.4,0.5-0.8,0.5-1.3C131.9,133,131.9,105.2,131.9,105.2z M114.5,146.8H93.7
					c0.3-0.7,0.2-1.6-0.4-2.2c-0.6-0.6-1.4-0.7-2.2-0.4v-20.7h13.7h0h9.6V146.8z M115.5,119.6h-10.6h0h-11l9.2-9.5l0,3.7
					c0,1.1,0.9,1.9,1.9,2h0l0,0v0c1.1,0,1.9-0.9,1.9-1.9l0-6.6h19L115.5,119.6z M128,131.1h-3.9c-1.1,0-1.9,0.9-1.9,1.9
					s0.9,1.9,1.9,1.9h1.6l-7.4,8.6v-21.3l9.7-11.7V131.1z"/>
								</g>
							</g>
							<g>
								<g>
									<path className="stExtrud3d" d="M108.6,131.1h-1.9l0-1.7c0-1.1-0.9-1.9-1.9-2h0c-1.1,0-1.9,0.9-1.9,1.9l0,2.9l-1.3,1.4
					c-0.8,0.8-0.7,2,0,2.7c0.4,0.4,0.9,0.5,1.4,0.5c0.5,0,1-0.2,1.4-0.6l1.3-1.3h3.1c1.1,0,1.9-0.9,1.9-1.9S109.7,131.1,108.6,131.1
					z"/>
								</g>
							</g>
							<g>
								<g>
									<circle className="stExtrud3d" cx="97.4" cy="140.4" r="1.9"/>
								</g>
							</g>
						</g>
						<g>
							<path className="stExtrud3d" d="M51.9,43.3h11.7v3.5h-7.5v3.7h6.4v3.5h-6.4v4.3h7.8V62h-12V43.3z"/>
							<path className="stExtrud3d" d="M70.3,54.6l-4.1-6.8h4.5l1.3,2.3c0.4,0.8,0.8,1.6,1.2,2.4h0.1c0.3-0.8,0.7-1.6,1-2.4l1-2.3h4.4L75.5,55
			l4.4,6.9h-4.5l-1.4-2.3c-0.4-0.8-0.9-1.7-1.3-2.5h-0.1c-0.4,0.8-0.7,1.6-1.1,2.5L70.3,62h-4.4L70.3,54.6z"/>
							<path className="stExtrud3d" d="M83.3,56.8V51h-1.9v-3.1l2.2-0.2L84,44h3.5v3.8h3.4V51h-3.4v5.7c0,1.6,0.7,2.3,1.8,2.3c0.5,0,1-0.1,1.4-0.3
			l0.7,3.1c-0.8,0.2-1.8,0.5-3.2,0.5C84.7,62.3,83.3,60.1,83.3,56.8z"/>
							<path className="stExtrud3d" d="M95.1,47.7h3.4l0.3,2.5h0.1c1-1.9,2.6-2.8,4-2.8c0.8,0,1.3,0.1,1.7,0.3l-0.7,3.6c-0.5-0.1-0.9-0.2-1.5-0.2
			c-1.1,0-2.4,0.7-3.1,2.6V62h-4.2V47.7z"/>
							<path className="stExtrud3d" d="M107.8,56.6v-8.8h4.2V56c0,2,0.5,2.7,1.8,2.7c1.1,0,1.7-0.4,2.5-1.6v-9.4h4.2V62h-3.4l-0.3-2h-0.1
			c-1.2,1.4-2.5,2.3-4.5,2.3C109.1,62.3,107.8,60.1,107.8,56.6z"/>
							<path className="stExtrud3d" d="M124.9,54.9c0-4.6,2.9-7.5,5.9-7.5c1.6,0,2.5,0.6,3.5,1.5l-0.2-2.2v-4.8h4.2V62h-3.4l-0.3-1.4h-0.1
			c-1,1-2.4,1.8-3.8,1.8C127.2,62.3,124.9,59.5,124.9,54.9z M134.1,57.6v-5.8c-0.7-0.7-1.6-0.9-2.4-0.9c-1.4,0-2.6,1.3-2.6,4
			c0,2.8,1,4,2.6,4C132.7,58.9,133.5,58.5,134.1,57.6z"/>
							<path className="stExtrud3d" d="M142.5,59.7l0.7-0.9c1.1,1.2,2.6,2.3,5.1,2.3c2.5,0,4.3-1.6,4.3-4c0-2.5-1.8-4.2-6.4-4.2v-1.1
			c4.2,0,5.7-1.8,5.7-4c0-2.1-1.5-3.4-3.7-3.4c-1.7,0-3.1,0.9-4.2,2l-0.8-0.9c1.3-1.2,2.8-2.2,5-2.2c2.8,0,5,1.6,5,4.4
			c0,2.4-1.5,3.8-3.5,4.5v0.1c2.3,0.5,4.2,2.2,4.2,4.8c0,3.2-2.4,5.2-5.6,5.2C145.4,62.3,143.6,61,142.5,59.7z"/>
							<path className="stExtrud3d" d="M159.6,43.1h4.4c5.8,0,8.6,3.7,8.6,9.4S169.8,62,164,62h-4.4V43.1z M163.8,60.8c5.1,0,7.3-3.5,7.3-8.4
			c0-4.9-2.2-8.3-7.3-8.3h-2.9v16.6H163.8z"/>
						</g>
					</g>
				</svg>
			)
		case "rex-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stREX{fill:' + activeREX + "}"}
					</style>
					<g>
						<g>
							<path className="stREX" d="M88.5,44.5h6.7c4,0,7.2,1.4,7.2,5.8c0,4.3-3.2,6.1-7.2,6.1h-2.5v6.7h-4.2V44.5z M94.9,53.1
			c2.2,0,3.4-0.9,3.4-2.8c0-1.8-1.2-2.5-3.4-2.5h-2.2v5.2H94.9z M94.5,55.3l2.9-2.7l6,10.5h-4.7L94.5,55.3z"/>
							<path className="stREX" d="M107.5,44.5h11.7V48h-7.5v3.7h6.4v3.5h-6.4v4.3h7.8v3.5h-12V44.5z"/>
							<path className="stREX" d="M127.9,53.5l-4.9-9.1h4.7l1.6,3.5c0.4,0.9,0.8,1.8,1.4,3h0.1c0.4-1.2,0.8-2.1,1.2-3l1.5-3.5h4.5l-4.9,9.3
			l5.2,9.4h-4.7l-1.8-3.8c-0.5-1-0.9-1.9-1.4-3.1h-0.1c-0.4,1.1-0.9,2.1-1.3,3.1l-1.7,3.8h-4.5L127.9,53.5z"/>
						</g>
						<g>
							<path className="stREX" d="M156,168.1h1.5v5.9h0l4.9-5.9h1.7l-3.7,4.5l4.3,7.3H163l-3.5-6.1l-2,2.4v3.7H156V168.1z"/>
							<path className="stREX" d="M172.1,169.4h-3.6v-1.3h8.6v1.3h-3.6v10.5h-1.5V169.4z"/>
							<path className="stREX" d="M182.9,168.1h3.4c2.6,0,4.4,0.9,4.4,3.5c0,2.5-1.8,3.6-4.4,3.6h-1.9v4.7h-1.5V168.1z M186.2,174
			c2.1,0,3-0.7,3-2.4c0-1.7-1-2.3-3.1-2.3h-1.7v4.7H186.2z"/>
						</g>
						<g>
							<g>
								<path className="stREX" d="M179.2,164.7c0,0,0-0.1,0-0.1l13.6-15.2c0.3-0.3,0.3-0.8-0.1-1.1c-0.3-0.3-0.8-0.3-1.1,0.1l-13,14.5
				l-21.5-53.2h27.1c0.4,0,0.8-0.4,0.8-0.8c0-0.4-0.4-0.8-0.8-0.8h-27.8l-6-14.9c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2c0,0,0,0,0,0
				c0,0,0,0,0,0c-0.1-0.1-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0h-7.6c0,0,0,0,0,0h0c0,0,0,0,0,0
				c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0,0,0,0,0l-13.6,15.2h-16.5l-6-14.9c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c0,0,0,0,0,0
				c-0.1,0-0.2-0.1-0.3-0.1c0,0,0,0,0,0H97c0,0,0,0,0,0h0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0.1c0,0,0,0,0,0l-13.6,15.2H66.4l-6-14.9c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2
				c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0H52c0,0,0,0,0,0h0
				c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0
				c0,0,0,0,0,0c0,0,0,0,0,0l-13.6,15.2h-5.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h15.6c1.7,0,5.1-1,6.3-4.9
				c0.1-0.4-0.1-0.9-0.5-1c-0.4-0.1-0.9,0.1-1,0.5c-1.1,3.7-4.6,3.8-4.8,3.8H40l11.8-13.1L73.2,148H45.5c-0.4,0-0.8,0.4-0.8,0.8
				c0,0.4,0.4,0.8,0.8,0.8h28.4l6.1,15.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
				c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0h7.6c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0
				c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l13.4-14.9h16.3l6.1,15.1
				c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1
				c0,0,0,0,0,0h7.6c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1
				c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1l13.4-14.9h16.2c0.1,0,0.1,0,0.2,0l6.1,15.1c0,0,0,0,0,0c0,0,0,0,0,0
				c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0h7.6
				c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1
				C179.1,164.9,179.1,164.8,179.2,164.7C179.2,164.7,179.2,164.7,179.2,164.7z M94.4,148c-1.7,0-5.1,1-6.3,4.9
				c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6c1.1-3.7,4.6-3.8,4.8-3.8h6l-11.8,13.2l-21.5-53.2h25.2
				c1.7,0,5.1-1,6.3-4.9c0.1-0.4-0.1-0.9-0.5-1c-0.4-0.1-0.9,0.1-1,0.5c-1.1,3.7-4.6,3.8-4.8,3.8H85l11.7-13.1l21.5,53.2H94.4z
				 M139.2,148c-1.7,0-5.1,1-6.3,4.9c-0.1,0.4,0.1,0.9,0.5,1c0.1,0,0.2,0,0.2,0c0.3,0,0.7-0.2,0.8-0.6c1.1-3.7,4.6-3.8,4.8-3.8h6.2
				l-11.8,13.2l-21.5-53.2h25.2c1.7,0,5.1-1,6.3-4.9c0.1-0.4-0.1-0.9-0.5-1c-0.4-0.1-0.9,0.1-1,0.5c-1.1,3.7-4.6,3.8-4.8,3.8h-7.2
				l11.7-13.1l21.5,53.2H139.2z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "simulation-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stChillware{fill:' + activeChillware + "}"}
					</style>
					<g>
						<g>
							<g>
								<path className="stChillware" d="M4.2,113.2c0-8.8,4.8-14.4,11.9-14.4c3.3,0,5.8,1.6,7.2,3.3l-1.1,1.3c-1.5-1.7-3.5-2.9-6.1-2.9
				c-6.1,0-9.9,4.9-9.9,12.6c0,7.7,3.7,12.7,9.7,12.7c2.9,0,5-1.2,7-3.4l1.1,1.2c-2.1,2.5-4.7,4-8.3,4C9,127.7,4.2,122,4.2,113.2z"
								/>
								<path className="stChillware" d="M31.7,96.7h1.9v8.8l0,4.5c2.1-2.1,4.2-3.7,7-3.7c4.2,0,6.1,2.5,6.1,7.8v13h-1.9v-12.8c0-4.3-1.4-6.3-4.6-6.3
				c-2.4,0-4.2,1.3-6.6,3.8v15.3h-1.9V96.7z"/>
								<path className="stChillware" d="M55.4,100.3c0-1,0.8-1.7,1.7-1.7c0.9,0,1.7,0.6,1.7,1.7c0,1-0.8,1.7-1.7,1.7C56.2,102,55.4,101.3,55.4,100.3
				z M56.2,106.9H58v20.3h-1.9V106.9z"/>
								<path className="stChillware" d="M67.9,124.4V96.7h1.9v27.9c0,0.9,0.4,1.3,0.9,1.3c0.2,0,0.3,0,0.8-0.1l0.3,1.5c-0.4,0.1-0.7,0.2-1.3,0.2
				C68.8,127.7,67.9,126.7,67.9,124.4z"/>
								<path className="stChillware" d="M80.1,124.4V96.7h1.9v27.9c0,0.9,0.4,1.3,0.9,1.3c0.2,0,0.3,0,0.8-0.1l0.3,1.5c-0.4,0.1-0.7,0.2-1.3,0.2
				C80.9,127.7,80.1,126.7,80.1,124.4z"/>
								<path className="stChillware" d="M88.9,99.6h6.4l1.9,12.9c0.4,2.9,0.8,5.8,1.1,8.7h0.2c0.5-2.9,1.1-5.8,1.6-8.7l3-12.9h5.3l3,12.9
				c0.6,2.8,1.1,5.8,1.7,8.7h0.2c0.4-2.9,0.7-5.9,1.1-8.7l1.9-12.9h5.9l-4.9,27.5h-7.8l-2.7-12.5c-0.4-2.1-0.8-4.3-1.1-6.4h-0.2
				c-0.3,2.1-0.7,4.3-1.1,6.4l-2.6,12.5H94L88.9,99.6z"/>
								<path className="stChillware" d="M133.2,99.6h7.5l8.7,27.5h-6.6l-3.6-14c-0.8-2.8-1.5-6-2.2-8.9h-0.2c-0.7,2.9-1.4,6.1-2.2,8.9l-3.6,14h-6.4
				L133.2,99.6z M130.2,115.7h13.3v4.8h-13.3V115.7z"/>
								<path className="stChillware" d="M154,99.6h9.9c5.8,0,10.6,2,10.6,8.6c0,6.3-4.8,9.1-10.6,9.1h-3.7v9.9H154V99.6z M163.4,112.3
				c3.3,0,5-1.4,5-4.1c0-2.7-1.8-3.6-5-3.6h-3.2v7.7H163.4z M162.8,115.6l4.3-4l8.8,15.5h-7L162.8,115.6z"/>
								<path className="stChillware" d="M182,99.6h17.3v5.2h-11v5.5h9.4v5.2h-9.4v6.3h11.5v5.2H182V99.6z"/>
								<path className="stChillware" d="M204.8,105.3c0-4.9,3.5-8.2,7.7-8.2s7.7,3.3,7.7,8.2c0,4.9-3.5,8.2-7.7,8.2S204.8,110.2,204.8,105.3z
				 M218.9,105.3c0-4-2.8-7-6.4-7c-3.6,0-6.4,3-6.4,7c0,4,2.8,6.9,6.4,6.9C216.1,112.2,218.9,109.3,218.9,105.3z M209.6,100.8h2.9
				c1.6,0,3.2,0.6,3.2,2.7c0,1.1-0.7,2.1-1.7,2.4l2.1,3.7h-1.6l-1.6-3.2h-2v3.2h-1.3V100.8z M212.2,105.1c1.3,0,2.2-0.4,2.2-1.6
				c0-1-0.5-1.6-2-1.6H211v3.2H212.2z"/>
							</g>
						</g>
					</g>
				</svg>
			)
		case "autonomous-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 1000 1000" space="preserve">
					<style type="text/css">
						{'.stAutonomous{fill:' + activeAutonomous + "}"}
					</style>
					<g>
						<g>
							<path className="stAutonomous" d="M31,42.5h1.4l7.3,20.7h-1.5L34.1,51c-0.8-2.5-1.6-4.6-2.3-7.1h-0.1c-0.7,2.5-1.4,4.7-2.3,7.1l-4.2,12.1h-1.5
			L31,42.5z M27.1,54.8h9.3V56h-9.3C27.1,56,27.1,54.8,27.1,54.8z"/>
							<path className="stAutonomous" d="M44,57.7V48h1.4v9.5c0,3.2,1,4.7,3.4,4.7c1.8,0,3.1-0.9,4.9-3.1v-11h1.4v15.1h-1.2l-0.2-2.5h-0.1
			c-1.5,1.7-3,2.9-5.1,2.9C45.4,63.5,44,61.6,44,57.7z"/>
							<path className="stAutonomous" d="M62.7,59v-9.7h-2.4v-1.1l2.4-0.1l0.2-4.4h1.2v4.4h4.4v1.2h-4.4v9.8c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C63.6,63.5,62.7,61.7,62.7,59z"/>
							<path className="stAutonomous" d="M72.1,55.6c0-5,3.1-7.9,6.8-7.9s6.8,2.9,6.8,7.9s-3.1,7.9-6.8,7.9S72.1,60.6,72.1,55.6z M84.2,55.6
			c0-4-2.3-6.7-5.3-6.7s-5.3,2.7-5.3,6.7s2.3,6.7,5.3,6.7S84.2,59.6,84.2,55.6z"/>
							<path className="stAutonomous" d="M91.7,48.1h1.2l0.2,2.3h0.1c1.5-1.5,3.1-2.7,5.2-2.7c3.1,0,4.6,1.9,4.6,5.8v9.7h-1.4v-9.5
			c0-3.2-1-4.7-3.4-4.7c-1.8,0-3.1,0.9-4.9,2.8v11.4h-1.4V48.1H91.7z"/>
							<path className="stAutonomous" d="M108.7,55.6c0-5,3.1-7.9,6.8-7.9c3.6,0,6.8,2.9,6.8,7.9s-3.1,7.9-6.8,7.9C111.9,63.5,108.7,60.6,108.7,55.6z
			 M120.8,55.6c0-4-2.3-6.7-5.3-6.7s-5.3,2.7-5.3,6.7s2.3,6.7,5.3,6.7C118.5,62.3,120.8,59.6,120.8,55.6z"/>
							<path className="stAutonomous" d="M128.3,48.1h1.2l0.2,2.3h0.1c1.4-1.5,3.1-2.7,4.8-2.7c2.4,0,3.6,1.2,4.2,3.1c1.7-1.9,3.4-3.1,5.1-3.1
			c3,0,4.5,1.9,4.5,5.8v9.7H147v-9.5c0-3.2-1.1-4.7-3.4-4.7c-1.4,0-2.8,0.9-4.5,2.8v11.4h-1.4v-9.5c0-3.2-1.1-4.7-3.4-4.7
			c-1.4,0-2.8,0.9-4.5,2.8v11.4h-1.4V48.1H128.3z"/>
							<path className="stAutonomous" d="M154.1,55.6c0-5,3.1-7.9,6.8-7.9c3.7,0,6.8,2.9,6.8,7.9s-3.1,7.9-6.8,7.9C157.2,63.5,154.1,60.6,154.1,55.6z
			 M166.2,55.6c0-4-2.3-6.7-5.3-6.7s-5.3,2.7-5.3,6.7s2.3,6.7,5.3,6.7S166.2,59.6,166.2,55.6z"/>
							<path className="stAutonomous" d="M173.5,57.7V48h1.4v9.5c0,3.2,1,4.7,3.4,4.7c1.8,0,3.1-0.9,4.9-3.1v-11h1.4v15.1h-1.2l-0.2-2.5l0,0
			c-1.5,1.7-3,2.9-5.1,2.9C174.9,63.5,173.5,61.6,173.5,57.7z"/>
							<path className="stAutonomous" d="M190,61.4l0.8-1c1.3,1.1,2.6,1.9,4.8,1.9c2.4,0,3.6-1.4,3.6-3c0-1.9-2-2.7-3.7-3.3c-2.3-0.8-4.8-1.7-4.8-4.3
			c0-2.2,1.7-4,4.9-4c1.6,0,3.2,0.7,4.3,1.6l-0.7,1c-1-0.8-2.1-1.4-3.6-1.4c-2.3,0-3.4,1.4-3.4,2.7c0,1.7,1.8,2.4,3.6,3
			c2.3,0.9,4.9,1.7,4.9,4.6c0,2.3-1.8,4.2-5.1,4.2C193.3,63.5,191.4,62.6,190,61.4z"/>
						</g>
						<g>
							<g>
								<path className="stAutonomous" d="M162.6,123.5c-0.1-0.8-0.8-1.4-1.6-1.4h-8.9c-0.9-3.6-2.4-7.1-4.3-10.3l6.3-6.3c0.6-0.6,0.7-1.5,0.2-2.1
				c-3.3-4.8-7.4-8.9-12.2-12.2c-0.7-0.5-1.6-0.4-2.1,0.2l-6.3,6.3c-3.2-1.9-6.7-3.3-10.3-4.3v-8.9c0-0.8-0.6-1.5-1.4-1.6
				c-5.7-1-11.5-1-17.2,0c-0.1,0-0.2,0.1-0.4,0.1c-4.7-1.7-9.9-1-14.1,1.8c-4.1,2.8-6.7,7.4-6.9,12.4c-0.4,0-0.8,0.1-1.2,0.2
				c-0.1,0-0.1,0-0.2,0c-0.5,0.1-0.9,0.2-1.4,0.4c-0.1,0-0.3,0.1-0.4,0.2c-0.3,0.1-0.6,0.2-0.9,0.3c-0.2,0.1-0.3,0.2-0.5,0.2
				c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.3,0.2-0.5,0.3s-0.5,0.3-0.7,0.4c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.2-0.4,0.3-0.6,0.5
				c-0.2,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.4,0.4-0.5,0.6s-0.3,0.3-0.4,0.5
				c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.2,0.4-0.3,0.5
				c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.2-0.1,0.4-0.2,0.6
				c-0.1,0.3-0.1,0.6-0.2,0.8c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.1,0.6-0.1,1c0,0.2-0.1,0.3-0.1,0.5c0,0.5-0.1,1-0.1,1.5
				c0,2,0.4,4,1.2,5.8c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.6,0.4-1.2,0.9-1.7,1.4c-0.1,0.1-0.1,0.1-0.2,0.2
				c-0.3,0.3-0.6,0.6-0.9,1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.3-0.5,0.7-0.8,1.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.7,1.1-1.3,2.3-1.7,3.5
				c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.3-0.2,0.6-0.2,0.8c-0.1,0.4-0.2,0.8-0.2,1.2c0,0.2-0.1,0.5-0.1,0.7s0,0.4-0.1,0.6v0.1
				c0,0.4,0,0.8,0,1.3c0,4.8,2.1,9.3,5.7,12.5c-0.7,1.2-1.3,2.5-1.7,3.9c0,0.1,0,0.2-0.1,0.2c-0.2,0.6-0.3,1.3-0.4,1.9
				c0,0.1,0,0.2,0,0.3c-0.1,0.7-0.1,1.3-0.1,2c0,7.4,5.1,13.8,12.2,15.4c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.5
				c0.1,0.4,0.3,0.8,0.4,1.2c0.1,0.1,0.1,0.3,0.1,0.4c0.7,1.6,1.6,3,2.6,4.3c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0.3,0.6,0.7,0.9,1
				c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.3,0.6,0.6,0.9,0.8c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.3,0.6,0.5,1,0.7c0.1,0.1,0.2,0.2,0.4,0.3
				c0.5,0.3,0.9,0.6,1.4,0.8c0.1,0,0.2,0.1,0.3,0.1c0.4,0.2,0.8,0.4,1.2,0.6c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.6,0.2,0.9,0.3
				c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.6,0.2,1,0.2c0.2,0.1,0.5,0.1,0.7,0.1c0.4,0.1,0.7,0.1,1.1,0.2c0.2,0,0.4,0.1,0.6,0.1
				c0.6,0.1,1.1,0.1,1.7,0.1c2.4,0,4.8-0.5,7-1.4c0.2,0.3,0.6,0.6,1,0.6c5.7,1,11.5,1,17.2,0c0.8-0.1,1.4-0.8,1.4-1.6v-8.9
				c3.6-0.9,7.1-2.4,10.3-4.3l6.3,6.3c0.6,0.6,1.5,0.7,2.1,0.2c4.8-3.3,8.9-7.4,12.2-12.2c0.5-0.7,0.4-1.6-0.2-2.1l-6.3-6.3
				c1.9-3.2,3.3-6.7,4.3-10.3h8.9c0.8,0,1.5-0.6,1.6-1.4C163.6,135.1,163.6,129.3,162.6,123.5z M96.8,178.8c-0.5,0-1,0-1.4-0.1
				c-0.2,0-0.3,0-0.5-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2-0.1-0.4-0.1-0.6-0.2
				c-0.2-0.1-0.5-0.2-0.7-0.3s-0.4-0.2-0.6-0.2c-0.2-0.1-0.5-0.2-0.7-0.3s-0.3-0.2-0.5-0.3c-0.3-0.2-0.5-0.3-0.8-0.5
				c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8-0.5-1.5-1.1-2.2-1.8c0,0,0,0-0.1-0.1c-0.3-0.3-0.6-0.6-0.9-1c0-0.1-0.1-0.1-0.1-0.2
				c-0.2-0.3-0.5-0.6-0.7-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.1-0.1-0.2-0.2-0.3
				c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.3-0.2-0.6-0.4-1c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.3-0.2-0.7-0.3-1
				c0-0.1-0.1-0.2-0.1-0.3c-0.8-4.2,0.1-8.5,2.7-12c0.5-0.7,0.4-1.8-0.3-2.3c-0.7-0.6-1.8-0.4-2.3,0.3c-2.4,3.2-3.7,7-3.7,11
				c0,0.3,0,0.7,0,1c-5-1.7-8.4-6.5-8.4-11.8c0-0.7,0.1-1.4,0.2-2c0-0.1,0-0.1,0.1-0.2c0.3-1.9,1.2-3.8,2.4-5.3l0,0
				c1.6-2,3.7-3.5,6.2-4.3c0.9-0.3,1.4-1.2,1.1-2.1c-0.3-0.9-1.2-1.4-2.1-1.1c-2.6,0.8-4.9,2.2-6.8,4.2c-2.8-2.5-4.4-6.1-4.4-9.9
				c0-0.6,0-1.1,0.1-1.7c0-0.2,0.1-0.4,0.1-0.5c0.1-0.4,0.1-0.8,0.2-1.1c0-0.2,0.1-0.4,0.1-0.6c0.1-0.5,0.3-0.9,0.5-1.3
				c0.1-0.3,0.2-0.5,0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.3-0.5,0.5-0.8c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.4,0.7-0.9,1.1-1.3
				c0.1-0.2,0.3-0.3,0.4-0.5c0.2-0.2,0.4-0.4,0.6-0.6s0.5-0.4,0.7-0.6c0.2-0.1,0.4-0.3,0.6-0.4c0.4-0.3,0.8-0.5,1.2-0.7
				c1.9-1,4-1.5,6.1-1.5c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-2,0-3.9,0.4-5.8,1.1c-0.6-1.4-0.9-2.9-0.9-4.4
				c0-5.3,3.5-9.9,8.6-11.3c5.1-1.4,10.5,0.9,13.1,5.4c0.3,0.5,0.8,0.8,1.4,0.8s1.1-0.3,1.4-0.8s0.3-1.2,0-1.7
				c-2.4-4.1-6.6-6.8-11.3-7.4c0.4-6.7,6.1-12,12.8-11.8s12.1,5.7,12.1,12.5v10.3l-7.2,10.1l-6.1-2.7c0-0.1,0-0.2,0-0.2
				c0-2.4-1.7-4.5-4.1-5s-4.8,0.9-5.7,3.1c-0.9,2.3,0,4.9,2.1,6.1c2.1,1.3,4.8,0.9,6.4-0.9l6,2.7c0.4,0.2,0.9,0.3,1.3,0.3
				c1.1,0,2.1-0.5,2.7-1.4l4.5-6.3v13.1H98.1c-0.8-2.3-3.2-3.7-5.6-3.3s-4.2,2.5-4.2,4.9s1.8,4.5,4.2,4.9c2.4,0.4,4.7-1,5.6-3.3
				h13.6v6.7h-3.6c-0.8-2.3-3.2-3.7-5.6-3.3s-4.2,2.5-4.2,4.9s1.8,4.5,4.2,4.9c2.4,0.4,4.7-1,5.6-3.3h3.6v6.7H98.1
				c-1.6,0-3,1.1-3.3,2.7l-1.2,5.9c-2.3,0.7-3.8,3-3.5,5.4s2.3,4.2,4.7,4.4c2.4,0.1,4.6-1.5,5.1-3.9s-0.7-4.7-2.9-5.7l1.1-5.4h13.6
				v13.3C111.7,172.1,105,178.8,96.8,178.8z M95.1,115.5c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7s0.7-1.7,1.7-1.7
				C94.3,113.8,95.1,114.6,95.1,115.5z M95.1,128.8c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
				C94.3,127.3,95.1,127.9,95.1,128.8z M105.1,138.8c0,0.9-0.7,1.7-1.7,1.7s-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
				C104.3,137.3,105.1,137.9,105.1,138.8z M96.8,160.6c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.7-1.7
				C96,158.8,96.8,159.6,96.8,160.6z M159.6,138.8h-8.8c-0.8,0-1.5,0.5-1.6,1.3c-0.9,4.1-2.5,8.1-4.8,11.7c-0.4,0.7-0.3,1.5,0.2,2.1
				l6.2,6.2c-2.7,3.6-5.8,6.8-9.4,9.4l-6.2-6.2c-0.6-0.6-1.4-0.6-2.1-0.2c-3.6,2.3-7.5,3.9-11.7,4.8c-0.8,0.2-1.3,0.8-1.3,1.6v8.8
				c-4.1,0.6-8.2,0.6-12.3,0.1c4.6-3.4,7.3-8.9,7.3-14.6v-8.4c12.2-0.9,21.7-11,21.7-23.2s-9.5-22.4-21.7-23.2V98.1
				c0-4.8-2.2-9.3-5.9-12.3c3.6-0.3,7.3-0.2,10.9,0.3v8.8c0,0.8,0.5,1.5,1.3,1.6c4.1,0.9,8.1,2.5,11.7,4.8c0.7,0.4,1.5,0.3,2.1-0.2
				l6.2-6.2c3.6,2.7,6.8,5.8,9.4,9.4l-6.2,6.2c-0.6,0.6-0.6,1.4-0.2,2.1c2.3,3.6,3.9,7.5,4.8,11.7c0.2,0.8,0.8,1.3,1.6,1.3h8.8
				C160.2,129.9,160.2,134.4,159.6,138.8z M125.1,132.3c0-5.8-4.3-10.7-10-11.5v-8.4c10.4,0.9,18.3,9.5,18.3,19.9
				c0,10.4-8,19-18.3,19.9v-8.4C120.8,142.8,125.1,137.9,125.1,132.3z M115.1,140.3V124c3.9,0.8,6.7,4.2,6.7,8.2
				C121.7,136.1,119,139.6,115.1,140.3z"/>
							</g>
						</g>
					</g>
				</svg>
			)
			case "hvi-dashboard":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1000 1000" space="preserve">
<style type="text/css">
						{'.stHvi{fill:' + activeHvi + "}"}
</style>
<g>
	<g>
		<path className="stHvi" d="M35.4,29.4h1.4v9.1h11.1v-9.1h1.4v20.7h-1.4V39.7H36.8v10.4h-1.4V29.4z"/>
		<path className="stHvi" d="M54.1,29.4h1.5l3.8,12.1c0.8,2.5,1.3,4.4,2.2,6.9h0.1c0.8-2.5,1.4-4.4,2.2-6.9l3.8-12.1h1.5l-6.8,20.7h-1.6
			L54.1,29.4z"/>
		<path className="stHvi" d="M71.6,41.6h6.9v1.2h-6.9V41.6z"/>
		<path className="stHvi" d="M84.3,29.4h1.4v20.7h-1.4V29.4z"/>
		<path className="stHvi" d="M93.2,35h1.2l0.2,2.3h0.1c1.5-1.5,3.1-2.7,5.2-2.7c3.1,0,4.6,1.9,4.6,5.8v9.7H103v-9.5c0-3.2-1-4.7-3.4-4.7
			c-1.8,0-3.1,0.9-4.9,2.8v11.4h-1.4V35z"/>
		<path className="stHvi" d="M111.8,45.9v-9.7h-2.4v-1.1l2.4-0.1l0.2-4.4h1.2V35h4.4v1.2h-4.4V46c0,1.9,0.5,3.2,2.6,3.2
			c0.6,0,1.3-0.2,1.9-0.5l0.4,1.1c-0.9,0.3-1.8,0.6-2.5,0.6C112.7,50.4,111.8,48.6,111.8,45.9z"/>
		<path className="stHvi" d="M121.2,42.6c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1h-10.8c0.1,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.7,1.5C124.3,50.4,121.2,47.5,121.2,42.6z M132.2,41.6c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H132.2z"/>
		<path className="stHvi" d="M139.2,35h1.2l0.2,2.8h0.1c1-1.9,2.5-3.2,4.3-3.2c0.5,0,1,0.1,1.5,0.3l-0.3,1.3c-0.5-0.2-0.8-0.3-1.4-0.3
			c-1.4,0-3,1-4.1,3.9v10.2h-1.4V35z"/>
		<path className="stHvi" d="M148.8,35.1l2.1-0.1h4.8v1.2h-6.9V35.1z M150.9,31.7c0-3,1.3-4.5,3.7-4.5c0.7,0,1.5,0.2,2.2,0.5l-0.4,1.1
			c-0.6-0.3-1.2-0.4-1.8-0.4c-1.6,0-2.4,1.2-2.4,3.4v18.3h-1.4V31.7z"/>
		<path className="stHvi" d="M158.8,46.3c0-3.3,3-4.9,9.7-5.7c0-2.3-0.6-4.8-3.6-4.8c-2,0-3.8,1-4.8,1.8l-0.6-1c1.1-0.8,3.2-2,5.6-2
			c3.6,0,4.8,2.6,4.8,5.8v9.6h-1.2l-0.2-2h-0.1c-1.5,1.2-3.4,2.3-5.3,2.3C160.8,50.4,158.8,49.1,158.8,46.3z M168.5,46.8v-5.1
			c-6.2,0.7-8.3,2.2-8.3,4.6c0,2.2,1.5,3,3.2,3C165.1,49.2,166.7,48.4,168.5,46.8z"/>
		<path className="stHvi" d="M175.6,42.6c0-5,3.2-7.9,6.9-7.9c2.1,0,3.4,0.9,4.4,1.9l-0.8,1c-1-0.9-2.1-1.6-3.6-1.6
			c-3.1,0-5.5,2.7-5.5,6.7c0,4,2.2,6.7,5.4,6.7c1.6,0,3-0.8,4-1.7l0.7,1c-1.3,1.2-2.9,2-4.8,2C178.5,50.4,175.6,47.5,175.6,42.6z"/>
		<path className="stHvi" d="M190.7,42.6c0-4.9,3.2-7.9,6.6-7.9c3.6,0,5.8,2.5,5.8,7c0,0.4,0,0.8-0.1,1.1h-10.8c0,3.8,2.3,6.5,5.7,6.5
			c1.6,0,2.9-0.5,4-1.3l0.6,1.1c-1.2,0.7-2.5,1.5-4.8,1.5C193.8,50.4,190.7,47.5,190.7,42.6z M201.7,41.6c0-3.8-1.7-5.8-4.4-5.8
			c-2.5,0-4.9,2.2-5.2,5.8H201.7z"/>
	</g>
	<g>
		<g>
			<g>
				<path className="stHvi" d="M152.1,128.7l-0.2,0.1v0.1L152.1,128.7z"/>
			</g>
		</g>
		<g>
			<path className="stHvi" d="M155.4,128.7C155.4,128.7,155.4,128.7,155.4,128.7l11.1-10.5c2-1.9,3.1-4.5,3.1-7.2c0-5.5-4.5-9.9-10.1-9.9
				c-1.9,0-3.7,0.5-5.3,1.5c0,0-0.1,0-0.1,0V70.2c0,0,0,0,0,0H79c0,0,0,0,0,0V101c0,0,0,0,0,0h-8.8c-3.3,0-6.6,1.1-9.3,3.1l-4.7,3.5
				c0,0,0,0,0,0H45.9c0,0,0,0,0,0v39.7c0,0,0,0,0,0h10.3c0,0,0,0,0,0l4.7,3.5c2.6,2,5.9,3.1,9.2,3.1c0,0,0,0,0,0v-4.4c0,0,0,0,0,0
				c-2.4,0-4.7-0.8-6.6-2.2l-5.9-4.4c0,0,0,0,0,0h-7.3c0,0,0,0,0,0v-30.9c0,0,0,0,0,0h7.3c0,0,0,0,0,0l5.9-4.4
				c1.9-1.4,4.3-2.2,6.6-2.2h19.7c1.2,0,2.3,0.8,2.4,2c0.1,1.3-0.9,2.4-2.2,2.4h-15c0,0,0,0,0,0l-0.4,1.6c-1.1,4.2-4.9,7.2-9.2,7.2
				h-1.9c0,0,0,0,0,0v4.4c0,0,0,0,0,0h1.9c5.8,0,10.9-3.6,12.9-8.8H79v75.1c0,0,0,0,0,0h75.1c0,0,0,0,0,0V171c0,0,0,0,0.1,0l3.6,3
				c0,0,0,0,0,0h3.7c0,0,0,0,0,0l21.4-14.3c0,0,0,0,0,0v-4.3c0,0,0,0,0,0L155.4,128.7z M106.6,162.9h7.1c0,0,0,0,0,0.1l-9.3,9
				c0,0,0,0,0,0l-11,3.2c0,0-0.1,0,0,0l3.1-11c0,0,0,0,0,0l4.2-4c0,0,0,0,0,0C102.1,161.8,104.2,162.9,106.6,162.9z M100.3,150.8
				C100.3,150.8,100.2,150.8,100.3,150.8c-0.9-0.7-1.4-1.8-1.4-2.9c0-1.1,0.4-2.1,1.2-2.9l18.6-18.6c2.1-2.1,4.9-3.2,7.8-3.2h6
				c0,0,0,0,0,0.1l-25.1,24.3h-0.7C104,147.5,101.7,148.8,100.3,150.8z M146.7,115.7l7.7,7.7c0,0,0,0,0,0l-25.5,24c0,0,0,0,0,0
				h-15.2c0,0,0,0,0-0.1L146.7,115.7C146.6,115.7,146.7,115.7,146.7,115.7z M159.7,105.5c3.4,0,6.1,3.1,5.4,6.7
				c-0.2,1.1-0.9,2.2-1.7,3l-5.6,5.3c0,0,0,0,0,0l-7.8-7.8c0,0,0,0,0,0l5.8-5.6C156.7,106,158,105.5,159.7,105.5z M149.7,185H83.4
				c0,0,0,0,0,0v-70.7c0,0,0,0,0,0h6.3c3.4,0,6.4-2.4,6.8-5.8c0.5-4-2.6-7.5-6.6-7.5h-6.6c0,0,0,0,0,0V74.6c0,0,0,0,0,0h66.2
				c0,0,0,0,0,0v32c0,0,0,0,0,0l-12.6,12.1c0,0,0,0,0,0h-10.6c-4.1,0-8.1,1.6-11,4.5l-18.4,18.4c-1,1-1.8,2.2-2.2,3.5
				c-1.3,4.1,0.6,8.2,3.9,10c0,0.2,0,0.2,0.1,0.4l-6.4,6.2c0,0,0,0,0,0l-5.7,19.8c0,0,0,0.1,0,0l19.8-5.6c0,0,0,0,0,0l13.5-13
				c0,0,0,0,0,0h20.3c2.6,0,5.1,0.9,7.1,2.6l2.1,1.8c0,0,0,0,0,0L149.7,185C149.7,185,149.7,185,149.7,185z M160.1,169.6h-0.7
				l-8.9-7.5c-2.8-2.3-6.3-3.6-9.9-3.6h-33.7c-1.6,0-3.1-1.1-3.4-2.6c-0.4-2.1,1.2-4,3.2-4h24.1c0,0,0,0,0,0l21.4-20.1c0,0,0,0,0,0
				l26.3,25.5c0,0,0,0,0,0.1L160.1,169.6z"/>
		</g>
		<g>
			<g>
				<g>
					<g>
						<polygon className="stHvi" points="124.8,108.1 113.5,108.1 97.1,82 108.5,82 						"/>
					</g>
				</g>
				<g>
					<g>
						<circle className="stHvi" cx="132.4" cy="86.9" r="4.8"/>
					</g>
				</g>
			</g>
		</g>
	</g>
</g>
</svg>

			)
		case "shs-logo":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 478.7 168.3" space="preserve">
					<style type="text/css">
						{'.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}'}
						{'.st1{fill:#1C1C1C;}'}
						{'.st2{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_2_);}'}
						{'.st3{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_3_);}'}
						{'.st4{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_4_);}'}
					</style>
					<g>
						<g>
							<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="156.2078" y1="117.5011" x2="156.2078" y2="50.8637">
								<stop  offset="0" stopColor="#2A3087" />
								<stop  offset="1" stopColor="#3A78AE" />
							</linearGradient>
							<rect x="147.2" y="46.3" className="st0" width="18" height="75.7"/>
							<g>
								<path className="st1" d="M183.2,106.3h41.9c1.8,0,3.3-0.7,4.4-2c1.1-1.4,1.7-3,1.7-5c0-1.9-0.5-3.6-1.6-5c-1.1-1.4-2.6-2.1-4.4-2.1
		   h-20.7c-4.6,0-8.5-0.7-11.6-2c-3.2-1.4-5.7-3.2-7.7-5.4c-2-2.2-3.4-4.7-4.3-7.5c-0.9-2.8-1.3-5.6-1.3-8.4c0-3,0.5-5.9,1.4-8.7
		   c0.9-2.8,2.4-5.2,4.4-7.4c2-2.1,4.6-3.8,7.7-5.1c3.2-1.2,7-1.9,11.4-1.9h38.4v16.4h-40.4c-1.8,0-3.3,0.7-4.6,2
		   c-1.3,1.4-1.9,3-1.9,4.9c0,1.9,0.6,3.5,1.9,4.8c1.3,1.3,2.9,2,4.7,2h20.4c7.7,0,13.8,2,18.2,5.9c4.4,3.9,6.6,9.8,6.6,17.6
		   c0,7.8-2.2,13.6-6.6,17.5c-4.4,3.9-10.5,5.8-18.5,5.8h-39.4V106.3z"/>
								<path className="st1" d="M304.1,122.7V92.1h-30.5v30.5h-16.4v-77h16.4v30.1h30.5V45.7h16.4v77H304.1z"/>
								<path className="st1" d="M333.6,106.3h41.9c1.8,0,3.3-0.7,4.4-2c1.1-1.4,1.7-3,1.7-5c0-1.9-0.5-3.6-1.6-5c-1.1-1.4-2.6-2.1-4.4-2.1
		   h-20.7c-4.6,0-8.5-0.7-11.6-2c-3.2-1.4-5.7-3.2-7.7-5.4c-2-2.2-3.4-4.7-4.3-7.5c-0.9-2.8-1.3-5.6-1.3-8.4c0-3,0.5-5.9,1.4-8.7
		   c0.9-2.8,2.4-5.2,4.4-7.4c2-2.1,4.6-3.8,7.7-5.1c3.2-1.2,7-1.9,11.4-1.9h38.4v16.4h-40.4c-1.8,0-3.3,0.7-4.6,2
		   c-1.3,1.4-1.9,3-1.9,4.9c0,1.9,0.6,3.5,1.9,4.8c1.3,1.3,2.9,2,4.7,2h20.4c7.7,0,13.8,2,18.2,5.9c4.4,3.9,6.6,9.8,6.6,17.6
		   c0,7.8-2.2,13.6-6.6,17.5c-4.4,3.9-10.5,5.8-18.5,5.8h-39.4V106.3z"/>
							</g>
							<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="134.025" y1="117.8533" x2="134.025" y2="56.3807">
								<stop  offset="0" stopColor="#2A3087" />
								<stop  offset="1" stopColor="#3A78AE" />
							</linearGradient>
							<rect x="125" y="52.2" className="st2" width="18" height="69.9"/>
							<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="111.843" y1="118.4039" x2="111.843" y2="65.0071">
								<stop  offset="0" stopColor="#2A3087" />
								<stop  offset="1" stopColor="#3A78AE" />
							</linearGradient>
							<rect x="102.8" y="61.4" className="st3" width="18" height="60.7"/>
							<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="89.66" y1="120.6806" x2="89.66" y2="96.1277">
								<stop  offset="0" stopColor="#2A3087" />
								<stop  offset="1" stopColor="#3A78AE" />
							</linearGradient>
							<rect x="80.6" y="94.8" className="st4" width="18" height="27.3"/>
						</g>
					</g>
				</svg>
			)
		case "vipra-big-logo":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 500 180" space="preserve">
					<style type="text/css">
						{'.st1{fill:#292F38;}'}
					</style>
					<g>
						<g>
							<path className="st1" d="M241.5,66.6h9.2l10,30c1.6,5.2,3.3,10.5,4.9,15.6h0.4c1.6-5.1,3.3-10.4,4.9-15.6l10-30h8.8l-18.7,52.9h-10.4
			L241.5,66.6z"/>
							<path className="st1" d="M298.2,50c0-3.5,2.7-5.8,6.2-5.8c3.5,0,6.2,2.3,6.2,5.8c0,3.4-2.7,5.8-6.2,5.8
			C300.9,55.8,298.2,53.3,298.2,50z M299.9,66.6h9v52.9h-9V66.6z"/>
							<path className="st1" d="M326.7,66.6h7.4l0.8,6.1h0.3c4.8-4,10.7-7.4,16.9-7.4c13.7,0,21,10.6,21,27c0,18-10.8,28.6-22.9,28.6
			c-4.8,0-9.8-2.2-14.7-6.1l0.2,9.2v17.9h-9V66.6z M363.7,92.4c0-11.7-4-19.5-13.9-19.5c-4.5,0-9,2.5-14.1,7.2v27.7
			c4.8,4,9.4,5.5,12.9,5.5C357.3,113.3,363.7,105.4,363.7,92.4z"/>
							<path className="st1" d="M387,66.6h7.4l0.8,9.6h0.3c3.7-6.7,9.1-10.9,15-10.9c2.3,0,4,0.3,5.6,1.1l-1.7,7.9c-1.8-0.6-3-0.9-5.1-0.9
			c-4.5,0-9.8,3.2-13.4,12.2v34h-9V66.6z"/>
							<path className="st1" d="M418.9,105.8c0-11.5,9.9-17.3,32.5-19.7c0-6.8-2.3-13.4-10.9-13.4c-6.1,0-11.6,2.9-15.8,5.7l-3.5-6.1
			c4.9-3.2,12.3-6.9,20.8-6.9c12.9,0,18.4,8.6,18.4,21.7v32.5H453l-0.8-6.3h-0.3c-5,4.2-10.9,7.6-17.5,7.6
			C425.6,120.8,418.9,115.3,418.9,105.8z M451.4,106.6V91.9c-17.7,2.1-23.7,6.5-23.7,13.2c0,6,4.1,8.5,9.3,8.5
			C442.1,113.6,446.3,111.1,451.4,106.6z"/>
						</g>
						<g>
							<g>
								<g>
									<polygon className="st1" points="139.2,140.3 89.6,140.3 18.3,26.5 67.9,26.5 				"/>
								</g>
								<g>
									<circle className="st1" cx="172.4" cy="47.6" r="21.1"/>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		case "user-level-1":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{".stIcon0{fill:#3F444C;}"}
						{'.stIcon1{fill:#D94452;}'}
					</style>
					<g>
						<circle className="stIcon0" cx="75" cy="75" r="67.7"/>
						<g>
							<g>
								<g>
									<g>
										<g>
											<polygon className="stIcon1" points="95.2,117.5 65.2,117.5 22,48.5 52,48.5 						"/>
										</g>
										<g>
											<circle className="stIcon1" cx="115.3" cy="61.3" r="12.8"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		case "user-level-2":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{".stIcon0{fill:#3F444C;}"}
						{".stIcon1{fill:#89C053;}"}
					</style>
					<g>
						<circle className="stIcon0" cx="75" cy="75" r="67.7"/>
						<g>
							<g>
								<g>
									<g>
										<g>
											<polygon className="stIcon1" points="95.2,117.5 65.2,117.5 22,48.5 52,48.5 						"/>
										</g>
										<g>
											<circle className="stIcon1" cx="115.3" cy="61.3" r="12.8"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		case "user-level-3":
			return (
				<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 150 150" space="preserve">
					<style type="text/css">
						{".stIcon0{fill:#3F444C;}"}
						{".stIcon1{fill:#F5B945;}"}
					</style>
					<g>
						<circle className="stIcon0" cx="75" cy="75" r="67.7"/>
						<g>
							<g>
								<g>
									<g>
										<g>
											<polygon className="stIcon1" points="95.2,117.5 65.2,117.5 22,48.5 52,48.5 						"/>
										</g>
										<g>
											<circle className="stIcon1" cx="115.3" cy="61.3" r="12.8"/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
			)
		default:
			return (
				"Icon missing"
			)
	}
}

export default connect(mapStateToProps)(SvgRendering)