import { TOGGLE_MENU, GRAPHQL_REQUEST_ACTIVE_MODULES } from "./actionTypes";

const initialState = {
    toggled: false,
    menu: "col-2",
    dashboard: "col-10",
    modules: []
}

export function DashboardReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MENU:
            if (state.toggled !== true) {
                return {
                    ...state,
                    toggled: !state.toggled,
                    menu: "col-1",
                    dashboard: "col-11"
                }
            } else {
                return {
                    ...state,
                    toggled: !state.toggled,
                    menu: "col-2",
                    dashboard: "col-10"
                }
            }
        case GRAPHQL_REQUEST_ACTIVE_MODULES + "_RESOLVED":
            return {
                ...state,
                modules: action.payload.company.modules
            }
        default:
            return state
    }
}