import { 
    TROUBLESHOOTERSIMULATION_DIAGNOSIS_PROGRESS, 
    TROUBLESHOOTERSIMULATION_ARROW_CLASSES, 
    TROUBLESHOOTERSIMULATION_GRAPH_VALUES, 
    TROUBLESHOOTERSIMULATION_UPDATE_ACTIVE_ITEM,
    TROUBLESHOOTERSIMULATION_ADD_LOCAL_LIST, 
    TROUBLESHOOTERSIMULATION_PUSH_INTO_CHART,  
    TROUBLESHOOTERSIMULATION_DIAGNOSIS_LIST 
} from "./actionTypes";

export function troubleshooterSimulationDiagnosisProgress (progress) {
    return {
        type: TROUBLESHOOTERSIMULATION_DIAGNOSIS_PROGRESS,
        progress
    }
}

export function troubleshooterSimulationArrowClasses (progress) {
    return {
        type: TROUBLESHOOTERSIMULATION_ARROW_CLASSES,
        progress
    }
}

export function troubleshooterSimulationUpdateActiveItem (id) {
    return {
        type: TROUBLESHOOTERSIMULATION_UPDATE_ACTIVE_ITEM,
        id
    }
}

export function troubleshooterSimulationGraphValues (values) {
    return {
        type: TROUBLESHOOTERSIMULATION_GRAPH_VALUES,
        values
    }
}

export function troubleshooterSimulationAddLocalList (list) {
    return {
        type: TROUBLESHOOTERSIMULATION_ADD_LOCAL_LIST,
        list
    }
}

export function troubleshooterSimulationPushIntoChart (id) {
    return {
        type: TROUBLESHOOTERSIMULATION_PUSH_INTO_CHART,
        id
    }
}

export function troubleshooterSimulationDiagnosisList (list) {
    return {
        type: TROUBLESHOOTERSIMULATION_DIAGNOSIS_LIST,
        list
    }
}