import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";

import { toggleMenu } from "store/dashboard/actions";

const mapStateToProps = state => {
    return {
        toggled: state.DashboardReducer.toggled
    }
}

const mapDispatchToProps = { toggleMenu };

const MenuButton = (props) => {
    let classNames = "menu-button-wrapper position-absolute"
    let icon = "caret-left"
    if (props.toggled) {
        classNames += " toggled"
        icon = "caret-right"
    }

    return (
        <div className={classNames}>
            <button className="menu-button border-0 p-0" onClick={() => props.toggleMenu()}><FontAwesomeIcon icon={icon}/></button>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuButton)