import { combineReducers } from "redux"
import { LoginReducer } from "./login/reducer"
import { DashboardReducer } from "./dashboard/reducer"
import { TroubleshooterSimulationReducer } from "./troubleshooterSimulation/reducer"
import { TroubleshooterReducer } from "./troubleshooter/reducer"

const allReducers = combineReducers({
    LoginReducer,
    DashboardReducer,
    TroubleshooterSimulationReducer,
    TroubleshooterReducer
})

export default allReducers;