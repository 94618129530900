import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DisplayButton from "../DisplayButton/DisplayButton";
import { 
  removeLoginInput,
  removeAllLoginInputs,
  login,
  graphqlRequestAccessToken 
} from "store/login/actions";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    checkingNumber: state.LoginReducer.checkingNumber,
    accessToken: state.LoginReducer.accessToken,
    companyId: state.LoginReducer.companyId
  }
}

const mapDispatchToProps = {
  removeLoginInput,
  removeAllLoginInputs,
  login,
  graphqlRequestAccessToken
}
  
const output = (props) => {
  let showLoginNumber = ""
  const { checkingNumber } = props
  if (checkingNumber.length !== 0) { 
    showLoginNumber = checkingNumber.map(() => ("*"))
  } else {
    showLoginNumber = "User Name"
  }
  return showLoginNumber
}

const InputField = (props) => {
  let checkLogin = ""
  checkLogin = props.checkingNumber.join("")
  const query = 
  `mutation Login($code: String = "${checkLogin}", $company_id: Int = ${props.companyId}) {
    login(code: $code, company_id: $company_id) {
      name,
      role,
      token {
        access_token
      }
    }
  }` 
  if (!props.accessToken) {
    return (
        <>
          <div className="number-align position-absolute">
            <div className="container">
              <div className="row p-0">
                <div className="p-0 col-12">
                  <div className="number-shown rounded-top text-center w-100 border-0 py-2">{output(props)}</div>
                </div>
                {
                  [...Array(9)].map((_,i) => (
                      <DisplayButton number={i + 1} key={i}/>
                  ))
                }
                <div className="col-4 number-button"></div>
                <DisplayButton number={0} />
                <button onClick={() => props.removeLoginInput()} className="col-4 number-button text-white"><FontAwesomeIcon icon="backspace" /></button>
                <button onClick={() => props.removeAllLoginInputs()} className="col-6 submit-cancel text-white text-center border-0">Cancel</button>
                <button onClick={() => props.graphqlRequestAccessToken(query)} className="col-6 w-100 submit-login text-white text-center border-0">Login</button>
              </div>
            </div>
          </div>
        </>
    );
  } else {
    return (
      <Redirect to="/dashboard" />
    )
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(InputField)