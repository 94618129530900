import React from 'react'
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        progress: state.TroubleshooterSimulationReducer.progress,
    }
}

const TroubleshooterSimulationProgressBar = (props) => (
    <>
        <p className="mb-0 align-middle position-absolute troubleshooter-simulation-percentage-number">
            {Math.round(props.progress)}%
        </p>
        <div className="troubleshooter-simulation-progress-bar d-inline-block position-absolute">
            <div className="troubleshooter-simulation-progress-bar-active" />
        </div>
    </>
)

export default connect(mapStateToProps)(TroubleshooterSimulationProgressBar)