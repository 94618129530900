import React, { useEffect } from "react"
import { Link, withRouter } from "react-router-dom";
import "assets/css/all.css"

import SvgRendering from "./SvgRendering/SvgRendering"
import ListingItems from "./ListingItems/ListingItems";
import MenuButton from "./MenuButton/MenuButton";

const Menu = (props) => {
    let modules = []
    let vipraHide = "text-capitalize mt-3 mb-5"
    let hide = "";
    let classNames = "list-group-item border-0 text-white"

    if (props.toggled) {
        hide = " toggled"
        vipraHide += hide
        classNames += " toggled"
    }

    if (props.modules !== undefined) {
        modules = props.modules
    }

    return (
        <>
            <div className="menu position-relative">
                <Link className={classNames} to="/dashboard">
                    <SvgRendering iconName="vipra" />
                    <span className={vipraHide}>vipra dashboard</span>
                </Link>
                <div className="gradient-menu position-absolute"/>
                <nav className="menu-list position-relative">
                    <ul className="list-group">
                        {
                            modules.map((item, i) => (
                                <ListingItems rawTab={item.name.toLowerCase()} toggled={hide} modules={modules} key={i} />
                            ))
                        }
                    </ul>
                </nav>
                <div className="gradient-bottom" />
                <MenuButton toggleButton={props.toggleButton} />
            </div>
        </>
    )
}

export default withRouter(Menu)