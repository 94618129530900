import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter ,Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"
import Login from "./container/Login/Login"
import MainPage from "./container/Dashboard/Dashboard"
import { store } from "./store/store";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBackspace, faCaretLeft, faCaretRight, faChevronDown, faChevronUp, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import "./../node_modules/bootstrap/dist/css/bootstrap.css";
import "./assets/css/all.css";
import { graphqlRequestgetCompany } from "./store/login/actions";

library.add(faBackspace, faCaretLeft, faCaretRight, faChevronDown, faChevronUp, faArrowRight)



const App = () => {
    let loadCompany = window.location.pathname.slice(1)
    const data = `
        {
            check(url:"${loadCompany}") {
                id,
                url
            }
        }
    `
    store.dispatch(graphqlRequestgetCompany(data))

    return (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route exact path={"/" + loadCompany} component={Login}/>
                <PrivateRoute path="/dashboard" component={MainPage} />
            </Switch>
        </BrowserRouter>
    </Provider>
)};


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);