import React from "react"
import { connect } from 'react-redux'
import { 
    setActiveTroubleshooterErrorClass,
    setActiveTroubleshooterErrorClassText,
} from "store/troubleshooter/actions";
import { troubleshooterSimulationDiagnosisList } from "store/troubleshooterSimulation/actions";
import TroubleshooterCategoryButton from "./TroubleshooterCategoryButton";

const mapStateToProps = state => ({
    currentErrorClass: state.TroubleshooterReducer.currentErrorClass
})

const mapDispatchToProps = {
    setActiveTroubleshooterErrorClass,
    setActiveTroubleshooterErrorClassText,
    troubleshooterSimulationDiagnosisList
}

const TroubleshooterCategories = (props) => {
    const { errorTypes } = props
    if (errorTypes !== undefined) {
        return (
            <ul className="list-group position-relative text-white">
                {
                    errorTypes.map((item, i) => (
                        <TroubleshooterCategoryButton errorType={item} key={i} />
                    ))
                }
            </ul>
        )
    } else {
        return ""
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TroubleshooterCategories)