import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'

const mapStateToProps = state => {
    return {
        troubleshooterSimulationListText: state.TroubleshooterSimulationReducer.troubleshooterSimulationListText,
        activeItem: state.TroubleshooterSimulationReducer.activeItem,
    }
}

const TroubleshooterSimulationList = (props) => (
    <>
        <h4 className="text-white troubleshooter-simulation-headline">Mögliche Gründe:</h4>
        <div className="position-relative mb-2">
            <div className="gradient-top-small" />
            <ul className="text-muted list-group">
                {props.troubleshooterSimulationListText.map((item, i) => {
                    if (props.activeItem === i) {
                        return (
                            <li className="list-group-item bg-transparent border-0 troubleshooter-simulation-selected-category" key={i}><FontAwesomeIcon icon="arrow-right" />{item}</li>
                        )
                    } else {
                        return (
                            <li className="list-group-item bg-transparent border-0" key={i}>{item}</li>
                        )
                    }
                })}
            </ul>
            <div className="gradient-bottom-small" />
        </div>
    </>
)

export default connect(mapStateToProps)(TroubleshooterSimulationList)