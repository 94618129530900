import React, { useEffect } from "react";
import { connect } from "react-redux"
import {     
    troubleshooterSimulationDiagnosisProgress,
    troubleshooterSimulationAddLocalList,
    troubleshooterSimulationUpdateActiveItem 
} from "store/troubleshooterSimulation/actions";
import TroubleshooterSimulationButton from "components/TroubleshooterSimulation/TroubleshooterSimulationButton";
import TroubleshooterSimulationList from "components/TroubleshooterSimulation/TroubleshooterSimulationList";
import TroubleshooterSimulationProgressBar from "components/TroubleshooterSimulation/TroubleshooterSimulationProgressBar";
import TroubleshooterSimulationGraph from "components/TroubleshooterSimulation/TroubleshooterSimulationGraph";

const mapStateToProps = state => {
    return {
        troubleshooterSimulationScanListClasses: state.TroubleshooterSimulationReducer.troubleshooterSimulationScanListClasses,
        troubleshooterSimulationListText: state.TroubleshooterSimulationReducer.troubleshooterSimulationListText,
        listItemsLocal: state.TroubleshooterSimulationReducer.listItemsLocal,
        arrowDisplay: state.TroubleshooterSimulationReducer.arrowDisplay,
        progress: state.TroubleshooterSimulationReducer.progress,
        graphValues: state.TroubleshooterSimulationReducer.graphValues,
        activeItem: state.TroubleshooterSimulationReducer.activeItem,
    }
}

const mapDispatchToProps = {
    troubleshooterSimulationDiagnosisProgress,
    troubleshooterSimulationAddLocalList,
    troubleshooterSimulationUpdateActiveItem
}

const TroubleshooterSimulation = (props) => {
    const { progress, troubleshooterSimulationDiagnosisProgress } = props

    const checkProgress = (progress) => {
        if (Math.round(progress) !== 0) {
            troubleshooterSimulationDiagnosisProgress(-progress)
        }
    }

    useEffect(() => {
        checkProgress(progress)
        return () => checkProgress(progress)
    }, [])

    return (
        <>
            <div className="troubleshooter-simulation-wrapper position-relative">
                <div className="row sub-wrapper">
                    <div className="col-6 offset-1">
                        <TroubleshooterSimulationList />
                        <div className="row troubleshooter-simulation-progress-wrapper">
                            <div className="col-4">
                                <TroubleshooterSimulationButton />
                            </div>
                            <div className="text-white troubleshooter-simulation-percentage col-8">
                                <TroubleshooterSimulationProgressBar />
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <TroubleshooterSimulationGraph />
                    </div>
                </div>
            </div>
            <style>
                {".troubleshooter-simulation-progress-bar-active{width:" + Math.round(props.progress) + "%}"}
            </style>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TroubleshooterSimulation)