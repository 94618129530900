import React, { useState } from "react"

import controlPicture1 from "assets/png/chillWARE_Live.png"
import controlPicture2 from "assets/png/Control_vipra.png"
import controlPicture3 from "assets/png/Downstream.png"
import controlPicture4 from "assets/png/Downstream_2.png"
import controlPicture5 from "assets/png/Extruder.png"
import controlPicture6 from "assets/png/Live.png"
import controlPicture7 from "assets/png/Main.png"
import {connect} from "react-redux"

const mapStateToProps = state => {
    return {
        modules: state.DashboardReducer.modules
    }
}

const Control = (props) => {
    const [ picNumber, setPicNumber ] = useState(3);
    const [ pic, setPic ] = useState(controlPicture1);
    const changePic = () => {
        switch(picNumber) {
            case 1:
            setPicNumber( picNumber + 1 );
            setPic(controlPicture1)
            break
            case 2:
            setPicNumber( picNumber + 1 );
            setPic(controlPicture3)
            break
            case 3:
            setPicNumber( picNumber + 1 );
            setPic(controlPicture2)
            break
            case 4:
            setPicNumber( picNumber + 1 );
            setPic(controlPicture4)
            break
            case 5:
            setPicNumber( picNumber + 1 );
            setPic(controlPicture5)
            break
            case 6:
            setPicNumber( picNumber + 1 );
            setPic(controlPicture6)
            break
            case 7:
            setPicNumber( picNumber - 6 );
            setPic(controlPicture7)
            break
        }
    }

    let ip_adress = null
    if (props.modules !== undefined) {
        props.modules.map(item => {
            if (item.name === "Control") {
                if (item.ip_adress !== null) {
                    return ip_adress = item.ip_adress
                }
            }
        })
    }
    if (ip_adress === null) {
    return (
        <>
            <button className="bg-transparent border-0" onClick={() => changePic()}><img src={pic}></img></button>
            <style>
                {"img{margin-top: 100px; width: 95%}"}
                {"button:focus{outline: none}"}
            </style>
        </>
    )
    } else {
        return (<iframe src={ip_adress} width="100%" height="100%" />)
    }
}

export default connect(mapStateToProps)(Control)