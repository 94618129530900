import { SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS, SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS_TEXT, GRAPHQL_REQUEST_ALL_ERROR_CLASSES } from "./actionTypes";

const initialState = {
    allErrorClasses: [],
    currentErrorClass: "",
    currentErrorClassText: ""
}

export function TroubleshooterReducer(state = initialState, action) {
    switch(action.type) {
        case SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS:
        return {
            ...state,
            currentErrorClass: action.errorClassName
        }
        case SET_ACTIVE_TROUBLESHOOTER_ERRORCLASS_TEXT:
        return {
            ...state,
            currentErrorClassText: action.errorClassNameText
        }
        case GRAPHQL_REQUEST_ALL_ERROR_CLASSES + "_RESOLVED":
        return {
            ...state,
            allErrorClasses: action.payload.company.errorClasses
        }
        default:
        return state
    }
}