import React from "react"
import {connect} from "react-redux"
import expertisePicture from "./../../assets/png/Expertise_FAKE.png"

const mapStateToProps = state => {
    return {
        modules: state.DashboardReducer.modules
    }
}

const Expertise = props => {
    let ip_adress = null
    if (props.modules !== undefined) {
        props.modules.map(item => {
            if (item.name === "Expertise") {
                if (item.ip_adress !== null) {
                    return ip_adress = item.ip_adress
                }
            }
        })
    }
    if (ip_adress === null) {
        return (
            <>
            <img src={expertisePicture}></img>
            <style>
                {"img{margin-top: 100px; width: 95%}"}
            </style>
            </>
        )
    } else {
        return (<iframe src={ip_adress} width="100%" height="100%" />)
    }
}

export default connect(mapStateToProps)(Expertise)