import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Menu from "components/Menu/Menu"
import Assistant from "components/Assistant/Assistant"
import Dashboard from "components/Dashboard/Dashboard"
import  UserDropdown from "components/UserDropdown/UserDropdown"
import { connect } from "react-redux"
import Control from "../Control/Control";
import Extrud from "../Extrud/Extrud";
import KPI from "../KPI/KPI";
import Analytics from "../Analytics/Analytics";
import Expertise from "../Expertise/Expertise";
import Troubleshooter from "../Troubleshooter/Troubleshooter";
import Chillware from "../Chillware/Chillware";
import Odis from "../Odis/Odis";
import TroubleshooterSimulation from "../TroubleshooterSimulation/TroubleshooterSimulation";
import { graphqlRequestActiveModules } from "store/dashboard/actions"

const mapStateToProps = state => {
    return {
        toggled: state.DashboardReducer.toggled,
        menu: state.DashboardReducer.menu,
        dashboard: state.DashboardReducer.dashboard,
        username: state.LoginReducer.username,
        userrole: state.LoginReducer.userrole,
        companyUrl: state.LoginReducer.companyUrl,
        companyId: state.LoginReducer.companyId,
        modules: state.DashboardReducer.modules
    }
}

const mapDispatchToProps = {
    graphqlRequestActiveModules
}



const MainPage = props => {
    const { toggled, menu, dashboard, username, userrole, companyUrl, companyId, modules } = props

    const query = `
    query($company_id:ID = ${companyId}){
        company(id: $company_id) {
          modules {
              name,
              ip_adress
          }
        }
      }
    `
    if (modules === undefined || modules.length === 0) {
        props.graphqlRequestActiveModules(query)
    }

    localStorage.setItem('Company', companyUrl)

    return (
        <div className="dashboard-page">
            <div className="row">
                <div className={menu}>
                    <Menu toggled={toggled} modules={modules}/>
                </div>
                <div className={dashboard}>
                    <Switch>
                        <Route exact path="/dashboard" component={Dashboard}/>
                        <Route path="/dashboard/assistant" component={Assistant}/>
                        <Route path="/dashboard/control" component={Control} />
                        <Route path="/dashboard/troubleshooter" component={Troubleshooter} />
                        <Route path="/dashboard/troubleshooter-simulation" component={TroubleshooterSimulation} />
                        <Route path="/dashboard/analytics" component={Analytics} />
                        <Route path="/dashboard/odis" component={Odis} />
                        <Route path="/dashboard/extrud3d" component={Extrud} />
                        <Route path="/dashboard/kpi" component={KPI} />
                        <Route path="/dashboard/expertise" component={Expertise} />
                        <Route path="/dashboard/chillware" component={Chillware} />
                    </Switch>
                </div>
            </div>
            <div className="position-absolute user-dropdown">
                <UserDropdown username={username} userrole={userrole} companyUrl={companyUrl}/>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);