
import {
    TROUBLESHOOTERSIMULATION_DIAGNOSIS_PROGRESS,
    TROUBLESHOOTERSIMULATION_ARROW_CLASSES,
    TROUBLESHOOTERSIMULATION_UPDATE_ACTIVE_ITEM,
    TROUBLESHOOTERSIMULATION_GRAPH_VALUES,
    TROUBLESHOOTERSIMULATION_ADD_LOCAL_LIST,
    TROUBLESHOOTERSIMULATION_PUSH_INTO_CHART,
    TROUBLESHOOTERSIMULATION_DIAGNOSIS_LIST
} from "./actionTypes";

const initialState = {
    troubleshooterSimulationListText: [],
    arrowDisplay: ["position-absolute","chillware-arrow-visible-0"],
    progress: 0,
    graphValues: [],
    currentGraphValues: [],
    activeItem: -1
}

export function TroubleshooterSimulationReducer (state = initialState, action) {
    switch(action.type) {
        case TROUBLESHOOTERSIMULATION_DIAGNOSIS_PROGRESS:
            return {
                ...state,
                progress: state.progress + action.progress
            }
        case TROUBLESHOOTERSIMULATION_ARROW_CLASSES:
            return {
                ...state,
                arrowDisplay: action.progress
            }
        case TROUBLESHOOTERSIMULATION_UPDATE_ACTIVE_ITEM:
            return {
                ...state,
                activeItem: action.id
            }
        case TROUBLESHOOTERSIMULATION_GRAPH_VALUES:
            return {
                ...state,
                graphValues: [...action.values]
            }
        case TROUBLESHOOTERSIMULATION_ADD_LOCAL_LIST:
            return {
                ...state,
                listItemsLocal: action.list
            }
        case TROUBLESHOOTERSIMULATION_PUSH_INTO_CHART:
            return {
                ...state,
                currentGraphValues: [...state.currentGraphValues, state.graphValues[action.id]]
            }
        case TROUBLESHOOTERSIMULATION_DIAGNOSIS_LIST:
            return {
                ...state,
                troubleshooterSimulationListText: [...action.list]
            }
        default:
            return state
    }
}