import { ADD_LOGIN_INPUT, REMOVE_LOGIN_INPUT, REMOVE_ALL_LOGIN_INPUTS, LOGIN, GRAPHQL_REQUEST_ACCESS_TOKEN, GRAPHQL_REQUEST_GET_COMPANY_ID } from "./actionTypes";

export function addLoginInput(checkingNumber) {
    return {
        type: ADD_LOGIN_INPUT,
        checkingNumber
    }
}

export function removeLoginInput(checkingNumbers) {
    return {
        type: REMOVE_LOGIN_INPUT,
        checkingNumbers
    }
}

export function removeAllLoginInputs(checkingNumbers) {
    return {
        type: REMOVE_ALL_LOGIN_INPUTS,
        checkingNumbers
    }
}

export function login() {
    return {
        type: LOGIN,
    }
}

export function graphqlRequestAccessToken(data) {
    return {
        type: GRAPHQL_REQUEST_ACCESS_TOKEN,
        payload: { query : data}
    }
}

export function graphqlRequestgetCompany(data, name) {
    return {
        type: GRAPHQL_REQUEST_GET_COMPANY_ID,
        payload: { query : data},
    }
}