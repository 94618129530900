import { TOGGLE_MENU, GRAPHQL_REQUEST_ACTIVE_MODULES } from "./actionTypes";

export function toggleMenu () {
    return {
        type: TOGGLE_MENU
    }
}

export function graphqlRequestActiveModules (data) {
    return {
        type: GRAPHQL_REQUEST_ACTIVE_MODULES,
        payload: { query: data }
    }
}