import React from 'react'
import SvgRendering from "components/Menu/SvgRendering/SvgRendering";

const LoginLogo = () => (
    <>
    <div className="vipra-logo-wrapper text-center position-absolute">
        <SvgRendering iconName="vipra-big-logo" />
        <p className="login-text">Virtual Production Assistance System</p>
    </div>
    <div className="shs-logo-wrapper position-absolute">
        <p className="mb-0"><span>powered by</span><SvgRendering iconName="shs-logo"/></p>
    </div>
    </>
)

export default LoginLogo