import React from "react"
import { connect } from 'react-redux'
import { 
    setActiveTroubleshooterErrorClass,
    setActiveTroubleshooterErrorClassText
} from "store/troubleshooter/actions";
import { troubleshooterSimulationDiagnosisList } from "store/troubleshooterSimulation/actions";

const mapStateToProps = state => ({
    currentErrorClass: state.TroubleshooterReducer.currentErrorClass
})

const mapDispatchToProps = {
    setActiveTroubleshooterErrorClass,
    setActiveTroubleshooterErrorClassText,
    troubleshooterSimulationDiagnosisList
}

const TroubleshooterCategoryButton = (props) => {
    const { errorType, setActiveTroubleshooterErrorClass, setActiveTroubleshooterErrorClassText, troubleshooterSimulationDiagnosisList } = props
    const { name, description, errorDiagnosisTypes } = errorType
    let listClassNames = "list-group-item border-0";
    let buttonClassNames = "list-group-item border-0 bg-transparent p-0 text-left";
    if(props.currentErrorClass === name) {
        let errorDiagnosisTypesArray = []
        if (errorDiagnosisTypes !== undefined) {
            errorDiagnosisTypes.map((item,i) => {
                errorDiagnosisTypesArray.push(item.name)
            })
        }
        setActiveTroubleshooterErrorClassText(description)
        troubleshooterSimulationDiagnosisList(errorDiagnosisTypesArray)
        listClassNames += " active"
    }

    return (
        <button className={buttonClassNames} onClick={() => setActiveTroubleshooterErrorClass(name)}><li className={listClassNames}>{name}</li></button>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TroubleshooterCategoryButton)