import React, { useState } from "react"

import chillwarePicture1 from "assets/png/ChillWARE1_FAKE.png"
import chillwarePicture2 from "assets/png/ChillWARE2_FAKE.png"
import chillwarePicture3 from "assets/png/ChillWARE3_FAKE.png"
import {connect} from "react-redux"

const mapStateToProps = state => {
    return {
        modules: state.DashboardReducer.modules
    }
}

const ChillwareFake = (props) => {
    const [ picNumber, setPicNumber ] = useState(3);
    const [ pic, setPic ] = useState(chillwarePicture1);
    const changePic = () => {
        switch(picNumber) {
            case 1:
            setPicNumber( picNumber + 1 );
            setPic(chillwarePicture1)
            break
            case 2:
            setPicNumber( picNumber + 1 );
            setPic(chillwarePicture3)
            break
            case 3:
            setPicNumber( picNumber - 2 );
            setPic(chillwarePicture2)
            break
        }
    }
    let ip_adress = null
    if (props.modules !== undefined) {
        props.modules.map(item => {
            if (item.name === "Chillware") {
                if (item.ip_adress !== null) {
                    return ip_adress = item.ip_adress
                }
            }
        })
    }
    if (ip_adress === null) {
    return (
        <>
            <button className="bg-transparent border-0" onClick={() => changePic()}><img src={pic}></img></button>
            <style>
                {"img{margin-top: 100px; width: 95%}"}
                {"button:focus{outline: none}"}
            </style>
        </>
    )
    } else {
        return (<iframe src={ip_adress} width="100%" height="100%" />)
    }
}

export default connect(mapStateToProps)(ChillwareFake)
