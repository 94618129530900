import axios from "axios";
import { createStore, applyMiddleware, compose } from "redux"
import allReducers from "./reducers";

const graphqlMiddleware = ({ dispatch, getState }) => next => action => {
    if (!action.type.includes("GRAPHQL_REQUEST")) {
        return next(action);
    } else if (action.payload !== undefined) {
        const { query } = action.payload;
        if (query !== undefined) {
            let apiUrl = process.env.REACT_APP_API_BASE_STAGE
            switch(window.location.hostname) {
                case "localhost":
                    apiUrl = process.env.REACT_APP_API_BASE_LOCAL
                break;
                default:
                    apiUrl = process.env.REACT_APP_API_BASE_STAGE

            }
            axios
            .create({ 
                baseURL: apiUrl,
                headers: {Authorization: `Bearer ${getState().LoginReducer.accessToken}`}
            })
            .post("", {
                query,
            })
            .then(({ data }) => {
                return data.data
            })
            .then(data => {
                if(data === undefined || data.login === null || data.check === null) {
                    dispatch({ type: 'LOGIN_FAILED'});
                } else {
                    dispatch({ type: action.type + '_RESOLVED', payload: data });
                }
            });
        }
        return next(action)
    }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
    applyMiddleware(graphqlMiddleware), 
)

export const store = createStore(allReducers, enhancer)