import React from "react"
import TroubleshooterCategoryWrapper from "components/Troubleshooter/TroubleshooterCategoryWrapper";
import TroubleshooterCategoryDesc from "components/Troubleshooter/TroubleshooterCategoryDesc";

const Troubleshooter = () => {
    return (
        <div className="troubleshooter py-5">
            <div className="row">
                <TroubleshooterCategoryWrapper />
                <TroubleshooterCategoryDesc />
            </div>
        </div>
    )
}

export default Troubleshooter