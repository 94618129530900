import React from "react"
import { Chart } from "chart.js";
import { connect } from "react-redux"
import {
    troubleshooterSimulationDiagnosisProgress,  
    troubleshooterSimulationArrowClasses, 
    troubleshooterSimulationGraphValues, 
    troubleshooterSimulationUpdateActiveItem, 
    troubleshooterSimulationPushIntoChart
} from "store/troubleshooterSimulation/actions";

// TODO: Rename

const mapStateToProps = state => {
    return {
        progress: state.TroubleshooterSimulationReducer.progress,
        graphValues: state.TroubleshooterSimulationReducer.graphValues,
        currentGraphValues: state.TroubleshooterSimulationReducer.currentGraphValues,
        troubleshooterSimulationListText: state.TroubleshooterSimulationReducer.troubleshooterSimulationListText
    }
}

const mapDispatchToProps = {
    troubleshooterSimulationDiagnosisProgress,  
    troubleshooterSimulationArrowClasses, 
    troubleshooterSimulationGraphValues, 
    troubleshooterSimulationUpdateActiveItem, 
    troubleshooterSimulationPushIntoChart
}
    
const buildBarChart = (props) => {
    const { currentGraphValues, troubleshooterSimulationListText } = props
    let labelsLength = []
    troubleshooterSimulationListText.map((item, i) => ( 
        labelsLength = [...labelsLength, i + 1]
    ))
    Chart.defaults.global.defaultFontColor = "#fff"; 
    const ctx = document.getElementById('diagnosisChart').getContext("2d");
    let gradient = ctx.createLinearGradient(0, 330, 0, 400);
    gradient.addColorStop(0, '#2ab7ff');
    gradient.addColorStop(1, '#eb5463');
    let chart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labelsLength,
            datasets: [{
                label: "Auswertungsergebnisse",
                backgroundColor: gradient,
                data: [...currentGraphValues]
            }]
        },
        options: {
            layout: {
                padding: {
                    left: 20,
                    right: 40
                }
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                    },
                }],
                yAxes: [{
                    gridLines: {
                        drawBorder: false,
                        color: "rgba(255, 255, 255, 1)",
                        zeroLineColor:"rgba(255, 255, 255, 1)",
                    },
                    ticks:{
                        padding: 10,
                        "beginAtZero": true,
                        "min": -100,
                        "max": 100,
                    }
                }]
            }
        }
    });
    if (currentGraphValues.length === 10) {
        Chart.data.datasets[0].data = 0
    }
    return chart
}

const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

const troubleshooterSimulationButton = (props) => {
    if (Math.round(props.progress) === 0) {
        const { troubleshooterSimulationListText } = props
        let graphValues = []
        const animation = 3000
        const j = animation * (animation / (200 / troubleshooterSimulationListText.length))
        let setClass = true
        let chart = buildBarChart(props)
        let visibleCounter = 0;
        if (graphValues.length === 0) {
            const random = getRandomInt(5)
            switch(random) {
                case 0:
                graphValues = ['5', '5', '5', '70', '5', '-50', '5', '5', '5', '12']
                break;
                case 1:
                graphValues = ['5', '55', '5', '5', '-55', '5', '30', '5', '-35', '5']
                break;
                case 2:
                graphValues = ['5', '-54', '5', '44', '5', '5', '5', '5', '12', '5']
                break;
                case 3:
                graphValues = ['40', '5', -'5', '5', '5', '-50', '5', '5', '50', '5']
                break;
                case 4:
                graphValues = ['5', '15', '5', '5', '-64', '5', '5', '80', '5', '5']
                break;
                default:
                graphValues = ['5', '5', '5', '5', '5', '5', '5', '5', '5']
            }
            if (graphValues.length < troubleshooterSimulationListText.length) {
                do {
                    graphValues.push("5")
                } while (graphValues.length < troubleshooterSimulationListText.length)
            }
        }
        props.troubleshooterSimulationGraphValues(graphValues)
        
        troubleshooterSimulationListText.map(() => {
            for(let i = 0; i < animation; i++) {
                setTimeout(() => {
                    props.troubleshooterSimulationDiagnosisProgress(i/j)
                    if (setClass === true) {
                        props.troubleshooterSimulationUpdateActiveItem(visibleCounter)
                        visibleCounter += 1
                        props.troubleshooterSimulationArrowClasses(["position-absolute", "troubleshooter-simulation-arrow-visible-"+visibleCounter])
                        setClass = false
                        if (visibleCounter === 10) {
                            setTimeout(() => {
                                props.troubleshooterSimulationArrowClasses(["position-absolute", "troubleshooter-simulation-arrow-visible-0"])
                            }, 1)
                        }
                    }
                }, 1)
                setTimeout(() => {
                    if (i === animation - 1) {
                        chart.data.datasets[0].data.push(graphValues[visibleCounter-1])
                        chart.update()
                        props.troubleshooterSimulationUpdateActiveItem(-1)
                        setClass = true
                    }
                }, 1)
            }
        })
    }
}


const TroubleshooterSimulationButton = (props) => {
    return (
        <div className="btn-troubleshooter-simulation-wrapper">
            <button className="btn btn-troubleshooter-simulation" onClick={() => troubleshooterSimulationButton(props)}>
                Automatische Diagnose starten
            </button>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TroubleshooterSimulationButton)